.sourceCell {
  display: flex;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  margin-left: 12px;

  &_index {
    min-width: 20px;
    width: fit-content;
  }
  &_img {
    min-height: 64px;
    height: 64px;
    min-width: 64px;
    width: 64px;
    margin: 0 13px;
    border-radius: 6px;
    //pointer-events: none;
  }
  &_AuthorName {
    white-space: nowrap;
    max-width: 160px;
    min-width: 160px;
    overflow: hidden;
    position: relative;
    font-size: 14px;
  }
}
.hiddenField {
  content: ' ';
  position: absolute;
  background: rgba(0,0,0,0);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.76) 50.88%, rgba(255, 255, 255, 0.96) 69.18%, #FFFFFF 100%);
  // background: red;
  width: 40px;
  height: 100%;
  right: -1px;
  top: 0;
}
.linkCell {
  display: flex;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  height: 64px;
  padding-left: 12px;

  &_linkBlock {
    display: flex;
    gap: 5px;
    color: #129FD4;
    cursor: pointer;
  }

}

.platformCell {
  display: flex;
  align-items: center;
  height: 64px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 133%;
  color: #3B3D47;
  padding-left: 22px;

  &_linkBlock {
    display: flex;
    gap: 5px;
  }

}

.authorCell {
  display: flex;
  align-items: center;
  height: 64px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 133%;
  color: #3B3D47;
  padding-left: 22px;
}

.categoryCell {
  position: relative;
  display: flex;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 133%;
  color: #3B3D47;
  width: 150px;
  max-width: 150px;
  padding-left: 22px;
  height: 64px;

  &_block {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: 60px;
    gap: 5px;
  }

  &_blockBlur {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 16px);
    height: 80%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.56) 50.88%, rgba(255, 255, 255, 0.96) 69.18%, #FFFFFF 100%);
    pointer-events: none;
  }

  &_imgBtn {
    // margin-top: 38px;
    position: absolute;
    top: calc(50% - 8px);
    user-select: none;
    right: 0;
    cursor: pointer;
  }
}

.subscribersCell {
  display: flex;
  align-items: center;
  height: 64px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 133%;
  color: #3B3D47;
  padding-left: 22px;
}

.erCell {
  display: flex;
  align-items: center;
  height: 64px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 133%;
  color: #3B3D47;
  padding-left: 22px;

}

.subscriberGrowthCell {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 133%;
  padding-left: 12px;
  color: black;

  &_block {
    display: flex;
    width: 150px;
    gap: 24px;
    white-space: nowrap;
    margin-bottom: 4px;
  }

  &_block:nth-last-child(1) {
    margin-bottom: 0;
  }

  &_name {
    width: 55px;
  }

  &_number {
    width: 20%;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 133%;
  }

  &_percent {
    width: 35%;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 133%;
    color: rgba(120, 126, 147, 0.7);
    padding-left: 12px;
  }
}

.priceCell {
  display: flex;
  align-items: center;
  height: 64px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 133%;
  color: #3B3D47;
  padding-left: 12px;
}

.windowModal {
  position: absolute;
  left: -150px;
  top: 50px;
  width: 300px;
  height: 161px;
  background: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  z-index: 100;

  &_main {
    padding: 25px;
  }

  &_title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    color: black;
  }
}



