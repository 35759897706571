.overlay {
  top: 0;
  left: 0;;
  position: fixed;
  // width: 100vw;
  width: 100%;
  height: 100%;
  // height: 100vh;
  background-color: rgba(0,0,0,0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.loaderOverlay {
  top: 0;
  left: 0;;
  position: absolute;
  // width: 100vw;
  width: 100%;
  height: 100%;
  // height: 100vh;
  background-color: rgba(0,0,0,0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.rootModal {
  position: relative;
  padding: 16px 32px;
  background: white;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  border-radius: 6px;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.tagName {
  margin-bottom: 16px;
}

.btnClose {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}
.grayLabel {
  line-height: 135%;
  color: #787E93;
  margin-bottom: 10px;
}

.content {
  display: flex;
}
.item, .itemWords {
  display: flex;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
.word {
  margin-right: 10px;
}
.itemLabel {
  font-size: 14px;
  line-height: 135%;  
  color: #787E93;
  width: 380px;
}
.itemContentWords {
  display: flex;
  max-width: 400px;
  flex-wrap: wrap;
  margin-left: -16px;
}
.wordContainer {
  padding: 11px;
  background-color: #DFFFF980;
  fill: #0EBFA1;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-right: 10px;
  margin-bottom: 8px;
  border-radius: 6px;
  font-weight: 400;
  &.excludeWord {
    fill: #E67E22;
    background-color: #FEF8E8;
  }
}

.removeWordIcn {
  height: 16px;
  cursor: pointer;
}

.listWordsLabel {
  margin-bottom: 10px;
}

.listWords {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.btnSave {
  background: #0EBFA1;
  border-radius: 4px;
  padding: 14px 20px;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  width: fit-content;
  cursor: pointer;
}

.tagNameInput {
  border: 1px solid #DEE1E7;
  width: 400px;
  outline: none;
  border-radius: 12px;
  padding: 8px 16px;
  margin-bottom: 12px;
}