.competitiveCampaignAnalysis {
  &_row {
    display: flex;
    align-items: center;
    padding: 12px;
    background: #FFFFFF;
    border-bottom: 1px solid #D6DDE8;
  }

  &_number {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #787E93;
    width: 35px;
  }

  &_name {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: black;
    width: 60%;
    cursor: pointer;
  }

  &_date {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #787E93;
    width: 20%;
  }

  &_controller {
    padding-left: 15px;
    display: flex;
    gap: 30px;
  }

  &_arrow {
    position: relative;
    width: 30px;
    display: flex;
    justify-content: center;
  }

  &_img {
    width: 18px;
    height: 18px;
    margin-top: 3px;
    cursor: pointer;
  }

  &_verticalLine {
    display: flex;
    justify-content: center;
    position: absolute;
    top: -25px;
    width: 36px;
    height: 137px;
    padding-top: 10px ;
    background: #E8F1F9;
    cursor: pointer;
  }

  &_campaignLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #787E93;
    width: 24%;
    margin-left: 66px;
  }

  &_blockCampaigns {
    display: flex;
    gap: 25px;
  }

  &_referenceCampaign {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #262626;
    cursor: pointer;

    &:hover {
      color: #129FD4;
    }
  }

  &_campaign {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #262626;
    cursor: pointer;

    &:hover {
      color: #129FD4;
    }
  }
}