.messageErrorValidation {
  position: relative;
  display: flex;
  flex-direction: column;
  &_message {
    display: flex;
    align-items: center;
    position: absolute;
    top: -60%;
    left: 0;
    font-size: 16px;
    font-weight: 500;
    color: red;
    white-space: nowrap;
    padding: 10px;
    background: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0 0 8px 0 silver;
    z-index: 100;
  }

}