.sourceCell {
  &_root {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &_avatar {
    height: 64px;
    width: 64px;
    background: silver;
    border-radius: 3px;
  }

  &_text {
    font-size: 14px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    color: #262626;
  }

  &_textWithTooltip {
    white-space: nowrap;
    max-width: 160px;
    min-width: 160px;
    overflow: hidden;
    position: relative;
    font-size: 14px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    color: #262626;
  }
}

.hiddenField {
  content: ' ';
  position: absolute;
  background: rgba(0,0,0,0);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.76) 50.88%, rgba(255, 255, 255, 0.96) 69.18%, #FFFFFF 100%);
  width: 40px;
  height: 100%;
  right: -1px;
  top: 0;
}