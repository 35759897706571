.rootChartSettings {
  padding-left: 16px;
}
.dropdownTitile {
  color: #787E93;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  margin-left: 12px;
};
.dropdownChartSettings {
  width: fit-content;
  padding: 0px 14px;
  border: 1px solid #D6DDE8;
  border-radius: 6px;
  margin-left: 12px;
  margin-top: 12px;
  margin-bottom: 8px;
}
.campaignFiltersContainer {
  min-width: 345px;
  background: #fff;
  border-radius: 6px;
  height: fit-content;
}
.campaignFiltersContent {
  padding: 16px;
  padding-top: 8px;
  :global {
    .ant-collapse-content-box {
      padding-top: 0px !important;
    }
  }
}
.acceptFilters {
  background: #0EBFA1;
  border-radius: 4px;
  padding: 14px 20px;
  width: fit-content;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  margin-left: 16px;
  cursor: pointer;
}
.campaingStatusToggle {
  display: flex;
  align-items: center;
}
.campaingStatusContainer {
  display: flex;
  align-items: center;
  padding: 12px;
  justify-content: center;
  background: #DFFCF7;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.campaingStatusLabel {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #787E93;
  margin-right: 8px;
}
.campaingStatus {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #0EBFA1;
  margin-right: 8px;
}

.campaingStatusContainerDisabled {
  display: flex;
  align-items: center;
  padding: 12px;
  justify-content: center;
  background: #FDEBEB;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  :global {
    .ant-switch-small {
      background: #F36869;
    }
  }
}
.campaingStatusDisabled {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #F36869;
  margin-right: 8px;
}

.contentDatePickerContainer {
  position: relative;
  margin-bottom: 24px;
  padding-left: 12px;
  padding-top: 16px;
  &::after {
    content: ' ';
    position: absolute;
    width: calc(100% - 32px);
    height: 1px;
    background-color: #D6DDE8;
    left: 16px;
    bottom: -12px
  }
}

.radioButtons {
  display: flex;
  margin-top: 12px;
  margin-left: 4px;
}
.smallWidtTitle {
  width: 90px;
}

.chartTypesContainer {
  margin-left: 6px;
}