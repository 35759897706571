.wrapper {
  display: flex;
  gap: 6px;

  div {
    width: 8px;
    height: 8px;
    background-color: #0EBFA1;
    border-radius: 100%;
    animation: bounce 1.4s infinite ease-in-out both;

    &:nth-child(1) {
      animation-delay: -0.32s;
    }

    &:nth-child(2) {
      animation-delay: -0.16s;
    }
  }
}

@keyframes bounce {

  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1.0);
  }
}