.textInput {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-family: 'Montserrat', sans-serif;

  &_title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    color: #787E93;
  }
}