.markDown {
  &_bold {
    font-weight: bold;
  }
  &_italic {
    font-style: italic;
  }
}
.mdB {
  font-weight: bold;
}

.mbI {
  font-style: italic;
}

.mbC {
  text-decoration: line-through;
}

.mdTC {
  color: blue;
}

.mbL {
  color: white;
  background-color: darkblue;
}