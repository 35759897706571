.root {
  display: flex;
  align-items: center;
  gap: 20px;
  min-width: 125px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 131.5%;
}

.switchControl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 110px;
  gap: 7px;
}