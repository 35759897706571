.container {
  position: relative;
}

.title {
  color: #262626;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 16px;
}

.subTitle {
  color: #787E93;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  margin-bottom: 16px;
}

.comment {
  border: 1px solid rgba(222, 225, 231, 0.50);
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 16px;
  color:#262626;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%
}

.classForControll {
  top: 0px;
  right: 0px
}

.content {
  padding: 16px 32px;
}

.documents {
  &_item {
    border-radius: 6px;
    border: 1px solid rgba(222, 225, 231, 0.50);    
    display: flex;
    align-items: center;
    padding: 8px 14px;
    width: fit-content;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}

.documentLabel {
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  width: 240px;
  margin-right: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
}