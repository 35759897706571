.campaignDashboard {
  &_root {
    display: flex;
    flex-direction: column;
    padding: 15px 32px;
    gap: 10px;
    height: 100%;
    overflow-y: auto;
  }
}
