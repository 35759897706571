.containerText {
        // background-color: rgb(223, 252, 247);
        // width: fit-content;
        display: inline;
        p {
            display: inline;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            color: #262626;
        }
}

.lighthing {
    display: inline-block;
    background-color: rgb(223, 252, 247);
    margin-left: 4px;
    margin-right: 4px;
}