.root {
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px #D6DDE8;
  padding-bottom: 10px;
}

.checkboxLabel {
  white-space: nowrap;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #787E93;
}

hr {
  margin-top: 6px;
  box-sizing: inherit;
  height: 1px;
  width: 100%;
  margin-block-end: 16px;
  margin-block-start: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
  background: #D6DDE8;
  border-radius: 1px;
  border-color: transparent;
}