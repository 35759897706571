.root {
  display: flex;
  flex-direction: column;

  height: 178px;
  width: 100%;

  margin-right: 32px;
  padding: 36px 59px;

  background: #FFFFFF;
  border-radius: 6px;
}

.itemContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  gap: 24px;

  min-width: 450px;
  max-width: 800px;

  margin-bottom: 16px;

  &_title {
    min-width: 60px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: #787E93;

  }

  &_name {
    
  }

  &_inputContainer {
    display: flex;
    justify-content: flex-start;
    min-width: 460px;
    min-height: 24px;
    border: 1px solid transparent;
    border-radius: 6px;
    padding: 12px;
    box-sizing: border-box;

    &_text {
      width: 100%;
      border: 1px solid transparent;
      outline: none;
      background-color: white;

      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;

      color: #000000;
    }
  }

  &_textContainer {
    display: flex;
    justify-content: flex-start;
    min-width: 460px;
    min-height: 24px;
    border: 1px solid transparent;
    border-radius: 6px;
    padding-left: 12px;
    box-sizing: border-box;

    &_text {
      border: 1px solid transparent;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;

      color: #000000;
    }
  }
}

.saveButton {
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  width: 120px;
  height: 37px;
  background: transparent;
  cursor: default;

  &_text{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    text-align: right;
  }

  &_text.disable {
    cursor: default;
    color: gray;
  }

  &_text.enable {
    cursor: pointer;
    color: #0EBFA1;
  }

  &_text.disabled {
    color: #787E93;
  }
}

.saveButton.invisible {
  opacity: 0;
  cursor: default;
}


@media screen and (max-width: 1200px) {
    .itemContainer {
        &_inputContainer,
        &_textContainer {
            min-width: auto;
        }
    }

    .profileEditImage {
        margin-left: auto;
    }
}