.demoIcons_root {
  padding-top: 16px;
  overflow: scroll;
}

.groupIcons {
  background-color: rgba(228, 198, 198, 0.15);
  padding: 12px;
  padding-bottom: 0px;
  border-radius: var(--var-border-radius);
  &_label {
      margin-bottom: 16px;
      font-size: 20px;
      font-weight: 500;
  }
  &_list {
      display: flex;
      flex-wrap: wrap;
  }
  margin-bottom: 32px;
}

.icn {
  margin-right: 32px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  &_label {
      margin-right: 4px;
      font-weight: 400;
      font-size: 16px;
  }
  &_image {
      margin-top: 12px;
  }
}