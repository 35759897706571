.searchGroupLogicOperator {
  &_container {
    display: flex;
    align-items: center;
    gap: 14px;
  }

  &_text {
    width: 170px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #787E93;
  }
}

.boxDashed {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 40px;
  background: white;
  border: 1px dashed #0EBFA1;
  border-radius: 6px;

  &_text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #787E93;
  }
}

.boxDashedGreen {
  @extend .boxDashed;
  background: #DFFCF7;

  &_text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #787E93;
  }
}