.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 26px;

  &_leftBox {
    display: flex;
    align-items: center;
    width: 119px;
    height: 40px;
    margin: 16px 0;
    white-space: nowrap;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #0EBFA1;
  }

  &_leftBox.retina {
    font-size: 14px;
    line-height: 140%;
  }

  &_rightBox {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #787E93;
  }

  &_rightBox.retina {
    font-weight: 500;
    font-size: 12px;
  }
}

.titleContainer.retina {
  margin-top: 16px;
}

.statisticContainer {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 16px 0;
  gap: 42px;

  &_item {
    display: flex;
    align-items: center;
    gap: 18px;

    &_title {
      @extend .statisticContainer_item;
      gap: 8px;
    }
  }

  &_itemNumberText {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #787E93;
  }

  &_itemTitleText {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  &_rssTitle {
    @extend .statisticContainer_itemTitleText;
    color: #0EBFA1;
  }

  &_tgTitle {
    @extend .statisticContainer_itemTitleText;
    color: #3390EC;
  }

  &_okTitle {
    @extend .statisticContainer_itemTitleText;
    color: #E67E22;
  }

  &_vkTitle {
    @extend .statisticContainer_itemTitleText;
    color: #0077FF;
  }
  &_zenTitle {
    @extend .statisticContainer_itemTitleText;
    color: rgba(47,47,50,0.95);
  }
}

.statisticContainer.retina {
  gap: 8px;
}

.publicationsContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  gap: 24px;

  &_item {
    display: flex;
    flex-direction: column;
    width: 220px;
    height: 200px;
    padding: 10px;
    gap: 10px;
    background-color: #FFFFFF;
    border: 1px solid #DFFCF7;
    box-shadow: 0 0 15px rgba(14, 191, 161, 0.25);
    border-radius: 6px;
    overflow: hidden;

    &_header {
      display: flex;
      justify-content: space-between;
      width: 200px;
      height: 40px;
      padding: 10px;
      gap: 6px;
      background-color: #DFFCF7;
      border-radius: 6px;
    }

    &_date {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #262626;
    }

    &_time {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: #667085;
    }

    &_textContent {
      width: 100%;
      height: 120px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #787E93;
      overflow: hidden;
    }
  }

  &_gradientContainer{
    position: relative;
    width: 100%;
  }

  &_gradientBox {
    top: -180px;
    width: 100%;
    height: 180px;
    position: absolute;
    background: linear-gradient(180deg, rgba(255,255,255,0), rgba(255,255,255,0.8));
  }
}

.publicationsContainer.retina {
  gap: 16px;
}

.showButtonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -20px;
  z-index: 2;

  &_body {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #129FD4;
    cursor: pointer;
    background-color: transparent;
  }
}

.showButtonContainer_body.disable {
  color: #787E93;
  cursor: default;
}

.title {
  display: flex;
  align-items: center;
  width: 119px;
  height: 40px;
  margin: 16px 0;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #0EBFA1;
}

.title.retina {
  font-size: 14px;
  line-height: 140%;
}
