.linkCell {
  display: flex;
  align-items: center;
  color: #129FD4;
  cursor: pointer;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  user-select: none;
  &_label {
    margin-left: 4px;
  }
}