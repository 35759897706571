.sidebar {

  &_close {
    position: absolute;
    top: 17px;
    right: 25px;
  }

  &_refresh {
    position: absolute;
    top: 17px;
    right: 57px;
  }

  &_title {
    display: block;
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 24px 32px 16px 32px;

  }

  &_wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  &_info {
    height: 100%;
    padding: 16px 24px 0;
  }
}