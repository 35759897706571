.table {
  display: flex;
  flex-flow: column nowrap;
  font-family: "Montserrat", sans-serif;
  height: 100%;
}

.header {
  display: grid;
  align-items: center;
  margin-bottom: 10px;
  &CheckboxCell {
    font-size: 14px;
    font-weight: 500;
    color: #787E93;
    display: flex;
    align-items: center;
  }
}

.body {
  display: flex;
  flex-flow: column nowrap;
  max-height: 100%;
  overflow-y: auto;
}

.row {
  display: grid;
  border-bottom: 1px solid rgba(214, 221, 232, .5);
  border-left: 1px solid rgba(214, 221, 232, .5);
  border-right: 1px solid rgba(214, 221, 232, .5);
  background: #fff;

  &:first-child {
    border-top: 1px solid rgba(214, 221, 232, .5);
  }

  &Cell {
    font-size: 12px;
    font-weight: 400;
    color: #787E93;
    display: flex;
    align-items: center;
    min-height: 43px;
    min-width: 40px;
    word-break: break-all;
    padding: 6px 8px;
    &Plan {
      &Views {
        background: rgba(237, 255, 252, 0.5);
      }
      &ER {
        margin-left: 8px;
        background: rgba(237, 255, 252, 0.5);
      }
      &CPV {
        background: rgba(237, 255, 252, 0.5);
      }
    }
  
    &Fact {
      &Views {
        background: rgba(254, 248, 232, 0.5);
      }
      &ER {
        background: rgba(254, 248, 232, 0.5);
      }
      &CPV {
        background: rgba(254, 248, 232, 0.5);
      }
    }
  
    &Percent {
      &Views {
        background: rgba(248, 251, 255, 0.5);
      }
      &ER {
        margin-right: 8px;
        background: rgba(248, 251, 255, 0.5);
      }
      &CPV {
        background: rgba(248, 251, 255, 0.5);
      }
    }
  }

  &Selected {
    background: #EDFFFC;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}