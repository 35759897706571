.wrapper {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    max-width: 948px;
}

.tabs {
    display: flex;
    margin-bottom: 16px;
    
    button {
        padding: 10px 16px;
        border-radius: 6px;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        color: #000000;
        font-size: 16px;

        &[data-active="true"] {
            background: #FFFFFF;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
        }
    }
}
