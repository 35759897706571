.legendMonitoringChartViews {
    background-color: rgba(248, 251, 255, 0.50);
    padding: 24px;
    &_root {
        max-height: 159px;
        overflow: auto;
        border-radius: 6px;
    }
}

.legend {
    display: flex;
    align-items: center;
    gap: 8px;
    &:not(:last-child) {
        margin-bottom: 16px;
    }
    &_block {
        width: 27px;
        height: 16px;
        border: 1px solid #dfdfdf;
        background-color: #fff;
        border-radius: 2px;
        margin-left: 8px;
    }
}