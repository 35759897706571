.wrapper {
    min-width: 184px;
    position: relative;
}

.container {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
    p {
        color: #0EBFA1;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 0;
        display: flex;
    }

    input {
        opacity: 0;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        z-index: 1;
    }
}

.loader {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
}