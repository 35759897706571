
.labelLoading {
  width: 75px;
  margin-right: 8px;
}
.additionlInfo {
  span {
    margin-top: 8px;
    color: #787E93;
  }
}
.btnWithLabel {
  display: flex;
  align-items: center;
  transition: all 0.1s ease;
  .label {
    margin-right: 8px;
  }
}

.blockLoadFileLoading_root {
  user-select: none;
  position: relative;
  color: #787E93;
  border: 1px dashed #0EBFA1;
  border-radius: 6px;
  background-color: #EDFFFC;
  width: 172px;
  flex-shrink: 0;
  height: 79px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
}