.detailInfo {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: 'Montserrat', sans-serif;

  &_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_title {
    font-size: 20px;
    font-weight: 600;
  }

  &_btnPen {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &_btnPen.invisible {
    visibility: hidden;
  }

  &_name {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &_label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    color: #787E93;
    min-width: 115px;
  }

  &_campaignName {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
  }

  &_inputName {
    width: 300px;
    padding: 10px;
    border: 1px solid rgba(222, 225, 231, 0.5);
    border-radius: 8px;
    outline: none;
    &_wrapper {
      width: 300px;
      position: relative;
    }
  }

  &_description {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    position: relative;
  }

  &_textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid rgba(222, 225, 231, 0.5);
    border-radius: 8px;
    outline: none;
    resize: none;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    &_wrapper {
      width: 100%;
    }

  }

  &_text {
    white-space: pre-line;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 133%;
    width: 987px;
    height: 70px;
    padding: 0 4px 0 0;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  &_textOpen {
    height: auto;
    min-height: 70px;
    margin-bottom: 47px;
    transition: all 0.5s ease;
  }

  &_overflowOverlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: linear-gradient(transparent, white);
    background: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.76) 50.88%, rgba(255, 255, 255, 0.96) 69.18%, #FFFFFF 85.66%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    transition: all 0.3s ease;
  }

  &_btnContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
  }

  &_controller {
    border-radius: 100%;
    height: 31px;
    width: 31px;
    background-color: #F8FBFF;
    position: absolute;
    bottom: 16px;
    z-index: 10;
    right: 16px;
    fill: #787E93;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.5s ease;
    transform: rotate(180deg);
    padding-top: 2px;

    &.open {
      transition: all 0.5s ease;
      transform: rotate(0deg);
    }

    &:hover {
      background-color: #ecf1f9;
    }
  }
}