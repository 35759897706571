.wrapper {
    display: flex;
    flex-flow: column nowrap;
}

.inputs {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    gap: 28px;

    input {
        width: 100px;
        border: 1px solid rgba(222, 225, 231, 0.5);
        border-radius: 6px;
        height: 35px;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
    }
}
