.searchField {
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  margin-bottom: 8px;
  background: #FFFFFF;
  border-radius: 6px;
  border: 1px solid rgba(120, 126, 147, 0.30);

  &_focus {
    border: 1px solid #129FD4;
  }

  &_input {
    width: 440px;
    outline: 0;
    border: none;
    padding: 0 16px;
    color: #262626;
    font-size: 16px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 131.5%;
  }

  &_imgSearch {
    display: flex;
    margin: 0 0 0 24px;
    cursor: pointer;
  }

  &_imgCross {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
    margin-right: 24px;
    cursor: pointer;
  }
}