.campaignSearchRequest {
  &_root {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background: white;
    border-radius: 6px;
  }

  &_container {
    display: flex;
    height: auto;
    min-height: 654px;
    overflow-x: auto;
    width: 100%;
    padding: 24px;
    margin-bottom: 24px;
    gap: 24px;
  }

  &_contentColumn {
    display: flex;
  }

  &_controlsColumn {
    display: flex;
    align-items: flex-end;
  }
}

.group {
  &_container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: fit-content;
  }

  &_box {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    padding: 10px 20px;
    gap: 10px;
    border: 1px solid #D6DDE8;
    border-radius: 6px;
  }

  &_top {
    display: flex;
    justify-content: space-between;
  }

  &_title{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    color: #787E93;
  }
}

.groupRow {
  display: flex;
}

.previewCheckboxBox {
  display: flex;
}

.flexRow {
  display: flex;
  align-items: center;
}

.preview {
  &_text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #129FD4;
  }

  &_cancelText {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #787E93;
  }
}