.iconSwitcher {
    display: flex;
    align-items: center;
    gap: 8px;

 &_outerCircle {
    width: 20px;
    height: 20px;
    background: none;
    border: 1px solid #0EBFA1;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

  &_outerCircle.disabled {
    cursor: no-drop;
  }


  &_circle {
    width: 12px;
    height: 12px;
    background-color: #0EBFA1;
    border-radius: 6px;
  }

  &_circle.disabled {
    visibility: hidden;
  }

  &_labelButton {
    display: flex;
    align-items: center;
    background: none;
    padding: 0;
  }

  &_labelButton.disabled {
    cursor: no-drop;
  }
}

.text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #1D1D1B;
}