.content {
  max-width: 150px;
  cursor: default;
  word-break: break-all;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #262626;
}

.label {
  display: flex;
  gap: 16px;
  white-space: nowrap;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #262626;
  cursor: pointer;
  align-items: center;
}