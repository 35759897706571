.projectInfoFeatures {
  &_root {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 16px;
  }

  &_buttonContainer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  &_blockName {
    width: 350px;
  }
}