.containerScroll {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow-y: auto;
	padding-top: 4px;
	padding-bottom: 4px;
	padding-right: 6px;
	padding-left: 4px;
}

.menu {

	&.withScroll {
		// overflow-y: auto;
		width: 100%;
	}
}

.menu::-webkit-scrollbar {
	width: 7px;
	position: absolute;
}

.containerDropdown {
	:global {
		.ant-dropdown-menu {
			padding-top: 0;
			padding-bottom: 0;
			border-radius: 6px;
			overflow: hidden;
			box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
		}
	}
}

.item {
	position: relative;
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	color: #262626;
	background-color: #ffffff;
	font-weight: 500;
	line-height: 20px;
	padding: 0px 12px 0px 12px;
	border-radius: 8px;

	&.main {
		border: 1px solid #DEE1E780;
		transition: box-shadow 0.2s ease, background-color 0.3s ease;
		display: flex;
		align-items: center;
		cursor: pointer;

		&:hover {
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
		}

		&.disabled {
			color: #E1E4E7;
			background-color: #fff;

			&:hover {
				box-shadow: none;
				background-color: #fff;
			}
		}
	}

	&.content {
		width: 100%;
		padding: 14px;
		border-radius: 0px;
		display: flex;
		transition: background-color 0.3s ease;
		cursor: pointer;

		&.checked {
			background-color: #DEE1E780;

		}

		&:hover {
			background-color: #DEE1E74D;
		}

		&:active {
			background-color: #DEE1E780;
		}
	}

	// #DEE1E74D
	&.pressed {
		border: 1px solid rgba(0, 0, 0, 0);
		background-color: #DEE1E74D;
	}
}

.containerCheckAll {
	padding: 4px 12px 4px 4px;
}

.checkAll {
	border-bottom: 1px solid rgba(222, 225, 231, 0.50);
}