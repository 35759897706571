.erFilter {
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: absolute;
  top: 55px;
  left: 12px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  color: black;
  width: fit-content;
  height: fit-content;
  padding: 12px;
  background: #FFFFFF;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  z-index: 100;

}

.sorting {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  color: #7C848B;
  margin-top: 10px;
  background: #FFFFFF;

  &_row {
    display: flex;
    align-items: center;
    color: black;
    margin-top: 6px;
  }

  &_img {
    margin-right: 5px;
    padding-bottom: 2px;
  }

  &_symbol {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    cursor: pointer;
  }

  &_symbol:hover {
    color: #0EBFA1;
  }
}

.title {
  color: #7C848B;
  padding: 0;
  margin: 0;
}

.filterContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
}