
.root_ModalEngagement {
    width: 780px;
    position: relative;
    margin-bottom: 16px;
    background: white;
    padding: 32px;
    border-radius: 12px;
}
.header {
    display: flex;
    align-items: center;
}
.bar {
    margin-right: 12px;
    cursor: pointer;
    &.select {
        color: #0EBFA1;
    }
}

.title {
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 32px;
margin-bottom: 16px;
color: #0EBFA1;
}

.chartTypes {
    display: flex;
    align-items: center;
}

.containerIcon {
    cursor: pointer;
    fill: #D6DDE8;
    transition: fill 0.3s;
    &:hover {
        transition: fill 0.3s;
        fill: #787E93;
    }
}

.selectChartType { 
    cursor: pointer;
    fill: #787E93;
    transition: fill 0.3s;
}

.containerIconClose {
    fill: #787e93d4;
    cursor: pointer;
    &:hover {
        fill: #787E93;
    }
}

.containerChekboxes {
    display: flex;
    margin-top: 8px;
    margin-left: 16px;
}

.checkBoxWithLabel {
    display: flex;
    margin-right: 32px;
    align-items: center;
} 
.checkBoxLabel {
    margin-left: 8px;
    margin-right: 8px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.likesColor {
    width: 27px;
    height: 16px;
    border: 1px solid #0EBFA1;
    border-radius: 2px;
    background-color: #DFFCF7;
}
.commentColor {
    width: 27px;
    height: 16px;
    border: 1px solid #E67E22;
    border-radius: 2px;
    background-color: #FEF8E8;
}
.viewsColor {
    width: 27px;
    height: 16px;
    border: 1px solid #F36869;
    border-radius: 2px;
    background-color: #FDEBEB;
}

.fullScreen {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: #fff;
  }