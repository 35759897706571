$largeWidth: 300px;
$largeHeight: 52px;
$mediumWidth: 250px;
$mediumHeight: 40px;
$smallWidth: 200px;
$smallHeight: 32px;
$largeFontSize: 16px;
$mediumFontSize: 14px;
$smallFontSize: 12px;

.authorFilter {
  position: absolute;
  top: 55px;
  left: 12px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  color: black;
  width: 350px;
  height: fit-content;
  max-height: 420px;
  padding: 12px;
  background: #FFFFFF;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  z-index: 100;

  &_search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #787E93;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid rgba(222, 225, 231, 0.5);
    border-radius: 6px;
  }

  &_input {
    outline: none;
    border: none;
    width: 220px;
  }

  &_img {
  }

  &_headCheckBox {
    border-bottom: 2px solid rgba(222, 225, 231, 0.5);
    margin-bottom: 10px;
  }

  &_list {
    max-height: 204px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &_line {
  }
}

.sorting {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  color: #7C848B;
  margin-top: 10px;
  background: #FFFFFF;

  &_row {
    display: flex;
    align-items: center;
    color: black;
    margin-top: 6px;
  }

  &_img {
    margin-right: 8px;
    padding-bottom: 2px;
  }

  &_symbol {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    cursor: pointer;
  }

  &_symbol:hover {
    color: #0EBFA1;
  }
}

.item {
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  color: #262626;
  background-color: #ffffff;
  font-weight: 500;
  line-height: 20px;
  padding: 0px 12px 0px 12px;
  border-radius: 8px;

  &.main {
    border: 1px solid #DEE1E780;
    transition: box-shadow 0.2s ease, background-color 0.3s ease;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    }

    &.disabled {
      color: #E1E4E7;
      background-color: #fff;

      &:hover {
        box-shadow: none;
        background-color: #fff;
      }
    }

    &.large {
      min-width: $largeWidth;
      min-height: $largeHeight;
      max-width: $largeWidth;
      max-height: $largeHeight;
      font-size: $largeFontSize;
      white-space: normal;

      span {
        margin: 0;
        max-width: calc($largeWidth - 64px);
        max-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &.medium {
      min-width: $mediumWidth;
      min-height: $mediumHeight;
      max-width: $mediumWidth;
      max-height: $mediumHeight;
      font-size: $mediumFontSize;
      white-space: normal;

      span {
        margin: 0;
        max-width: calc($mediumWidth - 64px);
        max-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &.small {
      min-width: $smallWidth;
      min-height: $smallHeight;
      max-width: $smallWidth;
      max-height: $smallHeight;
      font-size: $smallFontSize;
      white-space: normal;

      span {
        margin: 0;
        max-width: calc($smallWidth - 64px);
        max-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &.content {
    width: 100%;
    padding: 14px;
    border-radius: 0;
    display: flex;
    transition: background-color 0.3s ease;
    cursor: pointer;

    &.selected {
      background-color: #DEE1E780;

    }

    &.large {
      min-width: $largeWidth;
      max-width: $largeWidth;
      font-size: $largeFontSize;
      white-space: normal;
    }

    &.medium {
      min-width: $mediumWidth;
      max-width: $mediumWidth;
      font-size: $mediumFontSize;
      white-space: normal;
    }

    &.small {
      padding: 9px;
      padding-left: 14px;
      min-width: $smallWidth;
      max-width: $smallWidth;
      font-size: $smallFontSize;
      white-space: normal;
    }

    &:hover {
      background-color: #DEE1E74D;
    }

    &:active {
      background-color: #DEE1E780;
    }
  }

  &.pressed {
    border: 1px solid rgba(0, 0, 0, 0);
    background-color: #DEE1E74D;
  }
}

.containerScroll {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 6px;
}

.menu {
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
}

.menu::-webkit-scrollbar {
  width: 7px;
  position: absolute;
}

.emptyMessage {
  display: flex;
  padding: 12px;

  color: #E67E22;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 131.5%;
}