.monitoringViewsTabs {
    &_root {
        margin-top: 34px;
        width: calc(100% - 24px);
        position: relative;
        height: fit-content;
        display: flex;
        align-items: center;
    }
}

.tab {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgba(14, 191, 161, 0.30);
    display: flex;
    padding: 10px 16px 10px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1D1D1B;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    transition: all 0.2s;
    cursor: pointer;
    user-select: none;
}

.select {
    &:first-child {
        border-top-left-radius: 6px;
    }
    &:last-child {
        border-top-right-radius: 6px;
    }
    &:not(:last-child) {
        &:not(:first-child) {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;    
        }
    }
    border: 1px solid rgba(14, 191, 161, 0.30);
    background: rgba(237, 255, 252, 0.40);
    color: #262626;
    font-weight: 600;
    cursor: default;
}