.campaignDnDUsersReaction {
  &_root {
    display: flex;
    align-items: center;
    padding: 16px;
    gap: 8px;
    width: 345px;
    min-height: 50px;
    background: white;
    border-radius: 6px;
    font-family: 'Montserrat', sans-serif;
  }
}

.statisticsByCategory {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 15px;
  border-radius: 6px;
  background: white;
  font-family: 'Montserrat', sans-serif;

  &_title {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #787E93;
  }

  &_amount {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #787E93;
  }

  &_percent {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
}