.titleChart {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #787E93;
  margin-bottom: 16px;
}

.containerTonalityChart {
  background: #FFFFFF;
  border-radius: 6px;
  padding: 24px;
  margin-bottom: 16px;
  position: relative;
  height: fit-content;
}
