.root {
  display: flex;
  gap: 16px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  height: 100px;
  gap: 18px;
  padding: 16px;
  background: white;
  border-radius: 6px;
}

.trackedSourcesBlock {
  width: 510px;
}

.tonalitySourcesBlock {
  width: 530px;
}

.platformsBlock {
  width: 400px;
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #787E93;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 24px;
}

.checkboxLabel {
  white-space: nowrap;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: black;
}