.sourcesLists {
  &_root {
    padding: 16px 24px;
  }
}

.message {
    &_succes {
      margin-top: calc(100vh - 80px);
      :global {
        .ant-message-notice-content {
          background: #0EBFA1;
          border-radius: 8px;
          box-shadow: 0px 32px 32px -16px rgba(0, 0, 0, 0.08), 0px 4px 24px -16px rgba(0, 0, 0, 0.12);
          color: #FFF;
          font-family: Montserrat;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%; 
        }
      }
    }
    &_error {
      margin-top: calc(100vh - 80px);
      :global {
        .ant-message-notice-content {
          background: #F36869;
          border-radius: 8px;
          box-shadow: 0px 32px 32px -16px rgba(0, 0, 0, 0.08), 0px 4px 24px -16px rgba(0, 0, 0, 0.12);
          color: #FFF;
          font-family: Montserrat;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%; 
        }
      }
    }
  }


.empty {
  width: 100%;
  padding: 22px 16px 35px 15px;
  border-radius: 16px;
  background: #FFF;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  align-items: center;

  p {
    margin: 16px 0px;
    color: #AAAAAA;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 131.5%
  }

  button {
    border-radius: 6px;
    background: #0EBFA1;
    width: 173px;
    height: 40px;
    padding: 0px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    color: #FFF;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 18.9px */

    &:hover {
      background: #2BD3B7;
    }
  }
}