.wrapper {
    width: 100%;
    display: flex;
    position: relative;
    max-height: 100%;
    // overflow-y: auto;
}

.table {
    display: flex;
    flex-flow: column nowrap;
    font-family: "Montserrat", sans-serif;
    width: 100%;
    height: 100%;
    padding-right: 20px;
}

.header {
    display: grid;
    min-width: 100%;
    width: fit-content;
    background: #e8f1f9;
    padding: 0 16px;
    align-items: center;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    &CheckboxCell {
        font-size: 14px;
        font-weight: 500;
        color: #787e93;
        display: flex;
        align-items: center;
        margin: 0 auto;
    }
}

.body {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
}

.row {
    display: grid;
    padding: 0 16px;
    border: 1px solid #fff;
    border-bottom: 1px solid #dee1e7;
    background: #fff;
    min-width: 100%;
    width: fit-content;
    &Cell {
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        display: flex;
        align-items: center;
        min-height: 43px;
        word-break: break-all;
        padding: 0 12px;
        position: relative;
        white-space: nowrap;
        overflow: hidden;

        &:nth-child(1) {
            font-weight: 500;
        }
    }

    &Checked {
        border: 1px solid #edfffc;
        background: #edfffc;
    }

    &:hover {
        border: 1px solid #80D9CA;
    }

    &Focused {
        border: 1px solid #EDFFFC;
        background: #EDFFFC;
    }
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.filtered {
    padding: 10px 0;
    padding-left: 60px;
    background: #fff;
    display: flex;
    align-items: center;

    p {
        margin-bottom: 0;
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18.9px;
        color: #000;
    }

    button {
        padding-left: 8px;
        color: #129fd4;
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 135%;
    }
}


.hiddenField {
    content: ' ';
    position: absolute;
    background: rgba(0,0,0,0);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.76) 50.88%, rgba(255, 255, 255, 0.96) 69.18%, #FFFFFF 100%);
    width: 40px;
    height: 100%;
    right: -1px;
    top: 0;
}