.titile_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.important {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}
.customControls {
  color: #0EBFA1;
  font-weight: 400;
  font-size: 32px;
  line-height: 2px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}