.root {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: stretch;
  z-index: 10;
}

.contentContainer {
  position: absolute;
  border-radius: 6px;
  background: white;
  padding: 10px 10px 0 10px;
  // white-space: nowrap;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1));
}

.hidden {
  visibility: hidden;
}

.triangleRight {
  position: relative;
  top: 5px;
  left: -35px;
  display: block;
  color: red;
  width: 0;
  height: 0;
  border-right:  30px solid transparent;
  border-bottom: 21px  solid white;
  border-left:   30px solid transparent;
  -webkit-transform: rotate(-370deg);
}

.text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #F36869;
}