.categoryContainer {
    font-family: 'Montserrat';
    padding: 8px;
    transition: 0.25s ease;
    color: #000000;
    cursor: pointer;
    flex-grow: 1;
}
.categoryLabel {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
}
.subCategoriesCounter {
    font-weight: 600;
}
.categoryPercent {
    white-space: nowrap;
    margin-top: 12px;
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
    transition: 0.3s ease;
}
.hiddenPercent {
    white-space: nowrap;
    margin-top: 12px;
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
    opacity: 0;
    position: absolute;
}
.hiddenLabel {
    white-space: nowrap;
    flex-grow: 1;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    opacity: 0;
    position: absolute;
    align-items: center;
}