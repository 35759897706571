.wrapper {
    display: flex;
    flex-flow: column nowrap;
    padding-right: 32px;
    overflow-y: auto;
}

.header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    margin-bottom: 16px;
}