.radioButtons {
  display: flex;
  margin-top: 12px;
  margin-left: 4px;
}
.dropdownTitile {
  color: #787E93;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  margin-left: 12px;
};
.smallWidtTitle {
  width: 90px;
}