.wrapper {
    overflow: hidden;
    position: relative;
    scrollbar-width: none;
    height: 20px;
}

.container {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    align-items: center;
    white-space: nowrap;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    
    a {
        font-size: 12px;
        font-family: Montserrat;
        line-height: 16px;
        color: #787e93;

        &:last-of-type {
            color: #262626;
        }

        &:hover {
            color: #0ebfa1;
        }
    }

    p {
        color: #000;
        font-size: 12px;
        font-family: Manrope;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 0;

        &:last-of-type {
            display: none;
        }
    }
}