.rootMetrics{
  padding: 15px;
  border-radius: 6px;
  background-color: #ffffff;
  color: #262626;
  font-family: 'Montserrat';
  border: 1px solid #ffffff;
  margin-bottom: 16px;

  &.selectRoot {
    border: 1px solid #0EBFA1;
  }
}
.titleMetrics {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 12px;
}

.dropdownMetrics {
  width: fit-content;
  padding: 0px 14px;
  border: 1px solid #D6DDE8;
  border-radius: 6px;
}