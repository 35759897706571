.campaignAutoCompleteTrackedLinks {
  &_root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 16px 16px 16px 0;
    background: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    max-height: 200px;
    overflow-y: hidden;
  }
}

.wrapper  {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.sourceButton {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  padding: 6px 6px 6px 24px;
  background: none;

  &:hover {
    background: #E8F1F9;
    cursor: pointer;
  }

  &:active {
    background: rgba(222, 225, 231, 0.50);
  }

  &_title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #262626;
  }

  &_link {
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #787E93;
  }
  &_icon{
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding-left: 26px;
  gap: 16px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #787E93;
}