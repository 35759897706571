.chartContainer {
    width: 100%;
    height: 100%;
    height: 240px;
    width: 650px;
    position: relative;
}
// .defaultContainerTooltip {
//     background-color: white;
//     border-radius: 6px;
//     box-shadow: 0px 0px 15px rgba(14, 191, 161, 0.25);
// }

.container {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0px 0px 15px rgba(14, 191, 161, 0.25);
    padding: 12px;
    font-family: 'Montserrat';
    font-style: normal;
    width: fit-content;
    display: block;
    min-width: 250px;
}
.date {
    font-size: 12px;
    line-height: 16px;
    color: #787E93;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
}
.title {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #787E93;
    margin-bottom: 2px;
}
.platform {
    margin-right: 8px;
}
.name {
    margin-left: 8px;
    font-weight: 500;
    font-size: 14px;
    color: #262626;
}
.link {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #129FD4;
    cursor: pointer;
}
.countSubscribers {
    font-size: 12px;
    margin-bottom: 8px;
}