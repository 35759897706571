.wrapper {
    display: flex;
    padding: 0;
    gap: 8px;
    width: 447px;
    background: white;
    align-items: center;
}

.root.red {
    border-bottom: 1px solid #f36869;
}

.button {
    background: none;
    padding: 0;
    height: 24px;
    margin-top: 25px;
}

.hidden {
  pointer-events: none;
  opacity: 0;
}
