.containerIcon {
    fill: none;
    position: absolute;
    right: 4px;
    top: 4px
}
.containerFind {
    position: relative;
    margin-bottom: 10px;
    margin-top: 8px;
    input {
        width: calc(100% - 24px);
        border: none;
        &:focus-visible {
            border: none;
            outline: none;
        }
    }
    &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        bottom: -6px;
        left: 0px;
        background: #0EBFA1;
    }
}