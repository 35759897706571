.campaignMenuTabsContainer {
  &_root {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding-right: 32px;
  }
}

.height300 {
  // min-height: 260px;
}