.conatinerDatePicker {
  position: relative;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #262626;
  text-transform: capitalize;
}

.conatinerCalendar {
  z-index: 1;
  position: absolute;
  background: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  top: 32px;
  right: 0px;
  border-radius: 6px;
}

.bodyCalendar {
  display: flex;
}

.weekContainer {
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 2px;
  }
}

.labelDay {
  font-family: 'Montserrat';
  flex-grow: 0;
  flex-shrink: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 131.5%;
  min-width: 32px;
  min-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  user-select: none;
  cursor: pointer;
  &.name {
    color: #0EBFA1;
  }
  &.passive {
    color: #E1E4E7;
  }
  &.selectDay {
    background-color: #0EBFA1;
    color: white;
  }
  &.initDay {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    color: white;
    background-color: #0EBFA1;
  }
  &.lastDay {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    color: white;
    background-color: #0EBFA1;
  }
  &.hide {
    background-color: #fff;
    color: rgba(0,0,0,0);
    cursor: default;
  }
  // &:not(:last-child) {
  //   padding-right: 6px;
  // }
}

.containerMonth {
  padding: 24px;
}

.monthTitle {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.icn {
  user-select: none;
  position: absolute;
  fill: #0EBFA1;
  cursor: pointer;
  &.next{
    right: 0px;
    top: 5px;
  }
  &.prev {
    left: -8px;
    top: -10px;
    transform: rotate(180deg);
  }
}

.currentPeriod {
  text-transform: none;
  color: #787E93;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
}

.footer {
  display: flex;
  flex-flow: row nowrap;
  padding-bottom: 18px;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 13px;

  &Actions {
      display: flex;
      gap: 16px;

      button {
          height: 48px;
          padding-left: 20px;
          padding-right: 29px;
          border: 1px solid #0EBFA1;
          border-radius: 4px;
          color: #0EBFA1;
          font-size: 14px;
          font-weight: 500;
          background: none;
          font-family: 'Montserrat', sans-serif;
          text-transform: capitalize;
      }
  }

  &ConfirmButton {
      background: #0EBFA1;
      border-radius: 4px;
      height: 48px;
      padding: 0 20px;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 500;
      font-family: 'Montserrat', sans-serif;
  }
}