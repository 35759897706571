.root {
  display: flex;
  align-items: center;
  gap: 24px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 12px;
  width: 40px;
  height: 40px;
  border-radius: 4px;

  &:disabled {
    cursor: not-allowed;
  }
}

.green {
  border: 1px solid #0EBFA1;
}

.red {
  border: 1px solid #F36869;
}

.tooltip {
  cursor: pointer;
}

.tooltip::after {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;

  width: 160px;

  margin-top: 10px;
  margin-left: -120px;

  background: #FFFFFF;
  border-radius: 6px;


  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  content: attr(data-tooltip);
  opacity: 0;

  position: absolute;
  visibility: hidden;

  transition: all 0.4s ease-in-out;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;

  color: #262626;
  z-index: 100;
}

.tooltip:hover {
  position: relative;
}

.tooltip:hover::after {
  opacity: 1;
  visibility: visible;
}