.statusChart {
  position: absolute;
  width: 100%;
  height: calc(100% - 80px);
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #262626;
  &_message {
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin: 24px 0px;
  }
  &_title {
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    align-items: center;
    svg {
      margin-right: 16px;
    }
  }
}