.myCampaignContainer {
  padding-left: 0px;
  height: 100%;
}
.myCompaignInfo {
  display: flex;
  height: 100%;
  width: 100%;
}
.myCompaignContent {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 16px;
  max-width: 1024px;
}
.campaingError {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #E67E22;
  background: white;
  border-radius: 6px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.isNonePlots {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000;
  border-radius: 6px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inactiveCampaingContainer {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000;
  border-radius: 6px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerGlobalInfo {
  display: flex;
  background-color: white;
  border-radius: 6px;
  padding: 16px 24px;
  width: fit-content;
  height: fit-content;
  margin-bottom: 16px;
}

.blockInfo {
  &:not(:last-child) {
    margin-right: 26px;
  }
}

.blockTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #787E93;
}

.contentContainer {
  display: flex;
  align-items: center;
}

.currentContent {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #787E93;
  margin-right: 10px;
}

.updateContent {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: #0EBFA1;
}

@media (max-width: 1513px) {
  .myCompaignContent {
    max-width: 842px;
  }
}

.containerSorts {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.containerSortItem {
  background: #FFFFFF;
  border-radius: 6px;
  font-size: 12px;
  line-height: 20px;
  padding: 5px 10px;
  cursor: pointer;
  user-select: none;
  &:not(:last-child) {
    margin-right: 14px;
  }
}

.isSelectSort {
  background: #E8F1F9;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}