.editNumber {
    &_content {
        display: flex;
        flex-direction: column;
        font-family: Montserrat, sans-serif;
    }

    &_approval {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        margin-top: 12px;
        gap: 8px;
        color: #787E93;
    }

    &_close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        object-fit: contain;
        cursor: pointer;
    }

    &_complete {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        object-fit: contain;
        cursor: pointer;
    }
}
