.sourcesList {
  &_root {
    position: relative;
    height: 100%;
    max-width: 1460px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow-y: auto;
    padding-right: 14px;
    padding-bottom: 14px;
  }
}

.tableContainer {
  height: 100%;
  padding-bottom: 14px;
  padding-right: 14px;
  scroll-padding: 20px;
  overflow-y: auto;
}

.table {
  max-width: 1460px;
  // border-collapse: collapse;
  table-layout: fixed;
}

.thead {
  max-width: 1460px;
  position: sticky;
  top: 0;
  margin: 0;
  background: lightgray;
  height: 60px;
  min-height: 60px;
  z-index: 10;
}

.th {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  text-align: start;
  color: #5C606D;
  background: #E8F1F9;
  width: 360px;
  // padding: 0 46px;
  max-height: 60px;
  height: 60px;
  white-space: nowrap;
}

.th:nth-of-type(1) {
  padding-left: 22px;
}

.th:nth-of-type(1)::after {
  content: '';
  position: absolute;
  top: -2%;
  left: -0.5%;
  height: 100%;
  max-width: 1440px;
  border: 2px solid #E8F1F9;
}

.th:nth-of-type(2) {
  width: 140px;
  max-width: 80px;
  padding-left: 12px;
}

.th:nth-of-type(3) {
  width: 130px;
  padding-left: 12px;
}

.th:nth-of-type(4) {
  width: 150px;
  padding-left: 12px;
}

.th:nth-of-type(5) {
  width: 160px;
  max-width: 150px;
  padding-left: 12px;
}

.th:nth-of-type(6) {
  width: 143px;
  padding-left: 12px;
}

.th:nth-of-type(7) {
  width: 110px;
  padding-left: 12px;
}

.th:nth-of-type(8) {
  width: 255px;
  padding-left: 12px;
}

.th:nth-of-type(9) {
  width: 110px;
  padding-left: 12px;
}

.th:nth-of-type(10) {
  width: 100px;
  padding-left: 12px;
}

.title {
  display: flex;
  width: fit-content;
  padding: 10px;
  gap: 11px;
  border: 1px solid transparent;
  position: relative;
}

.title:hover {
  box-shadow: 0 0 5px silver;
  border-radius: 5px;
}

.tbody {
  border: 1px solid #DEE1E780;
  border-top: none;
}

// tr:first-child {
//   position: sticky;
//   top: 0;
// }

.tr {
  vertical-align: top;
  background: #FFFFFF;
  border: 1px solid #DEE1E780;
  transition: box-shadow 0.2s ease;

  &:hover {
    box-shadow: inset -1px -1px 0px #80D9CA, inset 1px 1px 0px #80D9CA;
    // border-radius: 6px;
  }

  &:active {
    & > div {
      background: #EDFFFC;
    }

    box-shadow: none;
    background: #EDFFFC;
  }
}

.td {
  padding: 10px 0;
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;

  &_value {
    color: #262626;
    font-size: 14px;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    line-height: 135%;
  }
}

.tableControls {
  width: 100%;
  max-height: 40px;
  padding: 10px 36px;
  background: white;
  border-left: 1px solid rgba(222, 225, 231, 0.50);
  border-right: 1px solid rgba(222, 225, 231, 0.50);
  transition: box-shadow 0.2s ease;

  &_label {
    color: #000;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
  }

  &_cleanBtn {
    margin-left: 10px;
    color: #129FD4;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
  }
}


