.sourceCell {
  display: flex;
  align-items: center;
  height: 84px;
  &_checkbox {
    margin-right: 10px;
  }
  &_img {
    position: relative;
    overflow: hidden;
    width: 96px;
    height: 64px;
    display: flex;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
    }

  }
  &_index {
    display: none;
  }
  &_name {
    cursor: pointer;
    padding: 10px;
    padding-left: 12px;
  }
}

.hiddenField {
  content: ' ';
  position: absolute;
  background: rgba(0,0,0,0);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.76) 50.88%, rgba(255, 255, 255, 0.96) 69.18%, #FFFFFF 100%);
  width: 40px;
  height: 100%;
  right: -1px;
  top: 0;
}