.containerCalendar {
    background: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}

.bodyCalendar {
    display: flex;
}

.weekContainer {
    display: flex;
    align-items: center;
    &:not(:last-child) {
        margin-bottom: 2px;
    }
}

.labelDay {
    font-family: "Montserrat";
    flex-grow: 0;
    flex-shrink: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 131.5%;
    min-width: 32px;
    min-height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
    user-select: none;
    cursor: pointer;
    &.name {
        color: var(--var-blue-default-primary);
    }
    &.passive {
        color: #e1e4e7;
    }
    &.selectDay {
        background-color: var(--var-blue-default-primary);
        color: white;
        border-radius: 40%;
    }
    &.initDay {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        color: white;
        background-color: var(--var-blue-default-primary);
    }
    &.lastDay {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        color: white;
        background-color: var(--var-blue-default-primary);
    }
    &.hide {
        background-color: #fff;
        color: rgba(0, 0, 0, 0);
        cursor: default;
    }
}

.containerMonth {
    padding: 24px;
    & .icn {
        height: 16px;
        user-select: none;
        fill: var(--var-blue-default-primary);
        cursor: pointer;
        & svg {
            stroke: var(--var-blue-default-primary);
        }
        &.prev {
            transform: rotate(180deg);
        }
    }
}

.monthTitle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    & .icn {
        stroke: var(--var-blue-default-primary);
    }
}
