.root_iconButton {
    position: relative;
    border-radius: 100%;
    background-color: transparent;
    fill: var(--var-gray);
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    &.isLoad{
        background-color: black;
        fill: black;
        transform: scale(0.75);
    }
}

.iconButton{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.icn {
    display: flex;
    align-items: center;
    justify-content: center;
    & svg {
        stroke: var(--var-gray);
    }
}
.loadingContainer {
  position: relative;
  width: 32px;
  height: 32px;
}
.loading {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 34px;
    width: 34px;
}
.loading span {
    display: block;
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    margin: auto;
    height: 34px;
    width: 34px;
}
.loading span::before {
    content: "";
    display: block;
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    margin: auto;
    height: 34px;
    width: 34px;
    border: 3px solid var(--var-green);
    border-bottom: 3px solid transparent;
    border-radius: 50%;
    -webkit-animation: circle 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
            animation: circle 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes circle {
    0% {
        -webkit-transform: rotate(0deg);
    }
    40% {
        -webkit-transform: rotate(180deg);
    }
    50% {
        border-bottom: 3px solid var(--var-green);
    }
    60% {
        -webkit-transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes circle {
    0%   {
        transform: rotate(0deg);
    }
    40%  {
        transform: rotate(180deg);
    }
    50% {
        border-bottom: 3px solid var(--var-green);
    }
    60%  {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loading span::after {
    content: "";
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    margin: auto;
    width: 6px;
    height: 6px;
    background: var(--var-green);
    border-radius: 50%;
    -webkit-animation: ball 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
            animation: ball 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes ball {
    0%   { -webkit-transform: translate3d(0, -32px, 0) scale(0, 2); opacity: 0; }
    50%  { -webkit-transform: translate3d(0, 0, 0) scale(1.25, 1.25); opacity: 1; }
    100% { -webkit-transform: translate3d(0, 8px, 0) scale(0, 0); opacity: 0; }
}
@keyframes ball {
    0%   { transform: translate3d(0, -32px, 0) scale(0, 2); opacity: 0; }
    50%  { transform: translate3d(0, 0, 0) scale(1.25, 1.25); opacity: 1; }
    100% { transform: translate3d(0, 8px, 0) scale(0, 0); opacity: 0; }
}