.container {
  display: flex;
  align-items: center;
  align-content: center;
  width: max-content;
  margin: 3px 0;
  gap: 12px;

  //> input {
  //  height: 16px;
  //  width: 16px;
  //  -webkit-appearance: none;
  //  -moz-appearance: none;
  //  -o-appearance: none;
  //  appearance: none;
  //  border: 2px solid #D2D5DA;
  //  border-radius: 4px;
  //  background-color: white;
  //  cursor: pointer;
  //}
  //
  //> span {
  //  color: white;
  //  padding: 0.5rem 0.25rem;
  //}
  //
  //> input:checked {
  //  background: #0EBFA1;
  //  border: none;
  //}
  //
  //> input:checked + span::before {
  //  content: '\2713';
  //  display: block;
  //  text-align: center;
  //  color: white;
  //  position: absolute;
  //  left: 0.7rem;
  //  top: 0.2rem;
  //}
  //
  //> input:active {
  //  border: 2px solid #34495E;
  //}
}

.text {
  margin-left: 8px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #262626;
  cursor: pointer;
}

.text.retina {
  font-size: 14px;
  line-height: 140%;
}

.labelContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.red {
  border: 1px solid #F36869;
}

//.ant-checkbox-wrapper.ant-checkbox.ant-checkbox-input.ant-checkbox-inner {
//  border: 2px solid #F36869 !important;
//}

//.ant-checkbox .ant-checkbox-inner {
//  background-color: var(--background-color);
//  border-color: var(--border-color);
//}

//.ant-checkbox .ant-checkbox-inner {
//  border: 2px solid #D2D5DA;
//  background-color: white;
//}