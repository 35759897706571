.campaignTrackedWordsBlock {
  &_root {
    display: flex;
    flex-direction: column;
    width: 500px;
    gap: 8px;
    background-color: white;
    border-radius: 6px;
  }
}

.root.fullScreen {
  width: 100%;
}

.flexRow {
  display: flex;
}

.favoriteBtn {
  display: flex;
  align-items: center;
  padding: 0;
  background: none;
  margin-top: 25px;
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #787E93;
}

.input {
  width: 440px;
  height: 27px;
  background: white;
  border: none;
  outline: none;

  &_text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #262626;
  }
}

.wordsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow-x: scroll;
  width: 415px;
  gap: 10px;
}

.wordsContainer.fullScreen {
  width: 100%;
}

.wordBox {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 10px;
  gap: 10px;
  background: #F8FBFF;
  border-radius: 6px;

  &_text {
    white-space: nowrap;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
}

.button {
  background: none;
  padding: 0;
  display: flex;
  align-items: center;
}