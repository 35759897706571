.root_InformationBlock {
    &:not(:last-child) {
        margin-right: 16px;
    }
    border-radius: 6px;
    background-color: #ffffff;
    font-size: 16px;
    padding: 16px;
    min-width: 300px;
    height: fit-content;
}

.title {
    color: #0EBFA1;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 16px;
}

.content {
    position: relative;
    left: -6px;
    margin-bottom: 16px;
}
.importantDetail {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 8px;
    color: #000000;
}

.emptyInportant {
    color: #787E93;
    line-height: 20px;
}