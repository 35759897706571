.searchWordPhrase {
  &_root {
    display: flex;
  }
}

.phrase {
  &_container {
    display: flex;
    gap: 10px;
  }

  &_tag {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    height: 41px;
    background: #F8FBFF;
    border: 1px solid #F8FBFF;
    border-radius: 6px;
  }

  &_previewTag {
    @extend .phrase_tag;
    background: #DFFCF7;
    border: 1px solid #0EBFA1;
  }

  &_text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    color: #262626;
    white-space: nowrap;
  }
}

.btn {
  background: none;
  padding: 0;
}

.flexRow {
  display: flex;
  align-items: center;
}