.platformCell {
  display: flex;
  align-items: center;
  color: #3B3D47;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;

  &_label {
    margin-left: 10px;
  }
}