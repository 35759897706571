.overlay {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: stretch;

  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 999;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  background: rgba(29, 29, 27, 0.5);

  overflow-y: auto;
}

.modalWindow {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1016px;
  min-height: 1590px;
  margin: 50px 0;
  background: #FFFFFF;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  opacity: 1;
  border-radius: 6px;
  padding: 32px;
}

.modalWindow.retina {
  width: 724px;
  padding: 16px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}