.wrapper {
    display: flex;
    justify-content: center;
    width: 676px;
    min-height: 532px;
    position: relative;
    height: fit-content;
}

.messageBox {
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 6px;
    width: 315px;
    height: 148px;
    background: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    &_error {
        color: #F36869;
    }
}

.flexRow {
    display: flex;
    width: 100%;
}

@media screen and (max-width: 1200px) {
    .wrapper {
        min-width: 400px;
        width: 500px;
    }
}