.editDate {
  &_root {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &_text {
    color: #3B3D47;
  }
}