.dropdown {
    padding: 24px;
    background: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    display: flex;
    flex-flow: column nowrap;
    width: 300px;

    &Button {
        background: #0EBFA1;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        padding: 8.5px 20px;
        align-self: flex-end;
        margin-top: 16px;

        &:disabled {
            cursor: not-allowed;
        }
    }
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 0;
    background: none;

    span {
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 16px;
        color: #787E93;
    }

    div {
        width: 32px;
        height: 32px;
        background: #DFFCF7;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }
}