.titleDate {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  align-items: center;
  color: #787E93;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 2px;
}

.levelContainer {
  display: inline-flex;
}

.levelDescription {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
  margin-right: 4px;
}

.level {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
  margin-right: 4px;
}

.titleSource {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #787E93;
  margin-bottom: 4px;
}

.linkSource {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #129FD4;
  width: 150px;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}
.containerRating {
  display: flex;
  align-items: center;
}
.ratingIcon {
  display: flex;
  margin-right: 8px;
}
.ratingIndicator {
  width: 10px;
  height: 10px;
  background: gray;
  margin-right: 6px;
  border-radius: 100%;
}
.ratingLabelContainer {
  display: flex;
  align-items: center;
}
.ratingLabel {
  display: flex;
  align-items: center;
}
.containerSourceInform {
  display: flex;
  align-items: center;
  margin-top: 6px;
}
.sourceLabel {
  margin-right: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.sourceIcon {
  margin-right: 6px;
}
.sourceName {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #787E93;
}