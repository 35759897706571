.cardList {
    &_root {
        display: flex;
        position: relative;
        width: 100%;
        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }
    &_remover {
        cursor: pointer;
        fill: #008ADE;
    }
    position: relative;
    width: 100%;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.10);
    padding: 16px;
    color:#17191D;
    font-family: Montserrat;
}

.cardInfo {
    &_title {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 131.5%;
        margin-bottom: 8px;
    }
    &_counter {
        color: #787E93;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 133%;
        margin-bottom: 8px;
    }
    &_description {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }
}

.controllerHide {
    position: absolute;
    top: 16px;
    right: 16px;
}

.btnContainer {
    padding: 20px 0 0 12px;
}