.sourcesFilter {
  position: absolute;
  top: 55px;
  left: 20px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  color: #7C848B;
  width: 170px;
  height: 100px;
  padding: 12px;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  &_row {
    display: flex;
    align-items: center;
    color: black;
    margin-top: 10px;
  }

  &_img {
    margin-right: 8px;
    padding-bottom: 2px;
  }

  &_symbol {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
  }

  &_symbol.active {
    font-weight: 900;
    text-decoration-line: underline;
    color: #0EBFA1;
  }


  //&_symbol:hover {
  //  color: #0EBFA1;
  //}

  //&_symbolActive {
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  font-weight: 900;
  //  text-decoration-line: underline;
  //  margin: 0 5px;
  //  color: #0EBFA1;
  //}
}