.growthPeriod {
  display: flex;
  align-items: center;
  color: #3B3D47;
  &_label {
    width: 55px;
    margin-right: 24px;
  }
  &_value {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    user-select: none;
    cursor: pointer;
  }
}

.pstc {
  color: #0EBFA1;
}
.ngtv {
  color: #FF0000;
}