.wrapper {
    display: flex;
    width: 100%;
    height: 350px;
    position: relative;
    margin-bottom: 12px;
}

.arrowWrapper {
    position: absolute;
    width: 350px;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 99;
    padding: 0 10px;
    top: 0;
    bottom: 0;

    button {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        &:disabled,
        &[disabled] {
            display: none;
        }
    }

    &Left {
        left: 0;

        button svg {
            transform: rotate(180deg);
        }
    }

    &Right {
        right: 0;
        justify-content: flex-end;
    }
}
