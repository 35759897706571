.overlay {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: stretch;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 999;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    background: rgba(29, 29, 27, 0.5);
    overflow-y: auto;
}

.modalWindow {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 730px;
    height: 224px;
    margin: 160px 0;
    background: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    opacity: 1;
    border-radius: 6px;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 16px 32px 32px 32px;
}

.title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #787E93;
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 32px;
    padding: 0 32px;
}

.closeButton {
    background: none;
    padding: 0;
    height: 19px;
}

.contentText {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #262626;
    margin-bottom: 16px;
}

.emailContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 24px;
}

.inputField {
    min-width: 400px;
    height: 48px;
    border: none;
    outline: none;
    border-bottom: 1px solid #0EBFA1;
    background: none;
    padding: 14px 10px;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    color: #000000;
}

.inputField.invalid {
    border-bottom: 1px solid #F36869;
}