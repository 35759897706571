.modalAddSources {
    &_root {
        z-index: 100;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &_table {
        margin-top: 16px;
        padding: 0 12px 12px 0;
        overflow: auto;
        width: 100%;
        height: 100%;
        max-width: 1100px;
        max-height: 500px;
        margin-bottom: 16px
    };
    &_controls {
        display: flex;
        justify-content: flex-end;
    }
    position: relative;
    z-index: 101;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
}

.modalAddSources_overlay {
    position: absolute;
    content: ' ';
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
}

.closeButton {
    position: absolute;
    top: 16px;
    right: 16px;
    fill: #787E93;
    cursor: pointer;
}