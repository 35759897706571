.wrapper {
    position: relative;
}

.button {
    width: 250px;
    background: #ffffff;
    border: 1px solid rgba(222, 225, 231, 0.5);
    border-radius: 8px;
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;

    span {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
    }
}

.modal {
    position: absolute;
    left: 0;
    right: 0;
    top: 45px;
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    z-index: 999;
    padding: 12px;
	gap: 12px;
	display: flex;
	flex-flow: column nowrap;

    & > span {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #7c848b;
		display: flex;

		&:nth-child(3) {
			margin-top: 5px;
		}
    }
}
