.catigoriesCell {
  position: relative;
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &_item {
    max-width: 160px;
    word-break: normal;
    white-space: normal;
  }
  &_root {
    height: fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    max-height: 60px;
    transition: all 0.3s;
    overflow: hidden;
    &.close {
      position: relative;
      &::after {
        position: absolute;
        bottom: 0px;
        content: ' ';
        width: 100%;
        height: 15px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.76) 50.88%, rgba(255, 255, 255, 0.96) 69.18%, #FFFFFF 85.66%);
      }
    }
    &.open {
      max-height: 500px;
    }
  }
}

.controller {
  border-radius: 100%;
  height: 31px;
  width: 31px;
  fill: #787E93;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  padding-top: 2px;
  &.open {
      transform: rotate(180deg);
  }
  &:hover {
  }
}