.dropDownPlatform {
  width: 1072px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: 20px;

  &_lineStart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    margin: auto;
    background: #F8FBFF;;
  }

  &_name {
    display: flex;
    align-items: center;
    width: 220px;
    height: 100%;
    font-weight: 600;
    font-size: 16px;
    background: #E8F1F9;;
    color: gray;
    border-radius: 6px;
    padding: 10px;
  }

  &_arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    width: 3%;
    background: #E8F1F9;;
    color: gray;
    border-radius: 2px;
    padding: 13px 11px;
    cursor: pointer;
    
    div {
      height: 12px;
      display: flex;
      align-items: center;
    }
  }

  &_rowHeader {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #615E83;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 18px;
  }

  &_rowHeader > * {
    margin-left: 172px;
  }

  &_first {
    margin: 15px 0 20px 0;
    min-height: 25px;
    max-height: 25px;
  }

  &_list {
    display: flex;
    align-items: center;
  }

  &_cell {
    display: flex;
    align-items: center;
    height: 38px;
  }

  &_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    color: black;
    margin-left: 34px;
    margin-right: 60px;
  }

  &_info {
    margin-left: 30px;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
  }

  &_percent {
    margin-left: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }
}

@media screen and (max-width: 1200px) {
  .dropDownPlatform {
    width: 100%;
  }
}