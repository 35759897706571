.wrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
    padding-right: 32px;
}

.header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.tabs {
    display: flex;
    flex-flow: row nowrap;

    button {
        padding: 10px 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        font-size: 16px;
        color: #262626;

        &[data-active="true"] {
            background: #FFFFFF;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
            border-radius: 6px;
        }
    }
}