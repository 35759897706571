.label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 131.5%;
  color: #787E93;
}

.input {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 150px;
  padding: 0 12px 0 12px;
  border: 1px solid rgba(222, 225, 231, 0.5);
  border-radius: 6px;
}

.text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 131.5%;
  margin: 12px 0;
  min-width: 10px;
  max-width: 120px;
  overflow-x: hidden;
  white-space: nowrap;
  outline: none;
}

.percent {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 131.5%;
  padding: 0 5px;
}
.inputs {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  gap: 28px;

  input {
    width: fit-content;
    max-width: 120px;
    border: 1px solid rgba(222, 225, 231, 0.5);
    border-radius: 6px;
    height: 35px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }
}