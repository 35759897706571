.root_CollapseContainer {
    overflow: hidden;
    transition: all 0.4s;
    &.open {
        max-height: 900px !important;
    }
}

.overflowOverlay {
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.76) 50.88%, rgba(255, 255, 255, 0.96) 69.18%, #FFFFFF 85.66%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    width: 100%;
    height: 76px;
    opacity: 0;
    position: absolute;
    transition: all 0.3s ease;
    &.close {
        opacity: 1;
    }
    &.open {
        height: 2px !important;
    }
}

.controller {
    border-radius: 100%;
    height: 31px;
    width: 31px;
    background-color: #F8FBFF;
    position: absolute;
    bottom: 16px;
    z-index: 10;
    right: 0px;
    fill: #787E93;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    padding-top: 2px;
    &.open {
        transform: rotate(180deg);
    }
    &:hover {        
        background-color: #ecf1f9;
    }
}