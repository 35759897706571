.dropdown {
  background-color: white !important;
}

.selectItemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  gap: 8px;
  background-color: white;
  border: none;
  cursor: pointer;
  margin-left: 10px;

  &_text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #262626;
  }
}

.selectItemContainer.filled {
  background-color: #E8F1F9;
}

.regionsAmountText {
  display: flex;
  white-space: nowrap;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #262626;
}