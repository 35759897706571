.inputContainer {
  position: relative;
  min-width: 198px;
  width: calc(100% + 12px);
}

.showingContainer {
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
  padding: 0;
  background: none;
}

.authInput {
  border: none;
  font-size: 16px;
  outline: none;
  border-bottom: 1px solid #0EBFA1;
  color: #0EBFA1;
  background: none;
  padding-bottom: 16px;
  margin-bottom: 32px;
  width: 100%;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #0EBFA1;
    opacity: 1;
    z-index: 1
  }

  :-ms-input-placeholder {
    color: #0EBFA1;
    opacity: 1;
    z-index: 1
  }
}