.root_DocumentsList{
    display: flex;
    transition: 0.3s ease;
    max-height: 280px;
    overflow: auto;
}

.documentsContainer {
    display: flex;
    flex-wrap: wrap;
    max-width: 880px;
    gap: 16px
}

.addBtn {
    margin-top: 16px;
    margin-left: 16px;
    border-radius: 100%;
    border: 1px solid rgba(0,0,0,0);
    width: 31px;
    height: 31px;
    background-color: #EDFFFC;
    cursor: pointer;
    position: relative;
    fill: #0EBFA1;
    // &.loadFile {
    //     border: 1px solid #0EBFA1;
    // }
}

.empty {
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: 5px;
    justify-content: center;
}