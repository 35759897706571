.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.rowFlexContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.topContainer {
  display: flex;
  height: 80px;
  width: 100%;
  gap: 26px;

  &_closeCrossContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  &_photo {
    display: flex;
    align-items: center;
  }

  &_bloggerName {
    @extend .rowFlexContainer;
    justify-content: flex-start;
    word-break: break-word;
    min-width: 180px;
    max-width: 200px;
  }

  &_bloggerName.retina {
    min-width: 120px;
    max-width: 140px;
  }

  &_bloggerType {
    @extend .rowFlexContainer;
    justify-content: flex-start;
    width: 100px;
    gap: 12px;
  }

  &_bloggerContract {
    @extend .rowFlexContainer;
    width: 160px;
    min-width: 160px;
    white-space: nowrap;
  }

  &_bloggerActualDate {
    @extend .rowFlexContainer;
    min-width: 200px;
    height: 100%;
    gap: 8px;
    margin-left: 79px;
  }

  &_bloggerActualDate.retina {
    margin-left: 6px;
  }
}

.actualDateTextContainer {
  display: flex;
  align-items: flex-end;
  width: 170px;
  height: 100%;
  margin-top: -20px;
}

.topContainer.retina {
  height: 60px;
  gap: 6px;
}

.bottomContainer {
  display: flex;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 10px;
  gap: 55px;

  &_item {
    display: flex;
    flex-direction: column;
    width: 142px;
    height: 100%;
    gap: 20px;
  }

  &_item.retina {
    max-width: 110px;
    gap: 6px;
  }

  &_itemNumber {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 9px;
  }

  &_itemText {
    height: 44px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    color: #787E93;
  }

  &_itemText.retina {
    font-size: 14px;
    line-height: 140%;
  }

  &_itemNumberText {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  }

  &_itemNumberText.retina {
    font-size: 16px;
    line-height: 20px
  }
}

.bottomContainer.retina {
  gap: 20px;
}

.bloggerNameText {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #262626;
}

.bloggerNameText.retina {
  font-size: 16px;
}

.bloggerTypeText {
  @extend .bloggerNameText;
  font-size: 14px;
  color: #787E93;
}

.bloggerContractText {
  @extend .bloggerNameText;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #0EBFA1;
}

.bloggerActualDateText {
  @extend .bloggerNameText;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #787E93;
}

.bloggerActualDateText.retina {
  @extend .bloggerNameText;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #787E93;
}

.bloggerContractText.noContract {
  color: #F36869;
}

.horizontalLine {
  width: 100%;
  height: 1px;
  background: #D6DDE8;
  border-radius: 1px;
}

.loaderContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 216px;
  min-height: 216px;
}