.wrapper {
	display: flex;
	flex-flow: row nowrap;
	padding: 20px;
	gap: 30px;
}

.left,
.right {
	display: flex;
	flex-flow: column nowrap;
	gap: 20px;
}

.block {
	display: flex;
	width: 800px;
	gap: 20px;
}