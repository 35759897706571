.empty {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    display: flex;
    align-items: center;
    color: #262626;
  &__root {
    background: #FFFFFF;
    margin-top: 100px;
    margin-left: 18%;
    padding: 24px;
    max-width: 430px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
  &__link {
    position: relative;
    color: #0EBFA1;
    cursor: pointer;
    &::after {
        content: ' ';
        position: absolute;
        left: 0px;
        bottom: -2px;
        width: 100%;
        height: 1px;
        background-color: #0EBFA1;
    }
  }
}