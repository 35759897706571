.root {
  display: flex;
  flex-direction: column;
  min-width: 120px;
  max-height: 600px;
  padding: 16px;
  gap: 12px;
  background: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #787E93;
}

.sortRowContainer {
  display: flex;
  align-items: center;
  gap: 14px;
  cursor: pointer;
}

.text {
  @extend .title;
  color: #787E93;

  &_active {
    @extend .title;
    font-weight: 600;
    color: #262626;
  }
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}