.comparisonPeriodModal {
  &_root {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: stretch;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 999;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    background: rgba(29, 29, 27, 0.5);
    overflow-y: auto;
  }
}

.flexRow {
  display: flex;
  width: 100%;
  height: 100%;
}

.dropDownContainer {
  display: flex;
  align-items: center;
  gap: 16px;
}

.dropdownBorder {
  display: flex;
  align-items: center;
  padding: 0 16px;
  gap: 12px;
  height: 34px;
  border: 2px solid #D6DDE8;
  border-radius: 6px;

  &_text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    color: #787E93;
  }
}

.flexColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.zoomWrapper {
  padding: 15% 0 3% 0;
}

.controlsWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100px;
  padding-right: 24px;
  gap: 16px;
}

.modalCrossWrapper {
  display: flex;
  width: 60px;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 16px;
}

.scrollWrapper {
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  //padding: 0 24px;
  margin-top: 32px;
}

.modalWindow {
  display: flex;
  flex-direction: column;
  width: 940px;
  min-height: 682px;
  margin: 160px 0;
  padding: 26px;
  background: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  opacity: 1;
  border-radius: 6px;

  &_line {
    position: relative;
    border-right: 1px solid silver;
    height: 100%;
    margin-right: 16px;
    display: flex;
    align-items: center;
  }

  &_controllerBlock {
    position: relative;
  }

  &_btn {
    position: absolute;
    top: -50px;
    left: -20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: white;
    font-size: 40px;
    padding-bottom: 8px;
    color: #787E93;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
  }

  &_btn:hover {
    cursor: pointer;
  }

  &_btn_shift {
    top: 0;
  }

  &_controller {
    position: absolute;
    right: 100px;
    bottom: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &_selectCompany {
    font-size: 18px;
    color: #787E93;
    padding: 6px 15px;
    border-radius: 5px;
    border: 1px solid #787E93;
    margin-right: 20px;
  }

  &_btnCompare{
    font-size: 18px;
    color: #30D5C8;
    padding: 6px 15px;
    border: 1px solid #30D5C8;
    border-radius: 5px;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &_title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #787E93;
    width: 100%;
    border-bottom: 1px solid #787E93;
    padding-bottom: 15px;
  }

  &_titleIcon:hover {
    color: #D2D5DA;
    cursor: pointer;
  }

  &_warningBlock {
    margin-top: 20px;
    width: 740px;
    height: 88px;
    background: #FDF3E9;
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
  }

  &_warningText {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #E67E22;
    width: 85%;
    margin: 15px;
  }

  &_warningIcon {
    height: 15px;
    width: 15px;
    margin: 15px;;
  }

  &_warningIcon:hover {
    cursor: pointer;
  }

  &_referenceCompany {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 16px;
    margin-bottom: 50px;
  }

  &_spanReferenceCompany {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #E67E22;
  }

  &_companyBlock {
    gap: 24px;
    width: 449px;
    height: 40px;
    background: #FEF8E8;
    border: 1px solid rgba(230, 126, 34, 0.2);
    border-radius: 6px;
    font-size: 18px;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }

  &_spanDateCompany {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #787E93;
    margin: 0 0 2px 20px;
  }

  &_compareCompany {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;
  }

  &_company {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &_lineCompany {
    width: 95%;
    height: 4px;
    border-radius: 2px;
    background: #AFEEEE;
  }

  &_companyBlock {
    gap: 24px;
    width: 449px;
    height: 40px;
    background: #FEF8E8;
    border: 1px solid rgba(230, 126, 34, 0.2);
    border-radius: 6px;
    font-size: 18px;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }

  &_companyBlock_bgWhite {
    margin-top: 10px;
    background: white;
    border: 1px solid rgba(120, 126, 147, 0.3);
    justify-content: space-between;
  }

  &_companyInfo {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &_spanTitleCompany{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 45%;
  }
  &_spanDateCompany {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #787E93;
    margin: 0 0 2px 20px;
    width: 50%;
  }

  &_basketButton {
    padding: 0;
    background: none;
  }

  &_basket {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  &_basket:hover {
    cursor: pointer;
  }

  &_lineSwitching {
    width: 800px;
    margin-left: 6px;
    border-bottom: 1px solid silver;
    position: relative;
  }

  &_btnSwitching {
    position: absolute;
    top: -17px;
    left: 32.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    background: white;
    border-radius: 2px;
    width: 35px;
    height: 35px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
    padding-left: 8px;
    padding-top: 8px;
  }

  &_btnSwitching:hover {
    cursor: pointer;
  }

  &_btnSwitching_shift {
    left: 63.5%;
    padding-left: 12px;
  }
}