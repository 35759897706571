.rootControls {
    width: 100%;
    // background-color: rgba(0, 8, 255, 0.274);
    display: flex;
    align-items: center;
    justify-content: center;
}

.controlContainer {
    display: flex;
    align-items: center;
}

.container {
    &:not(:last-child) {
        margin-right: 32px;
    }
}

.control {
    user-select: none;
    width: 38px;
    height: 38px;
    border-radius: 100%;
    background-color: white;
    box-shadow:  0px 0px 15px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    fill: #787E93;
    color: #787E93;
    &.squery {
        border-radius: 6px;
    }
    transition: transform 0.3s ease;
    &:active {
        transform: scale(0.95);
    }
}

.minus {
    width: 22px;
    height: 3px;
    border-radius: 8px;
    background-color: #787E93;
}