.wrapper {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
}

.input {
    border: 1px solid rgba(222, 225, 231, 0.5);
    height: 52px;
    width: 100%;
    padding-left: 12px;
    border-radius: 6px;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #262626;

    &Wrapper {
        width: 100%;
        position: relative;
    }

    &Placeholder {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        color: #787e93;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        pointer-events: none;
    }

    &:hover {
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    }

    &:focus {
        border: 1px solid rgba(120, 126, 147, 0.8);
    }

    &:disabled {
        border: 1px solid rgba(120, 126, 147, 0.3);
        background: rgba(225, 228, 231, 0.3);
        color: rgba(120, 126, 147, 0.3);

        &:hover {
            box-shadow: none;
        }

        ~ .cross {
            display: none;
        }
    }

    &WithText {
        border: 1px solid rgba(120, 126, 147, 0.3);
    }

    &Warning {
        border: 1px solid var(--yellow-text, #e67e22);
        color: #262626;
    }

    &Error {
        border: 1px solid #f36869;
        color: #262626;
    }

    &SizeMedium {
        height: 40px;
        font-size: 14px;

        ~ .inputPlaceholder {
            font-size: 14px;
        }
    }

    &SizeSmall {
        height: 32px;
        font-size: 12px;

        ~ .inputPlaceholder {
            font-size: 12px;
        }
    }

    &Search {
        padding-right: 30px;
        display: none;
    }

    &:read-only {
        &:hover{
            box-shadow: none;
        }
        &:focus {
            border: 1px solid rgba(222, 225, 231, 0.5);
        }
    }
}

.textError,
.textWarning {
    color: #f36869;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 4px;
    margin-bottom: 0;
}

.textWarning {
    color: #e67e22;
}

.header {
    display: flex;
	margin-bottom: 8px;
}

.label {
    color: #787e93;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
	margin-bottom: 0;

    &::first-letter {
        text-transform: uppercase;
    }
}

.limit {
    margin-left: auto;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
	color: #787E93;
	margin-bottom: 0;

	&Typing {
		color: #262626;
	}

	&Max {
		color: #F36869;
	}
}

.cross {
	position: absolute;
	width: 40px;
	right: 1px;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 1px;
	bottom: 1px;
    background: #fff;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.iconSearch {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.additionInfo {
    :not(:last-child) {
        margin-bottom: 8px;
    }
}