.container {
    display: flex;
    flex-flow: column nowrap;
    gap: 6px;
}

.title {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #000000;   
    margin-bottom: 0;
}

.list {
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;
    position: relative;
    max-height: none;

    a {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        color: #129FD4;
    }

    &Hidden {
        overflow: hidden;
        max-height: 86px;
    }
}

.listMore {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.94) 94.8%);
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}