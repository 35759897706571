.container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: stretch;
  isolation: isolate;
  background: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.text {
  height: 28px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  margin: 6px 0;
  padding: 0 16px;
  display: flex;
  align-items: center;

  &:hover{
    background-color: #f5f5f5;
  }

  color: #262626;
  cursor: pointer;
}

.text.retina {
  margin: 3px 0;
  padding: 0 8px;
  font-size: 14px;
  line-height: 140%;

}