.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin-top: 24px;
  gap: 16px;
  list-style-type: none;
  border: none;
}

.linkWrapper {
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 16px;
  min-width: 80px;
  gap: 16px;
  cursor: pointer;

  &:hover {
    background: #DFFCF7;
  }
}

.linkTitle {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #1D1D1B;
}

.linkTitle.active {
  color: #0EBFA1;
}

.subLink {
  display: flex;
  flex-direction: column;
  padding-left: 54px;
  margin-top: 6px;
  gap: 12px;

  &_item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #262626;
    cursor: pointer;
    background: none;
    padding: 0;
    & > div {
      display: flex;
      align-items: center;
    }
    &:hover {
      color: #0EBFA1;
    }
  }

  &_active {
    color: #0EBFA1;
  }
}

.campaignMenuItems {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-left: -50px;
  padding: 8px;
  width: 220px;
  border: 1px solid rgba(14, 191, 161, 0.5);
  border-radius: 4px;

  &_campaignName {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    white-space: nowrap;
    text-align: center;
    line-height: 135%;
    height: 100%;
    background: white;
    border: none;
    border-radius: 4px 0 0 4px;
  }

  &_campaignName::placeholder {
    color: black
  }

  &_campaignName:focus {
    outline: none;
    text-align: start;
  }

  &_campaignName:focus::placeholder {
    outline: none;
    text-align: start;
    color: #787E93;
  }

  &_list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: absolute;
    top: 40px;
    left: 0;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 200px;
    height: 100px;
    white-space: nowrap;
    background: #FFFFFF;
    box-shadow: 2px 3px 8px 1px silver;
    border-radius: 6px;
  }

  &_list::-webkit-scrollbar {
    display: none;
  }

  &_campaign {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    color: #787E93;
    padding: 5px;
    cursor: pointer;
  }

  &_campaign:hover {
    background: #E8F1F9;
  }
}

.subLinkCounter {
  border-radius: 30px;
  background: rgba(228, 228, 228, 0.50);
  margin-left: 2px;
  padding: 2px 6.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #787E93;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

@media screen and (max-width: 1200px) {
  .icon {
    min-width: 24px;
  }
}