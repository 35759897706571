.checkboxesDemo_root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding: 32px;
  overflow: hidden;
  background-color: #f3f3f3;
}

.checkboxesDemo {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  span {
    margin-left: 8px;
  }
}