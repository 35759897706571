.campaignDnDTonality {
  &_root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 6px;
    height: 132px;
    width: 290px;
    background: white;
    border-radius: 6px;
  }
}

.tonality {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  font-family: 'Montserrat', sans-serif;

  &_title {
    width: 92px;
    height: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    color: #7C848B;
  }

  &_row {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &_name {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    width: 100px;
  }

  &_chart {
    display: flex;
    min-width: 100px;
    width: 100px;
  }

  &_bar {
    display: flex;
    align-items: flex-start;
    height: 20px;
    border-radius: 6px;
  }

  &_quantity {
    width: 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #615E83;
  }
}