.containerModal {
    background-color: #F2F9FF;
    padding: 0;
    margin: 0;
    margin-left: -20px;
    margin-right: -20px;
    padding: 8px;
    border-radius: 6px;
    color:#787E93;
    font-size: 14px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    span {
        color:#262626;
        font-size: 14px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
}