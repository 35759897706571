.rootInitalChart {
  width: 100%;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border-radius: 6px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  padding-top: 12px;
  padding-bottom: 12px;
  justify-content: center;
  cursor: pointer;
  span {
    margin-right: 12px;
    margin-bottom: 4px;
  }
  div {
  }
}