.btnRemove {
    cursor: pointer;
    fill: #787E93;
    transition: all 0.3s ease;
    &:hover {
        fill: tomato
    }
}

.table {
    width: auto;
}

.projectList {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 32px;
    overflow: hidden;
};

.filter {
    &_status {
        margin-top: 12px;
    }
}
.filterStatus {
    &_list {
        margin-top: 12px;
    }
    &_item {
        display: flex;
        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }
}