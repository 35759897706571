.mainContainer {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: flex-start;
    overflow: hidden;
    width: 100%;
}

.contentContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #F8FBFF;
    overflow-x: hidden;
}

.wrapper.normal {
    width: 100px;
}

.wrapper.expanded {
    min-width: 245px;
}

.siderContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: stretch;
    padding: 0 0 0 8px;
    height: 100%;
    min-width: 234px;
    //background: linear-gradient(180deg, #FFFFFF 20.0%, #FFFFFF 80.0%, #0EBFA1 100%);
    background: white;
}

.link {
    display: flex;
    margin-left: -20px;
    justify-content: center;
    width: 100%;
    line-height: 1;
    transition: transform 0.2s;

    &:hover {
        transform: scale(1.15);
    }
}

.arrowsContainer {
    display: flex;
    width: 100%;
    padding: 10px;
    margin-top: 24px;

    &_arrows {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        padding: 10px;
        gap: 10px;
        cursor: pointer;

        &:hover {
            background-color: #DFFCF7;
            border-radius: 6px;
        }
    }

    &_arrows.normal {
        transform: rotate(0deg);
    }

    &_arrows.expanded {
        transform: rotate(180deg);
        margin-right: 0;
    }
}

.logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 62px;
    margin-left: 16px;
    gap: 12px;
    background: none;
    padding: 0;

    &_label {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #262626;
    }
}

.chevron {
    display: none;
}

@media screen and (max-width: 1200px) {
    .logoContainer {
        margin-top: 16px;
        display: flex;
        justify-content: center;
        margin-left: 0;
        
        .link {
            margin-left: 0;
        }
    }

    .chevron {
        background: none;
        padding: 0;
        margin: 0 auto;
        margin-top: 62px;
        margin-bottom: 16px;
        display: flex;
        transition: rotate 0.2s;
    }

    .chevron.expanded {
        transform: rotate(180deg);
        transition: rotate 0.2s;
    }

    .mainContainer {
        padding-left: 64px;
    }

    .siderContainer {
        position: absolute;
        left: 0;
        z-index: 99;
        overflow: hidden;
        padding-right: 0;
    }

    .siderContainer.normal {
        min-width: 62px;
        width: 62px;
        transition: width 0.5s;
    }

    .siderContainer.expanded {
        width: 245px;
        transition: width 0.2s;
    }
}