.button {
  display: flex;
  gap: 8px;
  align-items: center;
  flex-flow: row nowrap;
  padding: 0;
  margin: 0;
  background: none;

  div {
    width: 20px;
    height: 20px;
    border: 1px solid #0EBFA1;
    background: #fff;
    border-radius: 50%;
    position: relative;
  }

  span {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #1D1D1B;
  }
  &.disabled {
    div {
      border-color: #dfdfdf;
    }
    span {
      color: #787E93
    }
  }
}

.active {
  div {
    &::after {
      content: "";
      width: 10px;
      height: 10px;
      background: #0EBFA1;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.disabled {
  div {
    &::after {
      background: #dfdfdf;
    }
  }
}