.searchLogicOperatorContextMenu {
  &_root {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1001;
  }
}

.modalContextMenu {
  display: flex;
  flex-direction: column;
  min-width: 100px;
  max-width: 460px;
  max-height: 345px;
  padding: 16px;
  background: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  opacity: 1;
  border-radius: 12px;
}

.list {
  &_container {
    display: flex;
    flex-direction: column;
  }

  &_item {
    display: flex;
    cursor: pointer;
    padding: 0 4px;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  &_text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #787E93;
  }
}