.root {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.logoContainer {
  width: 100%;
  min-height: 150px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 50px;
  background-color: transparent;
}

.footerContainer {
  position: relative;
  width: 100%;
  min-height: 217px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 50px;
  background-color: white;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 42px 0;
  background-color: #DFFCF7;
}

.formContainer.finish{
  padding: 252px 0 233px 0;
}

.backLinkContainer{
  display: flex;
  width: 574px;
  margin-bottom: 12px;

  &_link {
    display: flex;
    width: 80px;
    gap: 12px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #787E93;

    cursor: pointer;
  }
}

.barsContainer {
  position: absolute;
  bottom: -238px;
  right: 0;
}

.chartContainer {
  position: absolute;
  bottom: -238px;
  right: 170px;
}