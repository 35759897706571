.tagCloudContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  max-width: 300px;
  max-height: 150px;
}
.tag {
  transform: scale(1);
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    transform: scale(1.2);
    transition: all 0.3s;
  }
}