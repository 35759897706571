.chartContainer {
    width: 100%;
    height: 100%;
    height: 240px;
    position: relative;
}
.defaultContainerTooltip {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0px 0px 15px rgba(14, 191, 161, 0.25);
}

.fullScreen {
    width: 100%;
    height: 100%;
    position: relative;

}