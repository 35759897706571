.campaignDropDownCard {
  &_root {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    width: 290px;
    height: 98px;
    background: white;
    border-radius: 6px;
  }
}

.title {
  width: 75px;
  height: 20px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  color: #7C848B;
}

.dropdownBorder {
  display: flex;
  align-items: center;
  padding: 0 16px;
  gap: 12px;
  height: 34px;
  border: 2px solid #D6DDE8;
  border-radius: 6px;

  &_text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    color: #787E93;
  }
}