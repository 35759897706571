.stageMessage {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  width: 260px;
  padding: 16px;
  background: #FFFFFF;
  border-radius: 8px;
  z-index: 300;

  &_btnCross {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 9px;
    height: 9px;
    cursor: pointer;
  }

  &_img {
    width: 9px;
    height: 9px;
  }

  &_message {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 133%;
    color: black;
  }

  &_link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    color: #0EBFA1;
    cursor: pointer;
  }
}