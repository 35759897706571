.wrapper {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(29, 29, 27, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    display: none;
    backdrop-filter: blur(5px);
}

.block {
    background: #fff;
    padding: 24px;
    background: #FFFFFF;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    p {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-weight: 500;
        color: #000;
        line-height: 28px;
        max-width: 270px;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 1100px) {
    .wrapper {
        display: flex;
    }
}