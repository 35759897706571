.containerFilterCheckBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  &.disabledField {
    color: rgba(120,126,147,1)
  }
}

.checkboxWithContent {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  width: 100%;
}

.labelCheckBox {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  margin-left: 12px;
  max-width: 136px;
  overflow: hidden;
}

.checkboxCounter {
  color:rgba(120, 126, 147, 0.7);
  font-size: 12px;
  margin-left: 8px;
}


.labelAdditionLink {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #0EBFA1;
  user-select: none;
  cursor: pointer;
}


.disabled {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0);
}