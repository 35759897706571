.campaignDnDPosts {
  &_root {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    gap: 16px;
    width: 340px;
    height: 667px;
    background: white;
    border-radius: 6px;
  }
}

.title {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  color: #7C848B;
}

.messages {
  font-family: 'Montserrat', sans-serif;
  width: 350px;
  background: #FFFFFF;
  border-radius: 6px;
  padding: 16px;

  &_title {
    display: flex;
    align-items: center;
    gap: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #7C848B;
  }
}

.post {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
  font-family: 'Montserrat', sans-serif;
  width: 300px;

  &_title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: black;
    margin-bottom: 16px;
    display: flex;
    gap: 10px;
  }

  &_text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }

  &_lineIcon {
    display: flex;
    gap: 15px;
  }

  &_iconStatistic {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &_count {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: black;
  }

  &_date {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    color: #7C848B;
  }
}