.editText {
  &_root {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &_text {
    color: #3B3D47;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
}

.modal {
  &_root {
    position: absolute;
    top: 100px;
    right: 20px;
    padding: 12px;
    width: 535px;
    // height: 330px;
    background: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.10);
    z-index: 99;
  }

  &_form {
    display: flex;
    flex-direction: column;
    font-family: Montserrat, sans-serif;
  }

  &_link {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    gap: 4px;
  }

  &_rowLink {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 500px;
    height: 52px;
    border-radius: 6px;
    border: 1px solid rgba(222, 225, 231, 0.50)
  }

  &_text {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    gap: 4px;
  }

  &_label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
  }

  &_input {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #262626;
    height: 50px;
    width: 450px;
    border: none;
    border-radius: 6px;
  }


  &_reset {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 100%;
    cursor: pointer;
  }

  &_file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    width: 500px;
    height: 36px;
    border-radius: 6px;
    border: 1px solid rgba(222, 225, 231, 0.50)
  }

  &_nameFile {
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
    color: black;
    width: 450px;
    height: 34px;
    border: none;
    border-radius: 6px;
  }

  &_nameFile::placeholder {
    color: black;
    opacity: 1;
  }

  &_controller {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    width: 500px;
  }

  &_reattach {

  }

  &_approval {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #787E93;
    margin-left: auto;
  }

  &_close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    object-fit: contain;
    cursor: pointer;
  }

  &_complete {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    object-fit: contain;
    cursor: pointer;
  }
}