.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 574px;
  height: 360px;
  gap: 32px;
  background: white;
  border-radius: 6px;
}

.completeButton{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  width: 56px;
  height: 37px;
  background: #0EBFA1;
  border-radius: 6px;
  cursor: pointer;

  &_text{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    text-align: right;
    color: #FFFFFF;
  }

  &_text.disabled {
    color: #787E93;
  }

  &:hover {
    background: linear-gradient(96.57deg, #00B394 2.34%, #43D2BA 51.04%, rgba(161, 240, 227, 0) 98.73%), #0EBFA1;
  }

  &:active {
    background: #21A08C;
  }
}

.completeButton.disabled {
  background: #E1E4E7;
  cursor: default;

  &:hover {
    background: #E1E4E7;
  }

  &:active {
    background: #E1E4E7;
  }
}

.successMsg{
  display: flex;
  justify-content: center;
  width: 400px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #0EBFA1;
  text-align: center;
}

.infoMsg{
  @extend .successMsg;
  color: #129FD4;
}

.errorMsg{
  @extend .successMsg;
  color: #F36869;
}