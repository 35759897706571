.layout {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.maskInputsContent {
  font-size: 16px;
  color: rgba(0, 0, 0, 0)
}

.logoContainer {
  max-width: 638px;
  width: 70%;
  display: flex;
}

.labelBack {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  color: #0EBFA1;
  margin-left: -12px;
  cursor: pointer;
  user-select: none;
  margin-bottom: 24px;
}

.labelLeft {
  margin-left: 8px
}

.centeredMarkaup {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.startedMarkup {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.authBlock {
  width: 100%;
  height: 100%;
  max-height: 314px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}


.underlineText {
  cursor: pointer;
}

.underline {
  height: 1px;
  width: 100%;
  background: #0EBFA1;
}

.authContainer {
  width: min-content;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.authButton {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: #0EBFA1;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 131.5%;
  color: #FFFFFF;
}

.createAccountInfo {
  font-family: 'Montserrat', sans-serif;
  margin-top: 32px;
  display: flex;
  gap: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #0EBFA1;
}

.barsContainer {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 20%;
}

.chartContainer {
  z-index: -1;
  position: absolute;
  bottom: 0;
  right: 170px;
}

.exception {
  border-radius: 6px;
  padding: 16px;
  color: #F36869;
  font-weight: 500;
  font-size: 16px;
  background: #fdebec;
  margin-bottom: 16px;
}

.forgetPassword {
  display: inline-flex;
  padding: 16px;
  color: #E67E22;
  font-weight: 500;
  font-size: 16px;
  background: #fef8e8;
  cursor: pointer;
}

.informationForgot {
  display: flex;
  flex-direction: column;
  padding: 16px;
  color: #E67E22;
  font-size: 16px;
  background: #fef8e8;
  border-radius: 6px
}

.exceptionBlockInfromation {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.exceptionBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
}

.containerLoader {
  position: fixed;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 1;
  background: rgba(255, 255, 255, 0.05)
}