.sourceSearchField {
  &_root {
    display: flex;
    max-height: 530px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    color: #787E93;
    margin-bottom: 16px;
  }
}

.searchField {
  display: flex;
  align-items: center;
  width: 530px;
  max-width: 530px;
  height: 50px;
  margin-right: 24px;
  background: #FFFFFF;
  border-radius: 6px;
  border: 1px solid rgba(120, 126, 147, 0.30);

  &_focus {
    border: 1px solid #129FD4;
  }
}

.imgSearch {
  display: flex;
  margin: 0 0 0 24px;
  cursor: pointer;
}

.input {
  width: 440px;
  outline: 0;
  border: none;
  padding: 0 16px;
  color: #262626;
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 131.5%;
}

.imgCross {
  display: flex;
  margin-left: 16px;
  margin-right: 24px;
  cursor: pointer;
}