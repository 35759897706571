.chartContainer {
    width: 100%;
    height: 100%;
    height: 240px;
    position: relative;
}

.newChartDefaultContainer {
    width: 100%;
    height: 100%; 
}

.PIE_CHART {
    width: 380px;
    height: 380px;
}
.btnResetZoom {
    margin-left: 12px;
    margin-top: 8px;
    border-radius: 4px;
    border: 1px solid #0EBFA1;
    color: #0EBFA1;
    padding: 8px 12px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    width: fit-content;
    position: relative;
  }

.fullScreenChart {
    max-width: none;
    max-height: none;
    background-color: #fff;
    z-index: 9999;
}

.smallScreen {
    max-height: 360px;
    min-height: 200px;
    width: 100%;
}

.rootControls {
    width: 100%;
    // background-color: rgba(0, 8, 255, 0.274);
    display: flex;
    align-items: center;
    justify-content: center;
}

.controlContainer {
    display: flex;
    align-items: center;
}

.container {
    &:not(:last-child) {
        margin-right: 32px;
    }
}

.control {
    user-select: none;
    width: 38px;
    height: 38px;
    border-radius: 100%;
    background-color: white;
    box-shadow:  0px 0px 15px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    fill: #787E93;
    color: #787E93;
    &.squery {
        border-radius: 6px;
    }
    transition: transform 0.3s ease;
    &:active {
        transform: scale(0.95);
    }
}

.minus {
    width: 22px;
    height: 3px;
    border-radius: 8px;
    background-color: #787E93;
}