.wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 8px;
}

.header {
    padding-left: 12px;
}

.addUserButton {
    background: none;
    padding: 0;
    display: flex;
    gap: 12px;
    align-items: center;

    span {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        color: #787E93;
    }
    
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #DFFCF7;
        border-radius: 50%;
        width: 32px;
        height: 32px;
    }
}