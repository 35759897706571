.root {
  width: 574px;
  padding: 32px 87px;
  background: white;
  border-radius: 6px;
  z-index: 10;
}

.textareaField {
  width: 400px;
  height: 107px;
  padding: 12px;
  resize: none;
  outline: none;
  margin-top: 17px;
  margin-bottom: 32px;
  border: 1px solid #0EBFA1;
  border-radius: 6px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 131.5%;
  color: #000000;
}

.title {
  margin-bottom: 32px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #0EBFA1;
}

.labelText {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 131.5%;
  display: flex;
  align-items: flex-end;
  color: #0EBFA1;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  &_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    width: 155px;
    height: 37px;
    background: #0EBFA1;
    border-radius: 6px;

    cursor: pointer;

    &_text {
      width: 91px;
      height: 21px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 131.5%;
      text-align: right;
      color: white;
    }

    &_text.disabled {
      color: #787E93;
    }

    &:hover {
      background: linear-gradient(96.57deg, #00B394 2.34%, #43D2BA 51.04%, rgba(161, 240, 227, 0) 98.73%), #0EBFA1;
    }

    &:active {
      background: #21A08C;
    }
  }

  &_button.disabled {
    background: #E1E4E7;
    cursor: default;

    &:hover {
      background: #E1E4E7;
    }

    &:active {
      background: #E1E4E7;
    }
  }
}