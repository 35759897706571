.row {
    &Channel {
        display: flex;
        align-items: center;
        gap: 8px;
    
        p {
            font-family: "Montserrat", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #129fd4;
            margin: 0;
        }
    }
}

.subscribeBtn {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #0EBFA1;
    line-height: 131.5%;
    background: none;
    padding: 0;
}

.linkChatBot {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: rgb(120, 126, 147);
    transition: color 0.3s;
    &:hover {
        color: #40a9ff;
    }
}