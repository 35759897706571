.settingsPage {
  &_root {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-left: 32px;
    padding-right: 8px;
    padding-bottom: 24px;
    overflow: auto;
  }
}