.root_ModalInfo {
    padding: 32px;
    padding-bottom: 12px;
    position: relative;
    width: 100%;
    max-width: 760px;
    min-width: 540px;
    max-height: 750px;
    overflow: scroll;
    border-radius: 6px;
    background-color: #fff;
    z-index: 1001;
}