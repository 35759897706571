.root {
  display: flex;
  flex-direction: column;
  max-height: 500px;
  max-width: 250px;
  padding: 10px;
  gap: 4px;
  background-color: white;
  overflow-y: hidden;
  box-shadow: 0 0 15px rgb(0 0 0 / 10%);
  border-radius: 6px;
}

.wrapper  {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #787E93;
}

.item {
  cursor: pointer;
  padding: 6px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #262626;

  &:hover{
    cursor: pointer;
    background: rgb(245, 245, 245);
  }
}