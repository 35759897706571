.root {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background-color: white;
}

.minWidth {
  width: 365px;
}

.maxWidth {
  width: 1000px;
}

.label {
  margin-right: 26px;
  white-space: nowrap;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #787E93;
}

.dropdownContainer {
  margin-right: 10px;
}

.headerContainer {
  display: flex;
  align-items: center;
  min-height: 52px;
  width: 420px;
  padding: 0 16px;
}

.listContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 16px;
}

.categoryBox {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 10px;
  gap: 10px;
  background: #F8FBFF;
  border-radius: 6px;

  &_text {
    white-space: nowrap;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
}

.buttonCross {
  cursor: pointer;
}