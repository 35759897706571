.container {
    display: flex;
    flex-flow: wrap row;
    gap: 12px;
}

.item {
    padding: 8px 10px;
    background: #F8FBFF;
    border-radius: 6px;
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    align-items: center;

    p {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #000000;
        margin-bottom: 0;
    }

    button {
        border: none;
        background: none;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.emptyText {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: #000000;
}