.campaignMenuTabs {
  &_root {
    width: 100%;
    display: flex;
    align-items: center;
  }
}

.leftControls {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}

.rightControls {
  display: flex;
  align-items: center;
  gap: 20px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 131.5%;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-left: -8px;
  border-radius: 16px;
  background-color: #DFFCF7;
  padding-bottom: 8px;
  padding-right: 8px;
}

.item {
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 131.5%;
  color: black;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: black;
  }
}

.item.active {
  color: #0EBFA1;
  border-bottom: 2px solid #0EBFA1;
}

.linkButton {
  background: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  
  svg {
    margin-top: 4px;
  }
}

.controlButton {
  background: none;
  padding: 0;
}

.switchControl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 110px;
  gap: 7px;
}

.usersBox {
  display: flex;
  width: 370px;
  height: 40px;
  gap: 16px;
  padding: 10px;
  background: white;
  border-radius: 6px;
  
  &Label {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    color: #787E93;
    font-size: 14px;
    line-height: 140%;
  }
}

.dropdown {
  background-color: transparent !important;
}

.selectItemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  gap: 16px;
  background-color: white;
  border: none;
  cursor: pointer;

  &_text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    color: #262626;
  }
}

.selectItemContainer.filled {
  background-color: #E8F1F9;
}

.dropdownTitleText {
  display: flex;
  white-space: nowrap;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 131.5%;
  color: #262626;

  border-bottom: 2px solid transparent;
  cursor: pointer;
  text-decoration: none;
}

.dropdownTitleText.active {
  color: #0EBFA1;
  border-bottom: 2px solid #0EBFA1;
}

.hint {
  &_root {
    width: 800px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_leftBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_textBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 460px;
    height: 44px;
    background: #DFFCF7;
    border-radius: 7px;
  }

  &_text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #787E93;
  }

  &_textButton {
    margin-left: 10px;
    width: 64px;
    height: 20px;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #0EBFA1;
    background: none;
  }
}

.comparisonTab {
  display: flex;
  align-items: center;
  gap: 10px;

  &_text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    color: #787E93;
  }
}

.tabsText {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 131.5%;
  color: #787E93;
}