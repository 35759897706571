.campaignSearchGroup {
  &_container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 8px;
  }

  &_top {
    display: flex;
    justify-content: space-between;
  }

  &_title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    color: #787E93;
  }

  &_content {
    display: flex;
    padding: 10px;
    gap: 10px;
    min-width: 100px;
    height: 61px;
    border: 1px solid #D6DDE8;
    border-radius: 6px;
  }
}

.initial {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &_title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    color: #787E93;
  }

  &_box {
    display: flex;
    align-items: center;
    min-width: 300px;
    height: 60px;
    padding: 10px 20px;
    gap: 10px;
    border: 1px solid #D6DDE8;
    border-radius: 6px;
  }

  &_text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    color: #0EBFA1;
  }
}

.btn {
  background: none;
  padding: 0;
}

.flexRow {
  display: flex;
  align-items: center;
}