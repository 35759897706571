.dropDown {
  width: 1072px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: 20px;

  &_lineStart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    margin: auto;
    background: #F8FBFF;;
  }

  &_name {
    display: flex;
    align-items: center;
    width: 220px;
    font-weight: 600;
    font-size: 16px;
    background: #E8F1F9;;
    color: gray;
    border-radius: 6px;
    padding: 10px;
  }

  &_arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    font-size: 16px;
    width: 3%;
    background: #E8F1F9;;
    color: gray;
    border-radius: 2px;
    padding: 13px 11px;
    cursor: pointer;
  
    div {
      height: 12px;
      display: flex;
      align-items: center;
    }
  }

  &_list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &_cell {
    display: flex;
    align-items: center;
    height: 38px;
    transition: height 2s;
  }

  &_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    color: black;
    margin-left: 34px;
  }

  &_schedule {
    margin-left: 65px;
  }

  &_line {
    background: #D0ECF6;
    height: 20px;
    border-radius: 2px;
  }

  &_info {
    margin-left: 44px;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    width: 150px;
    min-width: 50px;
  }

  &_percent {
    margin-left: 52px;
    font-weight: 600;
    font-size: 16px;
  }
}

@media screen and (max-width: 1200px) {
  .dropDown {
    width: 100%;
  }
}