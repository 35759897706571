
.inputAddWord {
  border: none;
  outline: none;
  width: 100%;
  font-size: 14px;
  border-bottom: 1px solid #DEE1E7;
  padding: 4px 4px;
  transition: all 0.3s ease;
  &:focus-visible {
    padding: 4px 24px;
    border-bottom: 1px solid #0EBFA1;
  }
}

.addWordContainer {
  position: relative;
  width: 100%;
  padding-right: 16px;
  svg {
    z-index: 10;
    transition: all 0.3s ease;
    cursor: pointer;
    display: none;
  }
}

.removeWord {
  position: absolute;
  left: 4px;
  top: 6px;
  width: 16px;
  height: 16px;
  &.show {
    svg {
      transition: all 0.3s ease;
      cursor: pointer;
      display: block;
    }
}
}
.addWord {
  position: absolute;
  right: 20px;
  top: 5px;
  width: 16px;
  height: 16px;
  &.show {
    svg {
      transition: all 0.3s ease;
      cursor: pointer;
      display: block;
    }
}
}

