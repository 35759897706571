.campaignReportsFilters {
  &_root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 16px;
    width: 676px;
    min-height: 532px;
    background: white;
    border-radius: 6px;
  }
}

.header {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #0EBFA1;
}

.headerHl {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #D6DDE8;
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #262626;

  margin-bottom: 10px;
}

.flexRow {
  display: flex;
  align-items: center;
  width: 100%;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: #dfdfdf;
  border-radius: 12px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: white;

  &.active {
    background: #0EBFA1;
  }
}

.label {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #787E93;
}

.inputContainer {
  display: flex;
  padding: 0;
  gap: 8px;
  width: 400px;
  background: white;
  border-bottom: 1px solid #D6DDE8;
}

.input {
  width: 400px;
  height: 27px;
  background: white;
  border: none;
  outline: none;

  &_text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #262626;
  }
}

.gap20{
  gap: 20px;
}

.gap16{
  gap: 16px;
}

.gap8{
  gap: 8px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.plusCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: #E1E4E7;
  border-radius: 16px;
  cursor: pointer;
}

.buttonControl {
  padding: 0;
}

.dropdownBorder {
  display: flex;
  align-items: center;
  // padding: 0 16px;
  gap: 12px;
  height: 40px;
  // border: 1px solid #D6DDE8;
  // border-radius: 6px;
}

.campaignReportsFiltersSkeleton {
  &_root {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    gap: 24px;
  }
  &_row {
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    gap: 24px;
  }
  &_column {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }
}

.radioButton {
    align-items: flex-start;
}