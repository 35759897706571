.sourcesForProject {
  &_root {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: stretch;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 999;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    background: rgba(29, 29, 27, 0.5);
    overflow-y: auto;
  }

  &_window {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    margin-top: 5%;
    width: 1480px;
    min-height: 330px;
    height: fit-content;
    background: #FFFFFF;
    border-radius: 6px;
    justify-content: space-between;
  }

  &_container {
    display: flex;
    flex-direction: column;
    margin: 32px;
    gap: 24px;
    height: 800px;
    max-height: 800px;
  }

  &_loadingBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    height: 214px;
  }

  &_top {
    display: flex;
    justify-content: flex-end;
  }
  &_bottomControls {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}
