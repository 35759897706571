@function property-value($property, $selector: null) {
    @return $parametr;
}
.root {
    display: flex;
    height: 100px;
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
}
.descriptionContainer {
    display: flex;
    align-items: center;
    margin-right: 4px;
}
.smallCategory {
    height: 60px;
    transition: 0.2s ease;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: black;
    font-weight: 600;
    text-overflow: hidden;
    user-select: none;
    line-height: 32px;
    flex-grow: 1;
    overflow: hidden;
    .description {
        display: none;
        margin-right: 4px;
    }
}
.withChangeSize {
    &:hover {
        transition: 0.25s ease;
        width: 400px !important;
        opacity: 0.9;
        line-height: 32px;
        padding: 24px;
    }
}
.bigCategory {
    height: 60px;
    transition: 0.3s ease;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 24px;
    color: black;
    font-weight: 600;
    line-height: 32px;
    padding: 24px;
    overflow: hidden;
    user-select: none;
}
.category {
    height: 60px;
    transition: 0.3s ease;
    position: relative;
    display: flex;
    text-overflow: ellipsis;
    align-items: center;
    font-size: 24px;
    flex-grow: 1;
    user-select: none;
    color: black;
    font-weight: 600;
    line-height: 32px;
    padding: 24px;
    overflow: hidden;
}
.description {
    font-size: 14px;
    position: absolute;
    right: 32px;
    top: 8px;
}
.name {
    font-weight: 400;
    white-space: nowrap;
}