.root {
    display: flex;
    flex-direction: column;
    min-width: 230px;
    gap: 4px;
    background-color: white;
    overflow-y: hidden;
    box-shadow: 0 0 15px rgb(0 0 0 / 10%);
    border-radius: 6px;
}

.wrapper  {
    display: flex;
    flex-direction: column;
    padding: 0 10px 10px 10px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.item {
    cursor: pointer;
    padding: 6px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #262626;

&:hover{
     cursor: pointer;
     background: rgb(245, 245, 245);
 }
}

.addUserBox {
    display: flex;
    width: 100%;
    height: 30px;
    margin-top: 5px;
    padding-left: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
    background: #DFFCF7;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #787E93;
}