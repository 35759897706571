.addSource {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: stretch;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 999;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  background: rgba(29, 29, 27, 0.5);
  overflow-y: auto;

  &_window {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    margin-top: 10%;
    width: 540px;
    min-height: 330px;
    background: #FFFFFF;
    border-radius: 6px;
    justify-content: space-between;
  }

  &_container {
    display: flex;
    flex-direction: column;
    margin: 32px;
    gap: 24px;
  }

  &_titlePlatform {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_socialIconList {
    display: flex;
    gap: 20px;
  }

  &_inputSource {
    color: #787E93;
    border: none;
    outline: none;
  }

  &_category {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    color: #787E93;
  }

  &_categoryPanel {
    display: flex;
    align-items: center;
    gap: 13px
  }

  &_btnArrow {
    cursor: pointer;
  }

  &_btnLabel {
    color: #787E93;
    font-size: 14px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
  }

  &_controller {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &_btnAdd {
    width: 120px;
    height: 50px;
    color: #FFFFFF;
    background: #0EBFA1;
    border-radius: 4px;
  }

  &_message {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
  }

  &_messageReload {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    color: #0EBFA1;
  }

  &_sourceResult {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    padding: 6px;
    background: none;

    &_title {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #262626;
    }

    &_link {
      display: flex;
      align-items: center;
      gap: 5px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #787E93;
    }
  }
}