.campaignSelectList {
  &_root {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: stretch;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 999;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    background: rgba(29, 29, 27, 0.5);
    overflow-y: auto;
  }
}

.modalWindow {
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  width: 1185px;
  min-height: 654px;
  margin: 160px 0;
  padding: 26px;
  background: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  opacity: 1;
  border-radius: 6px;

  &_btnBack {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #787E93;
    margin: 10px;
    width: 72px;
  }

  &_btnBack:hover {
    cursor: pointer;
  }

  &_arrow {
    width: 8px;
    height: 16px;
    margin-right: 12px;
    transform: rotate(180deg);
  }

  &_list {
    width: 1144px;
    height: 480px;
    margin: 20px 0;
    overflow: hidden;
    overflow-y: scroll;
    padding: 0;
  }

  &_titleList {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #787E93;
    height: 44px;
    background: #E8F1F9;
  }

  &_row {
    display: flex;
    border-bottom: 1px solid #D6DDE8;
  }

  &_checkedCell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    width: 70px;
    padding: 10px;
  }

  &_nameCell {
    width: 250px;
    padding: 12px;
    color: black;
    font-weight: 500;
    font-size: 14px;
  }
  &_triangle{
    width: 12px ;
    height: 8px;
    margin-left: 10px;
  }
  &_search{
    width: 13px ;
    height: 13px;
    margin-left: 14px;
  }

  &_dateCell {
    width: 147px;
    padding: 12px;
  }

  &_periodCell {
    width: 250px;
    padding: 12px;
  }

  &_authorCell {
    width: 200px;
    padding: 12px;
  }

  &_actionCell {
    width: 100px;
    padding: 12px;
  }

  &_controlBlock {
    display: flex;
    align-items: center;
    width: 80px;
    min-width: 80px;
    gap: 10px;
  }

  &_controller {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  &_btnAdd {
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    width: 121px;
    height: 48px;
    background: #0EBFA1;
    border-radius: 4px;
  }
}