.root {
    display: flex;
    justify-content: space-between;
    margin: 0 32px;
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 32px;
    padding: 24px;
    background: #FFFFFF;
    border-radius: 6px;
}

.header {
    display: flex;
    margin-bottom: 24px;
    justify-content: space-between;
}

.tabs {
    display: flex;
    flex-flow: row nowrap;
    gap: 16px;
    
    button {
        padding: 10px;
        border: 1px solid rgba(214, 221, 232, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        font-weight: 500;
        color: #787E93;
        background: none;
    }

    & &Active {
        background: #DFFCF7;
    }
}