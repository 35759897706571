.userNavigatePanel {
    &_root {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 200px;
        min-width: 200px;
        height: 90px;
        padding: 0 12px;
        background: white;
        border-radius: 6px;
    }
}

.link {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    width: 200px;
    height: 40px;

    &:first-child {
        border-radius: 6px 6px 0 0;
    }

    &>span {
        margin-left: 16px;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: flex-end;
        color: #787E93;
    }

    &Active {
        background: #E8F1F9;
    }
}

.itemContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0;
    margin: 8px 0;
    gap: 16px;
    height: 32px;
    width: 100%;
    background: #FFFFFF;

    &:hover {
        cursor: pointer;
        background: rgb(245, 245, 245);
    }

    &_text {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: flex-end;
        color: #787E93;
    }
}