.listCheckBoxes {
    position: relative;
    flex-shrink: 0;
    width: fit-content;
    max-height: 350px;
    overflow: scroll;
  
  }
  .checkBoxItem {
    max-width: 140px;
    display: flex;
    align-items: center;
    margin-top: 12px;
    .label {
      margin-left: 8px;
      max-width: 110px;
      white-space: wrap;
    }
  }