.searchPhraseParametersContextMenu {
  &_root {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1001;
  }
}

.modalContextMenu {
  display: flex;
  flex-direction: column;
  min-width: 100px;
  max-width: 560px;
  max-height: 545px;
  padding: 16px;
  background: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  opacity: 1;
  border-radius: 12px;
}

.flexRow {
  display: flex;
  align-items: center;
  width: 100%;
}

.gap20{
  gap: 20px;
}

.gap16{
  gap: 16px;
}

.gap8{
  gap: 8px;
}

.label {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #787E93;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  width: 350px;
  gap: 20px;
}

.parameterWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.inputBox {
  display: flex;
  padding: 0;
  gap: 8px;
  width: 250px;
  background: white;
  border-bottom: 1px solid #D6DDE8;
}

.input {
  width: 440px;
  height: 27px;
  background: white;
  border: none;
  outline: none;

  :global {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
    }
  }

  &_text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #262626;
  }
}

.backgroundNone {
  background: none;
}

.numberOccurrences {
  display: flex;
  align-items: center;
  padding: 0 14px;
  gap: 10px;
  width: 331px;
  height: 40px;
  background: #F8FBFF;
  border-radius: 6px;
}

.radioButton {
  align-items: flex-start;
}

.checkboxText {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: black;
}