.campaignReportsContainer {
  &_root {
    // display: flex;
    // align-items: center;
    width: 100%;
  }
}
.loader {
  z-index: 10;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  display: flex;
  height: 400px;
  padding-top: 200px;
  justify-content: center;
}
.hiddenChart {
  position: absolute;
  z-index: -10;
}
.flexColumn {
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.flexRow {
  display: flex;
  width: 100%;
}

.messageBox {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 6px;
  width: 315px;
  height: 148px;
  background: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  &_error {
    color: #F36869;
  }
}

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.messageWrapper {
  display: flex;
  justify-content: center;
  width: 676px;
  min-height: 532px;
}