

.listsSourcs {
  overflow: auto;
  height: fit-content;
  max-height: 205px;
}

.list {
  display: flex;
  align-items: center;
  padding: 12px;
  &:first-child {
    padding-top: 0px;
  }
  &:last-child {
    padding-bottom: 0px;
  }
  &_label {
    margin-left: 10px;
    color: #262626;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 131.5%; 
  }
}

.empty {
  width: 100%;
  padding: 12px 0px;
  display: flex;
  justify-content: center;
}