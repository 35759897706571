.header {
    padding: 24px 32px 16px 32px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    span {
        color: #262626;
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 125% */
    }

    button {
        margin-left: auto;
        display: flex;
        align-items: center;
    }
}

.content {
    padding: 16px 32px;

    p {
        margin-bottom: 0;
        color: #262626;
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 135%; /* 18.9px */
    }
}

.footer {
    padding: 16px 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
	gap: 16px;
}
