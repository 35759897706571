.modalContainer {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  height: 100%;
  width: calc(100%);
  top: 0;
  left: 0;
}
.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}
.modalOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.15);
}
.cardContainer {
  width: 780px;
  position: relative;
  margin-bottom: 16px;
  background: white;
  padding: 32px;
  border-radius: 12px;
}
.cardHeader {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  gap: 24px;

  &Actions {
    margin-left: auto;
    display: flex;
    gap: 12px;
  }
}
.iconsWithLabel {
  display: flex;
  align-items: center;
  margin-right: 12px;
  font-size: 12px;
  gap: 8px;
  margin-top: 16px;

  div {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    color: #787e93;
    font-weight: 500;
  }
}
.platformLabel {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.cardDescriptonTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1d202f;
  margin-bottom: 12px;
}
.cardDescriptonContent {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #262626;
}
.cardReactions {
  display: flex;
  margin-bottom: 10px;
}
.cardTags {
  display: flex;
  flex-wrap: wrap;
}
.cardTag {
  border-radius: 6px;
  background: #f8fbff;
  padding: 10px;
  margin-right: 12px;
  margin-top: 8px;
}
.addTag {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #787e93;
  cursor: pointer;
}
.removeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  // fill="#F36869"
  fill: #dfdfdf;
}
.cardContnet {
  display: flex;
  align-items: center;
}
.cardPreview {
  width: 150px;
  height: 100px;
  flex-shrink: 0;
  object-fit: fill;
  background-size: cover;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 6px;
}

.toggleOpenModalMoreInformation {
  margin-top: 16px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  // color: #0EBFA1;
  // fill: #0EBFA1;
  color: #dfdfdf;
  fill: #dfdfdf;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.labelToggleOpenModalMoreInformation {
  margin-right: 6px;
  margin-bottom: 8px;
}

.sourceType {
  display: flex;
  align-items: flex-start;

  img {
    margin-top: -3px;
  }
}

.author {
  display: flex;
  gap: 8px;
  align-items: flex-start;

  &Name {
    position: relative;
    width: 86px;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #129fd4;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    line-height: 16px;
    font-size: 12px;
    height: 32px;
  }
}

.postedDate {
  display: flex;
  align-items: center;
  gap: 8px;

  &Value {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    color: #787e93;
    font-family: "Montserrat", sans-serif;
  }
}

.tonality {
  display: flex;
  flex-flow: column nowrap;
  font-family: "Montserrat", sans-serif;

  &Label {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #787e93;
  }

  &Description {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &Indicator {
    width: 8px;
    height: 8px;
    border-radius: 100%;
  }

  &Status {
    color: #1d202f;
    font-weight: 500;
    font-size: 12px;
  }
}

.postLink {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  p {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    color: #129fd4;
    font-weight: 600;
    margin-bottom: 0;
  }
}


.modalContainerExpand {
  .cardContainer {
    width: 100%;
    max-width: 80vw;
    transition: all 0.3s;
  }

  .cardContnet {
    max-width: 800px;
  }
}