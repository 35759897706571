$largeWidth: 300px;
$largeHeight: 52px;
$mediumWidth: 250px;
$mediumHeight: 40px;
$smallWidth: 200px;
$smallHeight: 32px;
$largeFontSize: 16px;
$mediumFontSize: 14px;
$smallFontSize: 12px;

.containerScroll {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	padding-top: 4px;
	padding-bottom: 4px;
	padding-right: 6px;
}

.menu {
	&.withScroll {
		// overflow-y: auto;
		width: 100%;
	}

	&.large {
		max-height: calc(($largeHeight + 6px) * 5);
	}

	&.medium {
		max-height: calc($mediumHeight * 8);
	}

	&.small {
		max-height: calc($smallHeight * 10);
	}
}

.menu::-webkit-scrollbar {
	width: 7px;
	position: absolute;
}

.containerDropdown {
	:global {
		.ant-dropdown-menu {
			padding-top: 0;
			padding-bottom: 0;
			border-radius: 6px;
			overflow: hidden;
			box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
		}
	}
}

.item {
	position: relative;
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	color: #262626;
	background-color: #ffffff;
	font-weight: 500;
	line-height: 20px;
	padding: 0px 12px 0px 12px;
	border-radius: 8px;

	&.main {
		border: 1px solid #DEE1E780;
		transition: box-shadow 0.2s ease, background-color 0.3s ease;
		display: flex;
		align-items: center;
		cursor: pointer;

		&:hover {
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
		}

		&.disabled {
			color: #E1E4E7;
			background-color: #fff;

			&:hover {
				box-shadow: none;
				background-color: #fff;
			}
		}

		&.large {
			min-width: $largeWidth;
			min-height: $largeHeight;
			max-height: $largeHeight;
			font-size: $largeFontSize;

			span {
				margin: 0;
				max-width: fit-content;
				max-height: 20px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				padding-right: 20px;
				box-sizing: border-box;
			}
		}

		&.largeWidth {
			max-width: $largeWidth;

			span {
				max-width: calc($largeWidth - 64px);
			}
		}

		&.medium {
			min-width: $mediumWidth;
			min-height: $mediumHeight;
			max-height: $mediumHeight;
			font-size: $mediumFontSize;

			span {
				margin: 0;
				max-width: fit-content;
				max-height: 20px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				padding-right: 20px;
				box-sizing: border-box;
			}
		}

		&.mediumWidth {
			max-width: $mediumWidth;

			span {
				max-width: calc($mediumWidth - 64px);
			}
		}

		&.small {
			min-width: $smallWidth;
			min-height: $smallHeight;
			max-height: $smallHeight;
			font-size: $smallFontSize;

			span {
				margin: 0;
				max-width: fit-content;
				max-height: 20px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				padding-right: 20px;
				box-sizing: border-box;
			}
		}

		&.smallWidth {
			max-width: $smallWidth;

			span {
				max-width: calc($smallWidth - 64px);
			}
		}
	}

	&.content {
		width: 100%;
		padding: 14px;
		border-radius: 0px;
		display: flex;
		transition: background-color 0.3s ease;
		cursor: pointer;

		&.selected {
			background-color: #DEE1E780;
		}

		&.large {
			min-width: $largeWidth;
			font-size: $largeFontSize;
		}

		&.largeWidth {
			max-width: $largeWidth;
		}

		&.medium {
			min-width: $mediumWidth;
			font-size: $mediumFontSize;
		}

		&.mediumWidth {
			max-width: $mediumWidth;
		}

		&.small {
			padding: 9px;
			padding-left: 14px;
			min-width: $smallWidth;
			font-size: $smallFontSize;
		}

		&.smallWidth {
			max-width: $smallWidth;
		}

		&:hover {
			background-color: #DEE1E74D;
		}

		&:active {
			background-color: #DEE1E780;
		}
	}

	// #DEE1E74D
	&.pressed {
		border: 1px solid rgba(0, 0, 0, 0);
		background-color: #DEE1E74D;
	}
}





.root_Select {
	position: relative;

	margin: 24px;
	width: 250px;
	height: 40px;
	border-radius: 8px;

	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	color: #262626;
	background: #ffffff;

	font-weight: 500;
	font-size: 14px;
	line-height: 19px;

	border: 1px solid #DEE1E780;

	transition: box-shadow 0.2s ease;

	&:hover {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	}
}


.mainField {
	width: 100%;
	height: 100%;
}

.labelContent {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	cursor: pointer;
	user-select: none;
	padding: 0px 12px 0px 12px;
}

.content_select {
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 6px;
	background-color: #ffffff;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
	width: 100%;
}

.containerContent {
	width: 100%;
	height: 0px;
	position: relative;
}

.icn {
	position: absolute;
	right: 16px;
	fill: #262626;
	transition: all 0.3s ease;
	transform: rotate(180deg);

	&.open {
		transform: rotate(0) translateY(-2px);
	}

	&.large {
		top: 16px;
	}

	&.medium {
		top: 10px;
	}

	&.small {
		top: 5px;
	}

	&.disabled {
		fill: #E1E4E7;
	}
}

.accordionDropdown {
	& .main {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		height: 52px;
		padding: 0 12px;
		border: 1px solid rgba(222,225,231,.502);
		border-radius: 8px;
		background: rgb(255, 255, 255);
		transition: all 0.3s;
		&:hover{
			box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
		}
	}
	& .arrow {
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 135%;
		color: #787E93;
	}

	& .large {
		min-width: $largeWidth;
		font-size: $largeFontSize;
	}

	& .medium{
		min-width: $mediumWidth;
		font-size: $mediumFontSize;
	}

	& .small {
		min-width: $smallWidth;
		font-size: $smallFontSize;
	}
}

.accordionDropDownList { 
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 8px;
	transition: all 0.3s;
	height: 310px;
	overflow: hidden;
}

.skeletonOverlay {
	width: 100%;
	box-sizing: border-box;
	padding: 12px;
	overflow-y: auto;
	display: flex;
	flex-wrap: wrap;
	& span:not(:first-child) {
		margin-top: 6px
	}

	&_large {
		max-height: calc(($largeHeight + 6px) * 5);
	}

	&_medium {
		max-height: calc($mediumHeight * 8);
	}

	&_small {
		max-height: calc($smallHeight * 10);
	}
}
.skeletonOverlay::-webkit-scrollbar {
	width: 7px;
	position: absolute;
}

.loaderWrapper {
	box-sizing: border-box;
	padding: 14px;
}