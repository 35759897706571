.actionsCell {
    position: relative;
    display: flex;
    align-items: center;
    height: 64px;
    gap: 12px;
    padding-left: 12px;
    cursor: pointer;
	&Favorite {
        fill: none;
        stroke: #787E93;
        stroke-width: 2.5px;
        &.active {
          stroke: none;
          fill: #0EBFA1;
        }
		height: 24px;
	}

    &Menu {
        position: relative;

        &Button {
            display: flex;
            width: 32px;
            height: 32px;
            background-color: rgba(236, 242, 249, 0.5);
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: relative;

            span,
            &::after,
            &::before {
                width: 4px;
                height: 4px;
                background: #787e93;
                border-radius: 50%;
            }

            &::after,
            &::before {
                position: absolute;
                content: "";
                left: 50%;
                transform: translateX(-50%);
            }

            &::after {
                top: 7px;
            }
            &::before {
                bottom: 7px;
            }
        }

		&List {
			position: absolute;
			background: #fff;
			padding: 12px;
			display: flex;
			flex-flow: column nowrap;
			gap: 10px;
			z-index: 999;
			width: 200px;
			right: 32px;
			box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.10);
			border-radius: 6px;

			& > button {
				display: flex;
				gap: 10px;
				font-family: 'Montserrat', sans-serif;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 135%;
				color: #787E93;
				align-items: center;
			}
		}
    }
}