.commonButton {
  &_root {
    font-family: Montserrat, sans-serif;
    font-style: normal;
  }

  &_button {
    color: #FFFFFF;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 6px 12px;
    border-radius: 6px;
    border: none;
    transition: all 0.5s;
  }

  &_default {
    background: #0EBFA1;
    box-shadow: 0 2px 5px 0 rgba(14, 191, 161, 0.12), 0 0 0 1px #0EBFA1, 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 0 0 0 #0EBFA1 inset;
    transition: all 0.5s;
  }

  &_default:hover {
    background: #2BD3B7;
  }

  &_default:active {
    background: #0DAA8F;
    box-shadow: 0 2px 5px 0 rgba(14, 191, 161, 0.12), 0 0 0 1px #0EBFA1, 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 0 0 0 #0EBFA1 inset;
  }

  &_secondary {
    color: #0EBFA1;
    background: #FFFFFF;
    box-shadow: 0 1px 5px 0 rgba(14, 191, 161, 0.12), 0 0 0 1px #0EBFA1, 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 0 0 0 #0EBFA1 inset;
    transition: all 0.5s;
  }

  &_secondary:hover {
    box-shadow: none;
    background: rgba(43, 211, 183, 0.10);
  }

  &_secondary:active {
    color: #FFFFFF;
    background: #0DAA8F;
    box-shadow: 0 2px 5px 0 rgba(14, 191, 161, 0.12), 0 0 0 1px #0EBFA1, 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 0 0 0 #0EBFA1 inset;
  }


  &_danger {
    color: #FFFFFF;
    background: #F36869;
    box-shadow: 0 2px 5px 0 rgba(243, 104, 105, 0.12), 0 0 0 1px #F36869, 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 0 0 #F36869 inset;
    transition: all 0.5s;
  }

  &_danger:hover {
    background: #FF6566;
    box-shadow: 0 2px 5px 0 rgba(243, 104, 105, 0.12), 0 0 0 1px #F36869, 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 0 0 0 #F36869 inset;
  }

  &_danger:active {
    color: #FFFFFF;
    background: #DF4647;
    box-shadow: 0 2px 5px 0 rgba(243, 104, 105, 0.12), 0 0 0 1px #F36869, 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 0 0 0 #F36869 inset;
  }

  &_secondaryDanger {
    color: #F36869;
    background: #ffffff;
    box-shadow: 0 1px 5px 0 rgba(243, 104, 105, 0.12), 0 0 0 1px #F36869, 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 0 0 0 #F36869 inset;
    transition: all 0.5s;
  }

  &_secondaryDanger:hover {
    background: #ffffff;
    box-shadow: 0 2px 5px 0 rgba(243, 104, 105, 0.12), 0 0 0 1px #F36869, 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 0 0 0 #F36869 inset;
  }

  &_secondaryDanger:active {
    color: #ffffff;
    background: #F36869;
    box-shadow: 0 2px 5px 0 rgba(243, 104, 105, 0.12), 0 0 0 1px #F36869, 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 0 0 0 #F36869 inset;
  }

  &_disabled {
    background: rgba(225, 228, 231, 0.40);
    border-radius: 6px;
    cursor: default;
    color: rgba(120, 126, 147, 0.30);
  }

  &_disabledSecondary {
    background: #FFFFFF;
    border-radius: 6px;
    border: 1px solid rgba(120, 126, 147, 0.30);
    cursor: default;
    color: rgba(120, 126, 147, 0.30);
  }

  &_large {
    font-size: 16px;
    font-weight: 500;
    line-height: 131.5%;
    max-width: 300px;
    height: 50px;
  }

  &_medium {
    font-size: 14px;
    font-weight: 500;
    line-height: 135%;
    max-width: 200px;
    height: 40px;
  }

  &_small {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    max-width: 94px;
    height: 31px;
  }
}