.campaignCompareList {
  &_root {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

.listContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.compareList {
  display: flex;
  flex-direction: column;
  gap: 8px;
  //height: 100px;
  //overflow-y: auto;
  padding-right: 8px;
}

.campaignContainer {
  display: flex;
  align-items: center;
  gap: 24px;
}

.campaignData {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 0 10px;

  width: 450px;
  height: 40px;
  background: #FEF8E8;
  border: 1px solid rgba(230, 126, 34, 0.2);
  border-radius: 6px;

  &_name {
    min-width: 210px;
    max-width: 210px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
  }
  &_dateContainer {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &_date {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #787E93;
  }
}

.campaignBoxColor {
  background: #FFFFFF;
  border: 1px solid rgba(120, 126, 147, 0.3);
}

.campaignTitle {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 131.5%;
  color: #787E93;
}

.crossButton {
  width: 14px;
  height: 14px;
  background: 0;
  padding: 0;
}

.controlContainer {
  display: flex;
  gap: 24px;
}

.hintContainer {
  &_block {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 16px;
    height: 64px;
  }

  &_textBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 500px;
    height: 64px;
    padding: 12px 19px;
    background: #DFFCF7;
    border-radius: 7px;
  }

  &_text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #787E93;
  }
}