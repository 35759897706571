.dropdown {
  background-color: white !important;
}

.selectItemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 0 10px;
  gap: 10px;
  min-width: 145px;
  height: 48px;
  background-color: white;
  border-radius: 6px;
  border: none;
  cursor: pointer;

  &_text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #262626;
  }
}

.selectItemContainer.shadow {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.regionsAmountText {
  display: flex;
  white-space: nowrap;
  margin-right: 8px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #787E93;
}

.regionsAmountText.retina {
  font-size: 14px;
  line-height: 140%;
}

.selectItemContainer_text.retina {
  font-size: 14px;
  line-height: 140%;
}

.selectItemContainer.filled {
  background-color: #E8F1F9;
}

.selectItemContainer.retina {
  height: 32px;
  min-width: 105px;
  max-width: 105px;
}