.iconButtonWithLabel {
  &_root {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  &_label {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    text-align: center;
    color: #787E93;
    cursor: pointer;
  }
}