.containerGlobalInfo {
  display: flex;
  background-color: white;
  border-radius: 6px;
  padding: 16px 24px;
  width: fit-content;
  height: fit-content;
  margin-bottom: 16px;
  gap: 26px;
}

.subBox {
  display: flex;
  width: fit-content;
  height: fit-content;
  gap: 26px;
}

.blockTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #787E93;
}

.contentContainer {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 110px;
  cursor: pointer;
  &.full {
    width: fit-content; 
  }
  // border-color: lineargradient(to left, red, red 50%, blue 15%, purple 75%);
}
.hideGradient {
  position: absolute;
  background: linear-gradient(to right, rgba(255,255,255,0), white);
  width: 15px;
  height: 50px;
  top: -10px;
  right: 0;
}

.currentContent {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #787E93;
  margin-right: 8px;
  white-space: nowrap;

}

.positiveDiff {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #0EBFA1;
}
.negativeDiff {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #F36869;
}