.campaignAnalysisTable {
  &_root {
    display: flex;
    flex-direction: column;
    width: 1140px;
    font-family: 'Montserrat', sans-serif;
    height: 100%;
    overflow: auto;
  }
}

.header {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #787E93;
  height: 45px;
  background: #E8F1F9;

  &_name {
    display: flex;
    width: 58%;
    height: 70%;
    margin-left: 46px;
  }

  &_input {
    width: 300px;
    padding: 8px;
    background: none;
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }

  &_input:focus {
    background: #FFFFFF;
    outline: none;
  }

  &_search {
    margin-left: 17px;
    cursor: pointer;
  }

  &_arrow {
    margin-left: 15px;
  }

  &_period {
    width: 20%;
    padding-left: 12px;
  }

  &_actions {
    padding-left: 12px;
  }
}

.tableBody {
  height: 100%;
  overflow-y: auto;
}

.noDataElement {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: black;
}