.selectColumns {
  position: absolute;
  z-index: 200;

  &.main {
    display: flex;
    gap: 10px;
    width: 227px;
    max-height: 253px;
    padding: 12px;
    background: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.10);
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  overflow-y: auto;
}

.controlBlock {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 180px;
  min-width: 180px;
}

.text {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  color: black;
}