$columnWidths: minmax(200px, 350px) minmax(100px, 150px) minmax(100px, 150px) minmax(100px, 150px);

@mixin tableRow {
    display: grid;
    grid-template-columns: $columnWidths;
    column-gap: 10px;
    justify-content: space-between;
}

.wrapper {
    background: #FFFFFF;
}

.row {
    @include tableRow();
    border-bottom: 1px solid rgba(214, 221, 232, 0.5);
    padding: 6px 0;
    align-items: center;

    &Name {
        padding-left: 32px;
        display: flex;
        gap: 20px;

        span {
            font-family: 'Montserrat', sans-serif;
            font-weight: 500;
            font-size: 14px;

            &:first-child {
                color: #787E93;
            }

            &:last-child {
                color: #262626;
            }
        }
    }

    &Access {
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #262626;
    }

    &MoreButton {
        color: #787E93;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 14px;
        background: none;
        text-align: left;
        padding: 0;
    }
}

.list {
    display: flex;
    flex-flow: column nowrap;

    &Row {
        @include tableRow();
        padding: 10px 0;
        border-bottom: 1px solid rgba(214, 221, 232, 0.5);
        grid-auto-flow: dense;

        span {
            &:first-child {
                grid-column: 2 / 3;
                font-family: 'Montserrat', sans-serif;
                font-weight: 400;
                font-size: 14px;
                color: #262626;
            }

            &:nth-child(2) {
                grid-column: 3 / 4;
                font-family: 'Montserrat', sans-serif;
                font-weight: 400;
                font-size: 14px;
                color: #262626;
            }
        }

        .actions {
            grid-column: 4 / 5;
            display: flex;
        }
    }
}

.actions {
    display: flex;
    gap: 16px;
    align-items: center;

    button {
        background: none;
        text-align: left;
        padding: 0;
    }

    .arrowButton {
        &Open {
            transform: rotate(180deg);

            svg path {
                stroke: #0EBFA1;
            }
        }
    }

    .addUserButton {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #DFFCF7;
        border-radius: 50%;
    }
}

.hidden {
    opacity: 0;
}