.root_headerFilters {
    margin-left: 16px;
    margin-top: 12px;
}

.seporatorLineHeaderFilters {
    width: calc(100% - 16px);
    height: 1px;
    margin-bottom: 12px;
    margin-top: 12px;
    background-color: #D6DDE8;
}

.contentDatePickerContainer {
    cursor: pointer;
    position: relative;
}

.dateCreateCampaing {
    display: flex;
    align-items: center;
    span {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 135%;
      color: #787E93;
      margin-right: 12px;
    }
    div {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: right;
      color: #787E93; 
    }
  }