.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 194px;
}

.titleBox {
  display: flex;
  align-items: center;
  padding: 10px;
  width: 119px;
  height: 40px;
  margin: 16px 0;
  background: #E8F1F9;
  border-radius: 6px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 131.5%;
  color: #262626;
}

.titleBox.retina {
  font-size: 14px;
  line-height: 140%;
}

.title {
  display: flex;
  align-items: center;
  width: 119px;
  height: 40px;
  margin: 16px 0;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #0EBFA1;
}

.title.retina {
  font-size: 14px;
  line-height: 140%;
}

.horizontalLine {
  width: 100%;
  height: 1px;
  background: #D6DDE8;
  border-radius: 1px;
}