.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 16px;
}

.subRoot {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  gap: 16px;
}

.scrollWrapper {
  overflow-y: auto;
}

.titleBlock {
  display: flex;
  align-items: center;
  width: 161px;
  min-height: 42px;
  padding: 0 10px;
  background: #DFFCF7;
  border-radius: 6px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 131.5%;
  color: #000000;
}

.titleBlockDisabled {
  display: flex;
  align-items: center;
  width: 161px;
  min-height: 42px;
  padding: 0 10px;
  background: #E1E4E7;
  border-radius: 6px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 131.5%;
  color: white;
}

.buttonsContainer {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 16px;
}

.subRow {
  display: flex;
  gap: 16px;

  &_column {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
}

.bottomContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
}

.label {
  display: flex;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #787E93;
}

.label.small {
  font-size: 12px;
  line-height: 20px;
}

.label.middle {
  font-size: 14px;
  line-height: 140%;
}

.usersBox {
  display: flex;
  align-items: center;
  width: 370px;
  height: 40px;
  gap: 16px;
  padding: 10px;
  margin-bottom: 50px;
  background: white;
  border-radius: 6px;
}

.pinnedInfo {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.wordsWrapper {
  display: flex;
  flex-direction: column;
  max-width: 1016px;
  gap: 18px;
  padding: 16px;
  background: white;
  border-radius: 6px;
}

.wordsBlock {
  padding: 16px;
}

.switchContainer {
  display: flex;
  gap: 16px;
  margin-top: -4px;
}

.flexRow {
  display: flex;
  gap: 16px;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #787E93;
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 32px;
  gap: 12px;

  &_status {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 320px;
    height: 44px;
    gap: 16px;
    border-radius: 6px;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #787E93;
  }
}

.activeStatus {
  background: #DFFCF7;
}

.inactiveStatus {
  background: #FDEBEB;
  :global {
    .ant-switch-small {
      background: #F36869;
    }
  }
}

.parseFromBox {
  display: flex;
  align-items: center;
  padding: 16px;
  height: 52px;
  max-width: 130px;
  background: white;
  border-radius: 6px;

  &_text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #787E93;
  }
}

.searchRequest {
  &_container {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &_btn {
    padding: 0;
    background: none;

    &Circle {
      border-radius: 16px;
      background-color: #dffcf7;
      padding-bottom: 8px;
      padding-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
    }
  }

  &_text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    color: #0EBFA1;
  }
}

.checkboxText {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: black;
}