.lineSlider {
  &_root {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
  }
}

.circle {
  position: absolute;
  top: -4px;
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 2;

  &:hover {
    cursor: pointer;
  }
}

.rectangle{
  position: absolute;
  top: -8px;
  background: #0EBFA1;
  opacity: 0.1;
}

.hint {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -40px;
  width: 96px;
  height: 30px;
  background: #0EBFA1;
  border-radius: 6px;
  z-index: 2;

  &_text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: white;
  }
}

.inactiveLine {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: rgba(14, 191, 161, 0.2);
  border-radius: 2px;
  z-index: 1;
}

.activeLine {
  position: absolute;
  top: 0;
  width: 100%;
  height: 4px;
  background-color: rgba(14, 191, 161, 1);
  border-radius: 2px;
  z-index: 2;
}

.triangle {
  position: absolute;
  left: 40px;
  top: 19px;
  height: 14px;
  width: 14px;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  transform: rotate(-45deg);
  border-radius: 0 0 0 0.25em;
  background-color: inherit;
}

.triangleInverted {
  position: absolute;
  left: 41px;
  top: -3px;
  height: 14px;
  width: 14px;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  transform: rotate(135deg);
  border-radius: 0 0 0 0.25em;
  background-color: inherit;
}