.projectCreate {
  &_root {
    display: flex;
    flex-direction: column;
    padding: 16px;
    margin-left: 32px;
    gap: 16px;
    width: 1190px;
    min-height: 300px;
    background: white;
    border-radius: 6px;
  }

  &_title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
  }
}