.numberSort {
    display: flex;
    flex-flow: column nowrap;
    gap: 8px;
    width: fit-content;
    button {
        display: flex;
        gap: 8px;
        align-items: center;

        span {
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 135%;
            white-space: nowrap;
            color: #262626;
        }

        &:last-child svg {
            transform: scale(1, -1);
        }
    }
}