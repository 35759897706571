.advancedSearch {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: stretch;
  position: fixed;
  top: 0;
  left: 0;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  height: 100%;
  background: rgba(29, 29, 27, 0.5);
  z-index: 1000;
  opacity: 999;
  transition: all 0.5s;
}

.sidePage {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  right: -800px;
  width: 800px;
  height: 100%;
  padding: 24px;
  background: #FFFFFF;
  transition: all 0.8s;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 12px;
  }

  &_title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
  }

  &_btnCross {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_img {
    width: 20px;
    height: 20px;
  }

  &_wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 16px;
    margin-top: 16px;
    overflow-y: auto;
  }

  &_contetnWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: hidden;
    padding: 0 5px;
  }

  &_searchField {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    min-height: 50px;
    width: 300px;
    padding: 0 12px 0 0;
    background: #FFFFFF;
    border-radius: 6px;
    border: 1px solid rgba(222, 225, 231, 0.5);
  }

  &_input {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    outline: 0;
    border: none;
  }

  &_imgSearch {
    display: flex;
    //margin: 0 5px;
    cursor: pointer;
  }
}
