.filtersChart {
    display: flex;
    align-items: center;
    margin-left: 12px;
}

.checkboxWithLabel {
    display: flex;
    align-items: center;
    margin-right: 8px;
    margin-bottom: 12px;
    height: 20px;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
}

.labelCheckbox {
    margin-left: 6px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
}


.checkBoxContent {
    height: fit-content;
}

.datesInfo {
    font-size: 12px;
    color: #787E93;
    word-spacing:12px;
    font-weight: 500;
    line-height: 20px;
    height: 20px;
}