.cardContainer {
    position: relative;
    margin-bottom: 16px;
    background: white;
    padding: 24px;
    border-radius: 6px;
}

.cardHeader {
    display: flex;
    margin-bottom: 8px;
    align-items: flex-start;
    gap: 24px;
}

.cardUpdate {
    font-family: 'Montserrat';
    font-style: normal;
    color: #787E93;
    font-size: 14px;
    line-height: 20px;
    align-self: flex-end;
}

.iconsWithLabel {
    display: flex;
    align-items: center;
    margin-right: 12px;
    font-size: 12px;
    gap: 8px;
    
    div {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        color: #787E93;
        font-weight: 500;
    }
    
}

.platformLabel {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.cardDescripton {
    width: 100%;
}

.cardDescriptonTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #1D202F;
    margin-bottom: 12px;
}

.cardDescriptonContent {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #262626;

}

.cardReactions {
    display: flex;
}

.cardReactionsContainer {
    display: flex;
    justify-content: space-between;
}

.cardTags {
    display: flex;
    flex-wrap: wrap;
}

.cardTag {
    border-radius: 6px;
    background: #F8FBFF;
    padding: 10px;
    margin-right: 12px;
    margin-top: 8px;
}

.postedDate {
    display: flex;
    align-items: center;
    gap: 8px;

    &Value {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        white-space: nowrap;
        color: #787E93;
        font-family: 'Montserrat', sans-serif;
    }
}


.addTag {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #787E93;
    padding-top: 16px;
    cursor: pointer;
}

.removeButton {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    // fill="#F36869"
    fill: #dfdfdf;
}

.cardContnet {
    display: flex;
    position: relative;
    align-items: center;
}

.cardPreview {
    width: 150px;
    height: 100px;
    flex-shrink: 0;
    object-fit: fill;
    background-size: cover;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
}

.author {
    display: flex;
    gap: 8px;
    align-items: flex-start;

    &Name {
        position: relative;
        width: 86px;
        white-space: break-spaces;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #129FD4;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        line-height: 16px;
        font-size: 12px;
        height: 32px;
    }
}

.toggleOpenModalMoreInformation {
    margin-top: 12px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    // color: #0EBFA1;
    // fill: #0EBFA1;
    color: #129FD4;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    gap: 2px;
}

.deleteButton {
    margin-left: auto;
    border: none;
    background: none;
    padding: 0;
}

.restoreButton {
    padding: 10px;
    background: #EDFFFC;
    border-radius: 6px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #0EBFA1;
    margin-left: auto;

    img {
        margin-right: 8px;
    }
}

.deletedCardContainer {
    position: relative;
    margin-bottom: 16px;
    background: #E8F1F9;
    padding: 5px 16px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    p {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        margin-bottom: 0;
        margin-right: 5px;
    }

    button {
        padding: 0;
        background: none;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #0EBFA1;
    }
}

.sourceType {
    display: flex;
    align-items: flex-start;

    img {
        margin-top: -3px;
    }
}

.tonality {
    display: flex;
    flex-flow: column nowrap;
    font-family: 'Montserrat', sans-serif;
    
    &Label {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #787E93;
    }

    &Description {
        display: flex;
        align-items: center;
        display: flex;
        align-items: center;
        gap: 8px;
    }
    
    &Indicator {
        width: 8px;
        height: 8px;
        border-radius: 100%;
    }

    &Status {
        color: #1D202F;
        font-weight: 500;
        font-size: 12px;
    }
}

.postLink {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    p {
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        color: #129FD4;
        font-weight: 600;
        margin-bottom: 0;
    }
}

.cardInformation {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 8px;
}