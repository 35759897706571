.root_Sort {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.containerSorts {
    display: flex;
    align-items: center;
}

.containerSortItem {
    background: #FFFFFF;
    border-radius: 6px;
    font-size: 12px;
    line-height: 20px;
    padding: 5px 10px;
    cursor: pointer;
    user-select: none;
    &:not(:last-child) {
      margin-right: 14px;
    }
}

.isSelectSort {
    background: #E8F1F9;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

.containerFilter {
    padding-left: 16px;
}
.containerContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 8px;
    padding-bottom: 2px;
    svg {
        width: 6px;
    }
    &:hover {
        background-color: #F8FBFF;
    }
}

.name {
    margin-bottom: 4px;
}

.select {
    background-color: #F8FBFF;
}

.emptyFilters {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 15px;
    font-weight: bold;
    color: #787e93
}