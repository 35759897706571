.addMediaPlan {
  &_root {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: stretch;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 999;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    background: rgba(29, 29, 27, 0.5);
    overflow-y: auto;
  }

  &_window {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    margin-top: 10%;
    width: 440px;
    min-height: 150px;
    height: fit-content;
    background: #FFFFFF;
    border-radius: 6px;
    justify-content: space-between;
  }

  &_container {
    display: flex;
    flex-direction: column;
    margin: 24px 32px;
    gap: 16px;
    height: 100%;

    &_content {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &_header {
      display: flex;
      justify-content: space-between;
    }
  }

  &_controls {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 16px;
  }

  &_title {
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: #262626;
  }

  &_textContent {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    color: #262626;
  }

  &_simpleTextContent {
    @extend .addMediaPlan_textContent;
    line-height: 130%;
  }

  &_devider {
    border-bottom: 1px solid rgba(222, 225, 231, 0.50);
    background: #FFF;
  }

  &_simpleTextError {
    @extend .addMediaPlan_textContent;
    line-height: 130%;
    color: #ff7875;
  }
}