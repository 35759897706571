.overlay {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: stretch;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 999;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    background: rgba(29, 29, 27, 0.5);
    overflow-y: auto;
}

.modalWindow {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 160px 0;
    background: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    opacity: 1;
    border-radius: 6px;
}

.content {
    padding: 24px;
    width: 510px;
}


.header {
    display: flex;
    padding-bottom: 16px;
    flex-flow: row nowrap;
    justify-content: space-between;
    border-bottom: 1px solid #D6DDE8;
    width: 100%;
    align-items: flex-start;
    margin-bottom: 16px;

    span {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        color: #787E93;
    }

    button {
        padding: 0;
        background: none;
    }
}

.text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #787E93;
    margin-bottom: 0;
}

.form {
    display: flex;
    flex-flow: column nowrap;
    gap: 24px;
    width: 100%;
    margin-top: 24px;
}

.campaign {
    display: flex;
    flex-flow: row nowrap;
}

.email {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
}

.step {
    display: flex;
    flex-flow: row nowrap;
    gap: 22px;
    align-items: center;
    margin-right: 10px;
    
    &Number {   
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #0EBFA1;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #fff;
    }

    &Label {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #787E93;
        margin-bottom: 0;
    }
}

.inputField {
    width: auto;
    height: 48px;
    border: none;
    outline: none;
    border-bottom: 1px solid #0EBFA1;
    background: none;
    padding: 14px 10px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    color: #000000;
    flex: 1;
}

.invalid {
    border: 1px solid #F36869;
}

.buttonConfirm {
    display: flex;
    justify-content: flex-end;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.errorContainer {
    width: 424px;
    height: 104px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 66px;
    padding-left: 32px;
    position: relative;
    background: #FDEBEB;
    border-radius: 6px;

    p {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #F36869;
    }

    button {
        background: none;
        padding: 0;
        position: absolute;
        top: 37px;
        right: 37px;
    }
}

.success {
    width: 315px;
    height: 70px;
    display: flex;
    flex-flow: row nowrap;
    gap: 16px;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 0;
    position: relative;
    
    p {
        color: #787E93;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 0;
    }

    button {
        position: absolute;
        right: 10px;
        top: 5px;
    }
}