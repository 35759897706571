.header {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 1200px;
    margin-bottom: 16px;
}

.viewPage {
    &_root {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 24px 24px 32px;
        overflow-y: auto;
        position: relative;
    }

    &_header {
        display: flex;
        align-items: center;
        gap: 16px;
        width: 100%;
        max-width: 1240px;
        margin-bottom: 16px;
    }
}

