.campaignNameInput {
  &_root {
    display: flex;
    align-items: center;
    max-width: 1000px;
    min-width: 400px;
    // height: 52px;
    min-height: 52px;
    padding: 0 16px;
    gap: 12px;
    background: white;
    border-radius: 6px;
  }
}

.skeletonWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}

.label {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #787E93;
}

.inputBox.red {
  border-bottom: 1px solid #F36869;
}

.tooltip {
  position: absolute;
  width: 100px;
  height: 100px;
  top: -10px;
  background-color: white;
  z-index: 100;
}