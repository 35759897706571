.mediaPlan {
  &_root {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &_header {
    display: flex;
    gap: 24px;

    &_title {
      color: #262626;
      font-size: 20px;
      font-family: Montserrat, sans-serif;
      font-weight: 600;
      line-height: 20px;
    }

    &_saveSearch {
      height: 21px;
      color: #129FD4;
      font-size: 14px;
      font-family: Montserrat, sans-serif;
      font-weight: 600;
      line-height: 20px;
      border-bottom: 1px solid #129FD4;
      cursor: pointer;
    }

    &_actions {
      display: flex;
      flex-flow: row nowrap;
      gap: 12px;
      margin-left: auto;
    }
  }

  &_footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 32px;
    gap: 24px;
  }

  &_controller {
    border-radius: 100%;
    height: 31px;
    width: 31px;
    background-color: #F8FBFF;
    fill: #787E93;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.5s ease;
    transform: rotate(0deg);
    &.open {
      transition: all 0.5s ease;
      transform: rotate(180deg);
    }

    &:hover {
      background-color: #ecf1f9;
    }
  }
}

.flexRowVCenter {
  display: flex;
  align-items: center;
}

.gap8 {
  gap: 8px;
}

.btnSelectColumns {
  border-bottom: 1px solid #129FD4;

  &.text {
    color: #129FD4;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }
}

.btnRemove {
  cursor: pointer;
  fill: #787E93;
  transition: all 0.3s ease;

  &:hover {
    fill: tomato
  }
}

.meidaPlan {
  &_controls {
    display: flex;
    align-items: center;
  }
  &_deleted {
    margin-left: 4px;
    color: #787E93;
    fill: #787E93;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    &.isOnlyDeleted {
      color: #F36869;
      fill: #F36869;
      text-decoration: underline;
      text-underline-offset: 5.5px;
      span {
        margin-right: 6px;
      }
      svg {
        position: relative;
        top: 2px
      }
    }
  }
}

.message {
  &_succes {
    margin-top: calc(100vh - 80px);
    :global {
      .ant-message-notice-content {
        background: #0EBFA1;
        border-radius: 8px;
        box-shadow: 0px 32px 32px -16px rgba(0, 0, 0, 0.08), 0px 4px 24px -16px rgba(0, 0, 0, 0.12);
        color: #FFF;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; 
      }
    }
  }
  &_error {
    margin-top: calc(100vh - 80px);
    :global {
      .ant-message-notice-content {
        background: #F36869;
        border-radius: 8px;
        box-shadow: 0px 32px 32px -16px rgba(0, 0, 0, 0.08), 0px 4px 24px -16px rgba(0, 0, 0, 0.12);
        color: #FFF;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; 
      }
    }
  }
}