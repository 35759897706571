.wrapper {
  display: flex;
  position: relative;
  background: #E8F1F9;
  width: 100%;
  &Composite {
    background: transparent;
    height: 88px;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.container {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
}

.resultsCell {
  display: flex;
  align-items: center;
  height: 88px;
  width: 100%;

  &Value {
    font-size: 12;
    font-weight: 500;
    color: #787e93;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 6px 8px;
  }

  &Comment {
    display: flex;
    align-items: center;
    height: 88px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 36px;
  }

  &Plan {
    &Views {
      height: 44px;
      background-color: rgba(237, 255, 252, 1);
    }
    &ER {
      height: 44px;
      margin-left: 8px;
      background-color: rgba(237, 255, 252, 1);
    }
    &CPV {
      height: 44px;
      background-color: rgba(237, 255, 252, 1);
    }
  }

  &Fact {
    &Views {
      height: 44px;
      background-color: rgba(254, 248, 232, 1);
    }
    &ER {
      height: 44px;
      background-color: rgba(254, 248, 232, 1);
    }
    &CPV {
      height: 44px;
      background-color: rgba(254, 248, 232, 1);
    }
  }

  &Percent {
    &Views {
      height: 44px;
      background-color: rgba(248, 251, 255, 1);
    }
    &ER {
      height: 44px;
      margin-right: 8px;
      background-color: rgba(248, 251, 255, 1);
    }
    &CPV {
      height: 44px;
      background-color: rgba(248, 251, 255, 1);
    }
  }
}


.cellButton {
  gap: 10px;
  cursor: pointer;
  padding: 6px 8px;
  display: flex;
  align-items: center;

  &:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
}

.modal {
  background: #ffffff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  position: absolute;
  top: 44px;
  padding: 12px;
}

.searchButton {
  display: flex;
  align-items: center;
}

.cellSearch {
  padding: 10px;
  position: absolute;
  width: 355px;
  top: 36px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  z-index: 9;
  input {
    border-radius: 6px;
    border: 1px solid rgba(222, 225, 231, 0.5);
    height: 48px;
    background: #fff;
    width: 100%;
    font-size: 16px;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    color: #262626;

    &:focus {
      border: 1px solid #129fd4;
    }
  }

  button {
    position: absolute;
    right: 22px;
    top: 50%;
    transform: translateY(-50%);
    height: 16px;
  }
}

.compositeHeaderCell {
  position: absolute;
  top: 0;
  height: 44px;
  background-color: rgba(232, 241, 249, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}