.add {
  position: relative;
  width: 16px;
  height: 16px;
  margin-left: 2px;
  &::after, &::before {
    content: ' ';
    position: absolute;
    background-color: #787E93;
    border-radius: 4px;
  };
  &::before {
    height: 100%;
    width: 2px;
    left: calc(50% - 1px);
  }
  &::after {
    width: 100%;
    height: 2px;
    top: calc(50% - 1px)
  }
}

.control {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  user-select: none;
  cursor: pointer;
  transition: background 0.2s;
  &:hover {
    background: rgba(120, 126, 147, 0.05);
  }
  &_label {
    font-family: Montserrat;
    text-align: center;
    font-style: normal;
    line-height: 135%;
    margin-left: 10px;
    font-weight: 500;
    font-size: 14px;
    color: #787E93;
  }

}

.listsSourcs {
  overflow: auto;
  height: fit-content;
  max-height: 205px;
  &_root {
    transition: max-height 0.3s;
    padding-right: 12px;
    max-height: 328px;
    height: fit-content;
    overflow: hidden;
  }
  &_search {
    width: 250px;
    padding: 12px;
    padding-right: 0px;
  }
  &_btns {
    padding: 12px;
    padding-right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
  }
}

.selected {
  background: rgba(120, 126, 147, 0.10);
}