.overlay {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: stretch;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 999;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    background: rgba(29, 29, 27, 0.5);

    overflow-y: auto;
}

.modalWindow {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    opacity: 1;
    border-radius: 6px;
    height: auto;
    margin: 160px 0;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 26px;
    gap: 20px;
    width: 400px;
    height: 165px;

    &Text {
        display: flex;
        flex-direction: column;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        color: black;
    }

    &ControlBlock {
        display: flex;
        gap: 24px;
    }
}


.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.errorContainer {
    width: 424px;
    height: 104px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 66px;
    padding-left: 32px;
    position: relative;
    background: #FDEBEB;
    border-radius: 6px;

    p {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #F36869;
    }

    button {
        background: none;
        padding: 0;
        position: absolute;
        top: 37px;
        right: 37px;
    }
}

.success {
    width: 315px;
    height: 70px;
    display: flex;
    flex-flow: row nowrap;
    gap: 16px;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 0;
    position: relative;

    p {
        color: #787E93;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 0;
    }

    button {
        position: absolute;
        right: 10px;
        top: 5px;
    }
}