.campaignComparisonResultName {
  &_root {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: stretch;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 999;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    background: rgba(29, 29, 27, 0.5);
    overflow-y: auto;
  }
}

.modalWindow {
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  min-width: 400px;
  max-width: 600px;
  min-height: 200px;
  max-height: 600px;
  margin: 160px 0;
  padding: 26px;
  background: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  opacity: 1;
  border-radius: 6px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 18px;

  &_contentBox {
    max-height: 400px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &_title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #787E93;
    width: 100%;
    border-bottom: 1px solid #787E93;
    padding-bottom: 15px;
  }
}

.flexColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.flexRow {
  display: flex;
  width: 100%;
  height: 100%;
}

.modalCrossWrapper {
  display: flex;
  width: 60px;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 16px;
}

.inputBox {
  display: flex;
  padding: 0;
  gap: 8px;
  width: 100%;
  background: white;
  border-bottom: 1px solid #D6DDE8;
}

.input {
  width: 440px;
  height: 27px;
  background: white;
  border: none;
  outline: none;

  :global {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
    }
  }

  &_text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #262626;
  }
}