.root_iconButton {
    position: relative;
    border-radius: 100%;
    width: fit-content;
    height: fit-content;
    background-color: #EDFFFC;
    fill: #0EBFA1;
    transition: all 0.3s ease;
    &.isLoad{
        background-color: rgba(0,0,0,0);
        fill: rgba(0,0,0,0);
        transform: scale(0.75);
    }
}

.iconButton{
    position: relative;
    cursor: pointer;
    height: 32px;
    width: 32px;
}
.icn {
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
}
.loadingContainer {
  position: relative;
  width: 32px;
  height: 32px;
}
.loading {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 34px;
    width: 34px;
}
.loading span {
    display: block;
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    margin: auto;
    height: 34px;
    width: 34px;
}
.loading span::before {
    content: "";
    display: block;
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    margin: auto;
    height: 34px;
    width: 34px;
    border: 3px solid #0EBFA1;
    border-bottom: 3px solid transparent;
    border-radius: 50%;
    -webkit-animation: circle 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
            animation: circle 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes circle {
    0% {
        -webkit-transform: rotate(0deg);
    }
    40% {
        -webkit-transform: rotate(180deg);
    }
    50% {
        border-bottom: 3px solid #0EBFA1;
    }
    60% {
        -webkit-transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes circle {
    0%   {
        transform: rotate(0deg);
    }
    40%  {
        transform: rotate(180deg);
    }
    50% {
        border-bottom: 3px solid #0EBFA1;
    }
    60%  {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loading span::after {
    content: "";
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    margin: auto;
    width: 6px;
    height: 6px;
    background: #0EBFA1;
    border-radius: 50%;
    -webkit-animation: ball 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
            animation: ball 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes ball {
    0%   { -webkit-transform: translate3d(0, -32px, 0) scale(0, 2); opacity: 0; }
    50%  { -webkit-transform: translate3d(0, 0, 0) scale(1.25, 1.25); opacity: 1; }
    100% { -webkit-transform: translate3d(0, 8px, 0) scale(0, 0); opacity: 0; }
}
@keyframes ball {
    0%   { transform: translate3d(0, -32px, 0) scale(0, 2); opacity: 0; }
    50%  { transform: translate3d(0, 0, 0) scale(1.25, 1.25); opacity: 1; }
    100% { transform: translate3d(0, 8px, 0) scale(0, 0); opacity: 0; }
}