.root_footerFilters {
  position: relative;
  margin-left: 16px;
}

.input_filterName {
  border: none;
  margin-bottom: 12px;
  background: #FFFFFF;
  border-bottom: 1px solid #D6DDE8;
  width: 255px;
  transition: all 0.2s ease;
  &:focus-visible {
    border: none;
    border-bottom: 1px solid #0EBFA1;
    outline: none
  }
  &::placeholder {
    font-family: 'Montserrat', sans-serif;
    color: #787E93
  }
}
.errorInput{
  border-bottom: 1px solid #F36869;

}
.msg {
  color: #F36869;
  font-size: 12px;
  margin-bottom: 10px;
  transition: all 0.3s ease;
  max-height: 0px;
  overflow: hidden;

}

.show {
  max-height: 80px;
}