.contactsCustomer {
  &_root {
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    gap: 16px;
  }

  &_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_actions {
      fill: #787E93;
      display: flex;
      flex-flow: row nowrap;
      gap: 12px;
    }
  }

  &_title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
  }

  &_btnPen {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &_btnPen.invisible {
    visibility: hidden;
  }

  &_btnSave {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    width: 114px;
    height: 48px;
    color: #FFFFFF;
    background: #0EBFA1;
    border-radius: 6px;
  }

  &_btnContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
  }

  &_controller {
    border-radius: 100%;
    height: 31px;
    width: 31px;
    background-color: #F8FBFF;
    fill: #787E93;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.5s ease;
    transform: rotate(0deg);
    &.open {
      transition: all 0.5s ease;
      transform: rotate(180deg);
    }

    &:hover {
      background-color: #ecf1f9;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &_title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
  }

  &_row {
    display: flex;
    gap: 24px;
  }

  &_cell {
    display: flex;
    flex-direction: column;
  }

  &_label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    color: #787E93;
  }

  &_inputName {
    width: 369px;
  }

  &_inputAddress {
    width: 500px;
  }

  &_inputPhone {
    width: 240px;
  }

  &_inputEmail {
    width: 500px;
  }
}

input {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  padding: 0 12px;
}

input:focus {
  outline: none;
}

@keyframes bounce {
  0% {
    transform: translateY(0) rotate(0deg) scale(1);
    fill: #787E93;
  }
  10% {
    transform: translateY(-10px) rotate(20deg) scale(1.15);
    fill:#0EBFA1;
  }
  20% {
    transform: translateY(-20px) rotate(-20deg) scale(1.15);
    fill:#0EBFA1;
  }
  30% {
    transform: translateY(-20px) rotate(8deg) scale(1.12);
    fill:#0EBFA1;
  }
  40% {
    transform: translateY(-20px) rotate(-8deg) scale(1.1);
    fill:#0EBFA1;
  }
  50% {
    transform: translateY(-20px) rotate(4deg) scale(1.1);
    fill:#0EBFA1;
  }
  60% {
    transform: translateY(-20px) rotate(-4deg) scale(1.1);
    fill:#0EBFA1;
  }
  70% {
    transform: translateY(-20px) rotate(2deg) scale(1.1);
    fill:#0EBFA1;
  }
  80% {
    transform: translateY(-20px) rotate(-2deg) scale(1.1);
    fill:#0EBFA1;
  }
  90% {
    transform: translateY(0px) rotate(1deg) scale(1);
  }
  100% {
    fill: #787E93;
    transform: rotate(0deg);
  }
}

.animation {
  animation: bounce 1.3s infinite;
}