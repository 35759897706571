@import '~antd/dist/antd.css';

html {
  --greyShaft: #262626;
  --midnight: #001529;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  min-height: 100vh;

  overflow: hidden;
}

#root{
  height: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

button {
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
}

::-webkit-scrollbar-thumb {
  background: #D6DDE8;
  border-radius: 3px;
  width: 28px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: transparent;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 7px;
  height: 5px;
  cursor: pointer;
}

.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon {
  color: #fff;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: none;
}

.ant-page-header {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.ant-tabs-bottom {
  padding-right: 24px;
}

.ant-layout {
  background-color: transparent;
}

.ant-list-vertical .ant-list-item-meta-title {
  display: flex;
  margin-bottom: 0;
}

.ant-page-header-ghost {
  background-color: transparent;
}

.ant-checkbox .ant-checkbox-inner {
  width: 16px;
  height: 16px;
  border: 2px solid #D2D5DA;
  background-color: white;
  border-radius: 4px;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  width: 16px;
  height: 16px;
  background-color: #E1E4E7;
  border-color: #EDEDED;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
  width: 16px;
  height: 16px;
  background-color: #E1E4E7;
  border-color: #EDEDED;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #EDEDED;
}

.ant-checkbox-checked .ant-checkbox-inner {
  width: 16px;
  height: 16px;
  background-color: #0EBFA1;
  border: none;
}

.ant-switch.ant-switch-checked {
  background-color: #0EBFA1;
}

input:focus:not(:read-only)::placeholder {
  color: transparent;
}