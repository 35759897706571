.chartListCampaingReport{
  margin-top: 16px;
  max-width: 676px;
  overflow: hidden;
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  width: 676px;
}