.tooltipsDemo_root {
  height: 100%;
  width: 100%;
  padding: 32px;
  overflow: hidden;

  background-color: #f3f3f3;
}

.block {
  margin-bottom: 16px;
}

.label {
  width: fit-content;
  padding: 8px;
  background-color: white;
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: 8px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &_big {
    font-size: 18px;
  }
  &_mid {
    font-size: 14px;
  }
  &_small {
    font-size: 10px;
  }
}