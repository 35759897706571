.containerCalendar {
    &Wrapper {
        position: absolute;
        z-index: 12;
        top: 36px;
        right: 0;
    }
}

.currentPeriod {
    position: relative;
    box-sizing: border-box;
    text-transform: none;
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 6px;
    background: #e8f1f9;

    & span {
        margin-left: 8px;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 135%;
        color: #5C606D;
    }
    & svg {
        cursor: pointer;
    }
    & .icn {
        height: 16px;
        user-select: none;
        fill: var(--var-blue-default-primary);
        cursor: pointer;
        & svg {
            stroke: var(--var-blue-default-primary);
        }
        &.prev {
            transform: rotate(180deg);
        }
    }
}

.wrapperHide {
    display: none;
}
