.wrapper {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: auto;
}

.label {
    color: #787e93;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 4px;

    &::first-letter {
        text-transform: uppercase;
    }
}

.container {
    position: relative;
    border: 1px solid rgba(222, 225, 231, 0.5);
    border-radius: 6px;
    overflow: hidden;

    &[data-disabled="true"] {
        background: rgba(225, 228, 231, 0.3);
        .textarea {
            border: none;
            pointer-events: none;
            background: rgba(225, 228, 231, 0.3);
            color: rgba(120, 126, 147, 0.3);
        }

        & .counter {
            padding: 1px;
            position: absolute;
            right: 16px;
            bottom: 12px;
            width: auto;
            background: none;
        }
    }

    &[data-readonly="true"] {
        .textarea {
            pointer-events: none;
            border: none;
        }
    }

    &[data-focus="true"] {
        border: 1px solid rgba(120, 126, 147, 0.3);

        .textarea {
            box-shadow: none;
            outline: none;
        }

        .counter {
            color: #262626;
        }
    }

    & .textarea {
        border: none;
    }
}

.textarea {
    padding: 12px;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    color: #000;
    background: #fff;
    resize: none;
    width: 100%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    transition: height 0.2s;
    height: auto;
    overflow: hidden;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;

    &WithScroll {
        overflow-y: scroll;
    }

    &Wrapper {
        width: 100%;
        position: relative;
    }
}

.counter {
    color: #787e93;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    background: #fff;
    padding-right: 16px;
    padding-bottom: 12px;
    padding-top: 12px;
    background: #fff;
    display: flex;
    justify-content: flex-end;

    &Max {
        color: #f36869;
    }
}
