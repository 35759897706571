.typePlots {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
.checkBoxType {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.iconTypes {
  margin-left: 6px;
}
.listTypes {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.sourcesTypes {
  display: flex;
  align-items: center;
}
.filterPlotContainer {
  display: flex;
  margin-bottom: 12px;
}
.iconAltPlot {
  width: 28px;
  height: 28px;
  border: 2px solid #E67E22;
  background: #FEF8E8;
  border-radius: 100%;
}
.iconMainPlot {
  width: 28px;
  height: 28px;
  border: 2px solid #0EBFA1;
  background: #DFFCF7;
  border-radius: 100%;
}
.containerSlider {
  max-width: 285px;
  align-items: center;
  display: grid;
  grid-template-columns: 40px 80% 40px
}
.matchingThemesContainer {
  position: relative;
  width: 100%;
}
.iconFaq {
  margin-right: 4px;
  cursor: pointer;
}
.matchingThemesLabel {
  color: #787E93
}
.matchingThemesMark {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #262626;
  &:last-child {
    margin-left: 4px;
  }
}