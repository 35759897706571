.wrapper {
	background: rgba(29, 29, 27, 0.5);
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 999;
}
.content {
	display: flex;
	position: fixed;
	width: 600px;
	background: #fff;
	z-index: 999;
	padding: 24px 32px 24px 32px;
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
	border-radius: 16px;

	&Title {
		color: #262626;
		font-family: "Montserrat", sans-serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px;
		margin-bottom: 32px;
	}

	&Text {
		color: #262626;
		font-family: "Montserrat", sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		margin-bottom: 16px;
	}
}

.list {
	height: 360px;
	overflow: auto;
	display: flex;
	flex-flow: column nowrap;

	&Element {
		display: flex;
		flex-flow: row nowrap;
		padding: 12px 0;
		gap: 8px;

		span {
			color: #262626;
			font-family: "Montserrat", sans-serif;
			font-size: 16px;
			font-weight: 500;
		}
	}
}

.footer {
	display: flex;
	justify-content: flex-end;
	padding-top: 16px;

	&Buttons {
		display: flex;
		flex-flow: row nowrap;
		gap: 16px;
	}
}

.buttonClose {
	display: flex;
	position: absolute;
	right: 34px;
	top: 26px;
}