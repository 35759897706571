.campaignReportsList {
    &_root {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px;
        gap: 16px;
        max-width: 676px;
        min-width: 400px;
        width: 100%;
        min-height: 280px;
        height: auto;
        background: white;
        border-radius: 6px;
    }
}

.flexColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.flexRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.gap20 {
    gap: 20px;
}

.gap16 {
    gap: 16px;
}

.gap8 {
    gap: 8px;
}

.header {
    display: flex;
    align-items: center;
    height: 40px;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #0EBFA1;
}

.headerHl {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #D6DDE8;
}

.buttonControl {
    padding: 0;
    background: none;
}

.delAllItems {
    min-width: 168px;
    height: 30px;
    padding: 0;
    background: none;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #787E93;
}

.dateLabel {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #787E93;
}

.reportItemContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    gap: 12px;
    width: 100%;
    height: auto;
    border: 1px solid #D6DDE8;
    border-radius: 6px;

    &_reportName {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #262626;
        width: 100%;
        max-width: 100%;
        word-wrap: break-word;
        word-break: break-all;
    }

    &_reportControls {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 32px;
        min-width: 100px;
    }
}

.loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 160px;
}

.format {
    margin-left: 8px;
    min-width: 48px;
}

@media screen and (max-width: 1200px) {
    .campaignReportsList {
        &_root {
            margin-left: 24px;
            max-width: 550px;
        }
    }
}