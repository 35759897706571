.statusInfo {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: 'Montserrat', sans-serif;
  width: 320px;
  height: 108px;

  &_head {
    display: flex;
    align-items: center;
  }

  &_title {
    width: 80px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    color: #787E93;
    padding: 6px;
    background: #EDFFFC;
    border-radius: 6px;
  }

  &_textStatus {
    width: 120px;
    max-width: 120px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    margin-left: 18px;
  }

  &_btnCross {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    margin-left: 21px;
    cursor: pointer;
  }
}