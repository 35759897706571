.root_CurrentDocuments {
    position: relative;
}

.title {
    color: #262626;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 16px;
}

.content {
    display: flex;
    align-items: center;
}

.titleTemplates {
    margin-top: 16px;
    background-color: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    color: #787E93;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    margin-bottom: 16px;
    span {
        margin-right: 10px;
    }
    svg {
        fill: #0EBFA1;     
        transition: all 0.3s;
    }
    &.open {
        svg {
            transform: rotate(180deg);        
        }
    }
}

.hideCollapse {max-height: 0px !important;}

.templateTitle {
    color: #787E93;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
}