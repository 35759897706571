.milestonesBlock {
  display: flex;
  font-family: 'Montserrat', sans-serif;

  &_list {
    display: flex;
    gap: 24px;
    position: relative;
  }

  &_inputRadio {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 6px;
    position: relative;
    height: 50px;
  }

  &_radioWrapper {
    display: flex;
  }

  &_radio {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 24px;
    height: 24px;
    background: #FFFFFF;
    border-radius: 50%;
    border: 1px solid #DEE1E7;
    cursor: pointer;
    z-index: 20;
  }

  &_circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #787E93;
    z-index: 20;
  }

  &_circleAtWork {
    position: absolute;
    top: calc(50% - 3px);
    left: calc(50% - 3px);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #DEE1E7;
    z-index: 21;
  }

  &_circleSuccess {
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #DEE1E7;
    z-index: 22;
  }

  //&_circleSuccess:hover {
  //  position: absolute;
  //  top: calc(50% - 15px);
  //  left: calc(50% - 15px);
  //  width: 30px;
  //  height: 30px;
  //  border-radius: 50%;
  //  background: transparent;
  //  border: 1px solid #0EBFA1;
  //  z-index: 22;
  //  transition: all 0.1s;
  //}

  &_imgSuccess {
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    width: 24px;
    height: 24px;
  }

  &_title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
  }

  &_line {
    position: absolute;
    top: 20%;
    left: 5%;
    width: 470px;
    height: 1px;
    border: 1px solid #DEE1E7;
    z-index: 10;
  }

  &_lineSuccess {
    position: absolute;
    top: 20%;
    left: 5%;
    height: 0px;
    border: 1px solid transparent;
    border-image: linear-gradient(20deg, #0EBFA1 75%, #FFFFFF 98%);
    -moz-border-image: -moz-linear-gradient(20deg, #0EBFA1 75%, #FFFFFF 98%);
    -webkit-border-image: -webkit-linear-gradient(20deg, #0EBFA1 75%, #FFFFFF 98%);
    border-image-slice: 1;
    z-index: 12;
  }

  &_lineSuccessWidth70 {
    width: 70px;
  }

  &_lineSuccessWidth182 {
    width: 182px;
  }

  &_lineSuccessWidth320 {
    width: 320px;
  }

  &_lineSuccessWidth470 {
    width: 470px;
  }
}