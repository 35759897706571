.root {
    display: flex;
    gap: 8px;
}

.outerCircle {
    width: 20px;
    height: 20px;
    background: none;
    border: 1px solid #0EBFA1;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.outerCircle.disabled {
    cursor: no-drop;
}

.circle {
    width: 10px;
    height: 10px;
    background-color: #0EBFA1;
    border-radius: 5px;
}

.circle.disabled {
    visibility: hidden;
}

.labelButton {
    background: none;
    padding: 0;
}

.labelButton.disabled {
    cursor: no-drop;
}

.text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #1D1D1B;
}