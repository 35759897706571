.chartWrapper {
    max-height: 42px;
    overflow: hidden;
    background-color: white;
    border-radius: 6px;
    border: 1px solid white;
    transition: all 0.3s ease;
    padding-bottom: 12px;
    &.isOpen {
        max-height: 100vh;
    }
    &:hover {
        border: 1px solid #0EBFA1;
    }
    &:not(:last-child) {
        margin-bottom: 12px;
    }
}

.wrapperHeader {
    padding: 10px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}