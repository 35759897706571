.longlist_tooltip {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.headerPlanValues {
  display: flex;
  align-items: center;
  background-color: #EDFFFC;
  min-width: 150px;
  box-sizing: border-box;
  height: 44px;
  padding: 0 12px;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.headerAvgValues {
  display: flex;
  align-items: center;
  background-color: white;
  min-width: 150px;
  box-sizing: border-box;
  height: 44px;
  padding: 0 12px;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.icon_layout {
  margin-top: 2px;
  stroke: rgba(120, 126, 147, 0.3);
  fill: rgba(120, 126, 147, 0.3);
  &:hover {
    cursor: pointer;
  }
}