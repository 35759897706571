.deleteModal {
  &_root {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: stretch;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 999;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    background: rgba(29, 29, 27, 0.5);
    overflow-y: auto;
  }

  &_window {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    margin-top: 10%;
    min-height: 150px;
    height: fit-content;
    background: #FFFFFF;
    border-radius: 16px;
    justify-content: space-between;
    &.short {
      width: 283px
    }
  }

  &_container {
    display: flex;
    flex-direction: column;
    margin: 24px 32px 16px 32px;
    height: 100%;

    &_content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin: 16px 0;
    }

    &_header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }

    &_title {
      color: #262626;
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }

    &_textContent {
      color: #262626;
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 135%;
    }

    &_controls {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
      margin-top: 16px;
    }
  }
}

.textArea {
  width: 512px;
  margin-top: 10px;
}