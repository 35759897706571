.table {
    display: flex;
    flex-flow: column nowrap;
}

.header {
    display: grid;
    grid-template-columns: minmax(200px, 350px) minmax(100px, 150px) minmax(100px, 150px) minmax(100px, 150px);
    column-gap: 10px;
    justify-content: space-between;
    background: #E8F1F9;
    padding: 20px 0;

    span {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #787E93;

        &:first-child {
            padding-left: 32px;
        }
    }
}

.body {
    display: flex;
    flex-flow: column nowrap;
}