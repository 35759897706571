.tooltip {
  cursor: pointer;
}

.tooltip::after {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;

  width: 160px;

  margin-top: 10px;

  background: #FFFFFF;
  border-radius: 6px;


  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  content: attr(data-tooltip);
  opacity: 0;

  position: absolute;
  visibility: hidden;

  transition: all 0.4s ease-in-out;


  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;

  color: #262626;
}

.tooltip:hover {
  position: relative;
}

.tooltip:hover::after {
  opacity: 1;
  visibility: visible;
}

.userName {
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
  text-overflow: ellipsis;
}