.button {
    display: flex;
    gap: 10px;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    color: #787e93;
    align-items: center;
    width: 100%;
    text-align: left;
}

.modal {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(29, 29, 27, 0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;

    &Container {
        width: 600px;
        background: #fff;
        padding: 24px 32px 32px 32px;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        align-items: center;
        gap: 16px;
    }

    &Title {
        color: #262626;
        font-family: "Montserrat", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        width: 100%;
        margin-bottom: 16px;
    }

    &Buttons {
        display: flex;
        gap: 16px;
        justify-content: flex-end;
        margin-top: 8px;
        width: 100%;
    }
}

.successMessage {
    padding: 6.5px 12px;
    border-radius: 6px;
    background: #fff;
    display: flex;
    gap: 8px;
	align-items: center;

    p {
        color: #0ebfa1;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 135%;
		margin-bottom: 0;
    }
}

.textFieldSource {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;

    & > button {
        align-self: flex-end;
        margin-bottom: 6px;
    }
}