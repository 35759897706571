.campaignCreateForm {
  &_root {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 16px;
  }
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 32px;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
}

.subRoot {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  gap: 16px;
}

.scrollWrapper {
  overflow-y: auto;
}

.bottomContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
}

.pinnedInfo {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.label {
  display: flex;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #787e93;
}

.label.small {
  font-size: 12px;
  line-height: 20px;
}

.label.middle {
  font-size: 14px;
  line-height: 140%;
}

.buttonsContainer {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 16px;
}

.titleBlock {
  display: flex;
  align-items: center;
  width: 161px;
  min-height: 42px;
  padding: 0 10px;
  background: #dffcf7;
  border-radius: 6px;

  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 131.5%;
  color: #000000;
}

.flexRow {
  display: flex;
  gap: 16px;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #787e93;
}

.switchContainer {
  display: flex;
  gap: 16px;
  margin-top: -4px;
}

.wordsWrapper {
  display: flex;
  flex-direction: column;
  max-width: 1016px;
  gap: 18px;
  padding: 16px;
  background: white;
  border-radius: 6px;
}

.searchRequest {
  &_container {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &_btn {
    padding: 0;
    background: none;
    
    &Circle {
      border-radius: 16px;
      background-color: #dffcf7;
      padding-bottom: 8px;
      padding-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
    }
  }

  &_text {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    color: #0ebfa1;
  }
}

.checkboxText {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: black;
}

.postPreview {
  &_controlsBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    margin-bottom: 16px;

    &_hint {
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: #787e93;
    }
  }

  &_text {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    color: #129fd4;
  }

  &_disabled {
    color: #787e93;
  }

  &_list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &_titleText {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: black;
  }

  &_authorName {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #129fd4;
  }

  &_postLink {
    @extend .postPreview_authorName;
  }

  &_dateText {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: #787e93;
  }

  &_dateFooter {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #787e93;
  }

  &_contentText {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    color: #262626;
  }

  &_box {
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 12px;
    width: 843px;
    background: white;
    border-radius: 6px;

    &_header {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    &_content {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &_footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &_headerSubBox {
      display: flex;
      gap: 8px;
    }
  }
}

.searchRadioBtn {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  color: #7c848b;
}
