.sourcesFilter {
  position: absolute;
  top: 0;
  right: -70%;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  color: #7C848B;
  width: 170px;
  height: 100px;
  padding: 12px;
  background: #FFFFFF;

  &_row {
    display: flex;
    align-items: center;
    color: black;
    margin-top: 10px;
  }

  &_img {
    margin-right: 8px;
    padding-bottom: 2px;
  }

  &_symbol {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    text-decoration-line: underline;
    margin: 0 5px;
  }

  &_symbol:hover {
    color: #0EBFA1;
  }
}