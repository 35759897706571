.wrapper {
    display: flex;
	flex-flow: column nowrap;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sort {
    display: flex;
    align-items: center;
    gap: 15px;
    
    &Options {
        display: flex;
        gap: 16px;
        flex-flow: row nowrap;
    
        button {
            background: #ffffff;
            border-radius: 6px;
            padding: 7px 10px;
            font-family: "Montserrat", sans-serif;
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            color: #000000;
    
            &[data-active="true"] {
                background: #E8F1F9;
                font-weight: 600;
            }
        }
    }
}

.list {
	display: flex;
	flex-flow: column nowrap;
	gap: 16px;
	margin-top: 16px;
}