.dropDownCategory {
  width: 1073px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: 20px;

  &_lineStart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    margin: auto;
    background: #F8FBFF;;
  }

  &_name {
    display: flex;
    align-items: center;
    width: 220px;
    height: 100%;
    font-weight: 600;
    font-size: 16px;
    background: #E8F1F9;;
    color: gray;
    border-radius: 6px;
    padding: 10px;
  }

  &_arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    width: 3%;
    background: #E8F1F9;;
    color: gray;
    border-radius: 2px;
    padding: 13px 11px;
    cursor: pointer;

    div {
      height: 12px;
      display: flex;
      align-items: center;
    }
  }

  &_block {
    display: flex;
    gap: 84px;
  }

  &_rowHeader {
    display: flex;
    align-items: flex-start;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #615E83;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 18px;
  }

  &_imgBack {
    margin-top: 20px;
    width: 15px;
    height: 9px;
    transform: rotate(270deg);
  }

  &_imgForward {
    margin-top: 20px;
    width: 15px;
    height: 9px;
    transform: rotate(90deg);
  }

  &_slider {
    display: flex;
    align-items: center;
    width: 780px;
    overflow: hidden;
    padding: 0 5px;

  }

  &_slide {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 13px;
    font-size: 12px;
    max-width: 150px;
    min-width: 150px;
    max-height: 46px;
    min-height: 46px;
    padding: 5px 10px;
    margin-right: 8px;
    background: #F8FBFF;
    border-radius: 6px;
    transition: 0.5s;
  }

  &_slide.leftBlur {
    background: linear-gradient(to right, transparent, #F8FBFF 150px, #F8FBFF calc(100% - 100px));
  }

  &_slide.rightBlur {
    background: linear-gradient(to left, transparent, #F8FBFF 150px, #F8FBFF calc(100% - 100px));
  }

  &_text {
    color: #787E93;
  }

  &_text.leftTextBlur {
    background: linear-gradient(to right, white 0%, #787E93 15%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #787E93;
  }

  &_text.rightTextBlur {
    background: linear-gradient(to left, white 25%, #787E93 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #787E93;
  }

  &_info {
    display: flex;
    margin-top: 20px;
    max-width: 150px;
    min-width: 150px;
    transition: 0.5s;
  }

  &_quantity {
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
  }

  &_percent {
    margin-left: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  &_list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 81px;
  }

  &_cell {
    display: flex;
    align-items: center;
    height: 38px;
  }

  &_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    color: black;
    margin-left: 34px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 180px;
  }


}