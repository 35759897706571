.resultsComplete {
  &_root {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: stretch;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 999;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    background: rgba(29, 29, 27, 0.5);
    overflow-y: auto;
  }

  &_warning {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(254, 248, 232, 1);
    padding: 10px;
    border-radius: 6px;
    width: 100%;
    & span {
      margin-left: 12px;
    }
  }

  &_window {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    margin-top: 10%;
    width: 700px;
    min-height: 150px;
    height: fit-content;
    background: #FFFFFF;
    border-radius: 6px;
    justify-content: space-between;
    &.short {
      width: 283px
    }
    &.adaptiveSize {
      width: fit-content;
    }
  }

  &_container {
    display: flex;
    flex-direction: column;
    margin: 24px 32px;
    gap: 16px;
    height: 100%;

    &_content {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &_header {
      display: flex;
      justify-content: space-between;
    }

    &_warning {
      display: flex;
      justify-content: center;
    }
  }

  &_controls {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-top: 16px;
    &_right {
      display: flex;
      align-items: center;
      & button{
        &:first-child {
          margin-left: 16px;
        }
      }
    }
    &.short {
      justify-content: center;
      align-items: center;
    }
  }

  &_title {
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: #262626;
  }

  &_textContent {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    color: #262626;
  }

  &_simpleTextContent {
    @extend .resultsComplete_textContent;
    line-height: 130%;
  }

  &_devider {
    border-bottom: 1px solid rgba(222, 225, 231, 0.50);
    background: #FFF;
  }
}

.modal {
  &_file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    height: 36px;
    box-sizing: border-box;
    padding-right: 8px;
    border-radius: 6px;
    border: 1px solid rgba(222, 225, 231, 0.50);
    &_controls {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & button {
        margin-left: 8px;
      }
    }
  }

  &_nameFile {
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
    color: black;
    width: 450px;
    height: 34px;
    border: none;
    border-radius: 6px;
  }

  &_nameFile::placeholder {
    color: black;
    opacity: 1;
  }

}