.blockInfo {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: 'Montserrat', sans-serif;

  &_row {
    display: flex;
    gap: 10px;
  }

  &_label {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 135%;
    color: #787E93;
  }

  &_text {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 131.5%;
  }
}