.campaingStatusToggle {
    display: flex;
    align-items: center;
  }
  .campaingStatusContainer {
    display: flex;
    align-items: center;
    padding: 12px;
    justify-content: center;
    background: #DFFCF7;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .campaingStatusLabel {
    white-space: nowrap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #787E93;
    margin-right: 8px;
  }
  .campaingStatus {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #0EBFA1;
    margin-right: 8px;
  }
  .campaingStatusContainerDisabled {
    display: flex;
    align-items: center;
    padding: 12px;
    justify-content: center;
    background: #FDEBEB;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    :global {
      .ant-switch-small {
        background: #F36869;
      }
    }
  }
  .campaingStatusDisabled {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #F36869;
    margin-right: 8px;
  }