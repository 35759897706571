.seporatorLine {
  width: calc(100% - 32px);
  margin-left: 16px;
  height: 1px;
  background-color: #D6DDE8;
}
.customPanel{
  :global {
    .ant-collapse-header-text {
      font-family: 'Montserrat';
      white-space: nowrap;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: #787E93;
      user-select: none;
    }
    .ant-collapse-expand-icon {
      color: #787E93;
    }
  }
  // &.smallPanel {
  //   :global {
  //         .ant-collapse-header {
  //           padding: 5px 2px
  //         }

  //     }
  // }
}

.emptyFilters {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 15px;
  font-weight: bold;
  color: #787e93;

  margin-top: -10px
}

.moreController {
  color: #787E93;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  user-select: none;
}
.buttonHide {
  position: absolute;
  left: auto;
}
.containerControllers {
  display: flex;
  justify-content: space-between;
}

.important {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
}

.titile_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customControls {
  color: #0EBFA1;
  font-weight: 400;
  font-size: 32px;
  line-height: 2px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.filterRadio {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #DFFCF780;
  padding: 8px;
  margin-bottom: 12px;
  span {
    font-size: 12px;
  }
}