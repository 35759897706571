.platformFilter {
  position: absolute;
  top: 55px;
  left: 12px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  color: black;
  width: 180px;
  min-height: 150px;
  max-height: 400px;
  padding: 12px;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  z-index: 100;

  &_headCheckBox {
    border-bottom: 2px solid rgba(222, 225, 231, 0.5);
    margin-bottom: 10px;
  }

  &_listIcon {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &_listPlatforms {
    display: flex;
    flex-direction: column;
  }

  &_lineIcon {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &_label {
    color: #000;
    font-size: 14px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
  }

  &_icon {
    display: flex;
    align-items: center;
    height: fit-content;
  }
}