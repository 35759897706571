.root_ModalInfo {
    padding: 32px;
    padding-bottom: 12px;
    position: relative;
    width: 100%;
    max-width: 760px;
    min-width: 540px;
    max-height: 750px;
    overflow: scroll;
    border-radius: 6px;
    background-color: #fff;
    z-index: 1001;
}

.constrols {
    position: absolute;
    top: 32px;
    right: 32px;
    display: flex;
    align-items: center;
    svg {
        margin-right: 16px;
        cursor: pointer;
    }
}

.item_label {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
    color: #787E93;
}

.title {
    font-weight: 500;
    font-size: 16px;
    color: #000;
    margin-top: 12px;
}

.item {
    &:not(:last-child) {
        margin-bottom: 16px;
    }
}

.content {
    display: flex;
    flex-wrap: wrap;
}

.contentItem {
    background: #F8FBFF;
    border-radius: 6px;
    margin-bottom: 12px;
    padding: 10px;
    &:not(:last-child) {
        margin-right: 12px;
    }
}

.footer_ModalChange {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
}


.input_newFilterName {
    border: none;
    margin-bottom: 0px;
    background: #FFFFFF;
    border-bottom: 1px solid #D6DDE8;
    width: 100%;
    padding-bottom: 4px;
    transition: all 0.2s ease;
    &:focus-visible {
      border: none;
      border-bottom: 1px solid #0EBFA1;
      outline: none
    }
    &::placeholder {
      font-family: 'Montserrat', sans-serif;
      color: #787E93
    }
}

.containerModal {
    position: fixed;
    left: 0;
    top: 0;
    width: calc(100% + 16px);
    height: calc(100% + 16px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.08);
}