.wrapper {
    display: flex;
	flex-flow: column nowrap;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.detailCardInfo {
    max-width: 100%;
    &_controls {
        display: flex;
        justify-content: flex-end;
        gap: 24px;
        padding: 16px 0px
    }
    &_content {
        width: fit-content;
        max-width: 100%;
        max-height: 750px;
        padding: 0px 16px 16px 0px;
        overflow: auto;
    }
}

.message {
    &_succes {
      margin-top: calc(100vh - 80px);
      :global {
        .ant-message-notice-content {
          background: #0EBFA1;
          border-radius: 8px;
          box-shadow: 0px 32px 32px -16px rgba(0, 0, 0, 0.08), 0px 4px 24px -16px rgba(0, 0, 0, 0.12);
          color: #FFF;
          font-family: Montserrat;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%; 
        }
      }
    }
    &_error {
      margin-top: calc(100vh - 80px);
      :global {
        .ant-message-notice-content {
          background: #F36869;
          border-radius: 8px;
          box-shadow: 0px 32px 32px -16px rgba(0, 0, 0, 0.08), 0px 4px 24px -16px rgba(0, 0, 0, 0.12);
          color: #FFF;
          font-family: Montserrat;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%; 
        }
      }
    }
  }