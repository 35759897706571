.root {
  display: flex;
}

.table {
  display: table;
  width: 100%;
  min-width: 400px;
  height: auto;
  box-sizing: border-box;
  border-collapse: collapse;
  empty-cells: show;
  table-layout: fixed;
}

.tableColGroup {
  display: table-column-group;
}

.tableCol {
  display: table-column;
}

.tableThead {
  display: table-header-group;
  position: sticky;
  top: 0;
}

.tableHeadTr {
  display: table-row;
  background: #FFFFFF;
  border-bottom: 1px solid #D6DDE8;
}

.tableTh {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  height: 60px;
  background: #E8F1F9;


  &:first-child {
    border-top-left-radius: 6px;
  }

  &:last-child {
    border-top-right-radius: 6px;
  }
}

.tableTbody {
  display: table-row-group;
  overflow-y: auto;
}

.headerColumn {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-left: 6px;
  cursor: default;

  &_filtering {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    gap: 12px;

    &:hover {
      height: 40px;
      background: #E8F1F9;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    }
  }
}

.headerColumn.notFiltering {
  padding-left: 16px;
}

.textContainer {
  display: flex;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #787E93;
}

.tableBodyWrapper {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.tableTbody {
  display: table-row-group;
  overflow-y: auto;
  border: 1px solid red;
}

.tableTr {
  display: table-row;
  background: #FFFFFF;

  border-bottom: 2px solid rgba(214, 221, 232, 0.5);
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;

  &:hover {
    border: 2px solid #00CC99;
  }

  &:hover .tableTd {
    border-top: 2px solid #00CC99;
  }
}

.tableTd {
  display: table-cell;
  padding: 10px 0;
  height: auto;
  vertical-align: middle;
}

.cellNameContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.cellName {
  display: flex;
  align-items: center;
  gap: 8px;
}

.cell {
  height: auto;
  //padding-left: 16px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 131.5%;
  color: #787E93;
}

.cell.filtered {
  padding-left: 26px;
}

.statusCell {
  display: flex;
  gap: 16px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 131.5%;

  &_active{
    color: #0EBFA1;
  }

  &_notActive {
    color: #E67E22;
  }
}

.actionCell {
  display: flex;
  align-items: center;
  min-width: 430px;
}

.controlBlock {
  display: flex;
  align-items: center;
  width: 80px;
  min-width: 80px;
  gap: 10px;
}

.noDataContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 131.5%;
  color: #000000;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 216px;
  min-height: 216px;
}

.controlButton {
  background: none;
  padding: 0;
}