.root_Sort {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.containerSorts {
    display: flex;
    align-items: center;
}

.containerSortItem {
    background: #FFFFFF;
    border-radius: 6px;
    font-size: 12px;
    line-height: 20px;
    padding: 5px 10px;
    cursor: pointer;
    user-select: none;
    &:not(:last-child) {
      margin-right: 14px;
    }
}

.isSelectSort {
    background: #E8F1F9;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }