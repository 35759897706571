.root {
    display: flex;
    justify-content: center;
    align-items: center;
}

.svgContainer {
    position: relative;
    animation: rotate 2s linear infinite;
    width: 70px;
    height: 70px;

    &.large {
        width: 51px;
        height: 51px;
    }

    &.medium {
        width: 21px;
        height: 21px;
    }

    &.small {
        width: 12px;
        height: 12px;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.circleContainer {
    width: 100%;
    height: 100%;
    fill: none;

    stroke-width: 2;
    stroke: #0EBFA1;
    stroke-linecap: round;
    transform: translate(5px, 5px);

    stroke-dasharray: 220;
    stroke-dashoffset: 220;
    animation: animate 4s linear infinite;

    &.large {
        stroke-width: 2;
    }

    &.medium {
        stroke-width: 1;
    }

    &.small {
        stroke-width: 0.5;
    }
}


@keyframes animate {
    0%, 100% {
        stroke-dashoffset: 220;
    }
    50% {
        stroke-dashoffset: 0;
    }
    50.1% {
        stroke-dashoffset: 440;
    }
}
