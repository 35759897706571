.dropdownTitile {
  color: #787E93;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  margin-left: 12px;
};
.dropdownChartSettings {
  width: fit-content;
  margin-left: 12px;
  margin-top: 12px;
  margin-bottom: 8px;
}