.dateSlider {
  &_root {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 30px;
    padding-left: 6px;
  }
}

.tickLabels {
  &_root {
    position: absolute;
    top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &_row {
    display: flex;
    align-items: center;
  }
}

.tick {
  height: 4px;
  border-left: 1px solid rgba(120, 126, 147, 0.2);

  &_label {
    display: flex;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    color: #787E93;
  }
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}