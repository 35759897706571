.content_root {
    position: relative;
    padding: 8px;
}

.icnContainer {
    position: absolute;
    right: 0px;
    top: -4px;
    fill: #787E93;
    cursor: pointer;
    transition: transform 0.3s;
    user-select: none;
    &:hover {
        transform: scale(1.15);
    }
    &:active {
        transform: scale(0.95);
    }
}