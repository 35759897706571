.root_DetailInfo {
    display: flex;
    align-items: center;
}

.container {
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    color: #787E93;
    position: relative;
    &:not(:last-child) {
        margin-right: 16px;
    }
}
.info {
    display: flex;
}
.offset {
    margin-left: 4px;
    margin-bottom: 2px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 133%;
    &.plus {
        color: #0EBFA1;
    }
    &.minus {
        color: #F36869;
    }
}

.label {
    margin-top: 2px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}