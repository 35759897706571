.authorCell {
  position: relative;
  &_label {
    color: #3B3D47;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
}