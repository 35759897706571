.results {
  &_root {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &_header {
    display: flex;
    gap: 24px;

    &_title {
      color: #262626;
      font-size: 20px;
      font-family: Montserrat, sans-serif;
      font-weight: 600;
      line-height: 20px;
    }

    &_saveSearch {
      height: 21px;
      color: #129FD4;
      font-size: 14px;
      font-family: Montserrat, sans-serif;
      font-weight: 600;
      line-height: 20px;
      border-bottom: 1px solid #129FD4;
      cursor: pointer;

    }
  }

  &_footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 32px;
    gap: 24px;
  }

  &_tableWrapper {
    width: 100%;
    overflow-x: auto ;
    display: flex;
    justify-content: flex-start;
  }

  &_table {
    width: fit-content;
    & .rowCell {
      min-height: 84px;
    }
  }

}

.subscriberGrowthCell {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.grouwthItem {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  & div {
    box-sizing: border-box;
    padding-left: 5px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.grouwthTime {
  color: #3B3D47;
}

.grouwthValue {
  color: #0EBFA1;
}

.grouwthPercent {
  color: rgba(120, 126, 147, 0.7);
}