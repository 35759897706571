.resultAnalytics {
  width: 1141px;
  background: white;
  padding: 24px;

  &_introInfo {
    color: #787E93;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50%;
    margin-bottom: 16px;
  }

  &_orderPeriod {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
  }

  &_referenceCampaign {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #787E93;
  }
  &_listDropDown{
  }
  &_listDropDown > *{
    margin-bottom: 16px;
  }
}

.campaignComparisonResultSkeleton {
  display: flex;
  flex-direction: column;
  gap: 36px;
  width: 1141px;
  background: white;
  padding: 24px;
  border-radius: 6px;

  &_cell {
    display: flex;
    flex-direction: column;
    gap: 12px
  }
}


@media screen and (max-width: 1200px) {
  .resultAnalytics {
    width: 100%;
  }
  .campaignComparisonResultSkeleton {
    width: 100%;
  }
}