.rootHiddenChartItem {
  border: 1px solid #ffffff;
  cursor: pointer;
  background-color: #fff;
  margin-top: 12px;
  width: 100%;
  border-radius: 6px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 131.5%;
  user-select: none;
  color: #787E93;
  .left {
    div {margin-left: 24px};
  }
  .right {
    margin-top: 6px;
    div {
      &:last-child {
        margin-left: 12px;
        margin-right: 24px;
      }
    }
  }
  
}
.defaultContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.rootChartItem {
  position: relative;
  border: 1px solid #ffffff;
  user-select: none;
  background-color: #fff;
  margin-top: 12px;
  width: 100%;
  border-radius: 6px;
  padding: 16px 24px;
  .header {
    justify-content: space-between;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    span {
      margin-right: 10px;
    }
    .chartTitle {
      svg {
        margin-top: 6px;
      }
    }
  }
}

.chartHeaderControls {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  color: #787E93;
  
}

.chartSave {
  margin-right: 14px;
}

.chartDelete {
  svg {
    margin-top: 2px;
  }
}

.rootSelect {
  border: 1px solid #0EBFA1

}
.newChartContainer {
  flex-shrink: 1;
  position: relative;
  width: 100%;
}
.listCheckBoxes {
  position: relative;
  flex-shrink: 0;
  max-height: 350px;
  overflow: scroll;

}
.checkBoxItem {
  max-width: 140px;
  display: flex;
  align-items: center;
  margin-top: 12px;
  .label {
    margin-left: 8px;
    max-width: 110px;
    white-space: wrap;
  }
}

.loaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}

.errorModal {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-size: 14px;
  position: fixed;
  z-index: 10;
  background-color: white;
  border-radius: 6px;
  top: calc(50% - 100px);
  left: calc(50% - 200px);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  padding: 16px;
}
.errorDescription {
display: flex;
justify-content: center;
font-weight: 500;
font-size: 16px;
padding: 16px
}
.footerControls {
  display: flex;
  justify-content: center;
}
.rootControls {
  display: flex;
  align-items: center;
}
.btnDone {
  border-radius: 4px;
  border: 1px solid #0EBFA1;
  color: #0EBFA1;
  padding: 8px 12px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.btnReapit {
  border-radius: 4px;
  border: 1px solid #E67E22;
  color: #E67E22;
  padding: 8px 12px;
  margin-left: 12px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.inputChangeName {
  outline: none;
  border: none;
  border-bottom: 1px solid #fff;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-left: 8px;
  margin-right: 8px;
  // margin-bottom: 8px;
  padding-bottom: 0px;
  &:focus-visible {
      outline: none;
      border-bottom: 1px solid #0EBFA1;
  }
}

.fullScreen {
  // position: fixed;
  // width: 100%;
  // height: 100%;
  // top: 0;
  // left: 0;
  z-index: 9999;
  background-color: #fff;
}