
.table {
    display: flex;
    flex-flow: column nowrap;
}

.body {
    display: flex;
    flex-flow: column nowrap;
}

.noData,
.error {
    height: 44px;
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        color: #262626;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 0;
    }
}

.error {
    p {
        color: #F36869;
    }
}