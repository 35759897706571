.containerTag {
  width: 500px;
  position: relative;
  margin-bottom: 16px;
  :global{
  .ant-select{
    .ant-select-selector {
      border-color: #0EBFA1 !important;
      border-radius: 6px !important;
    }
  }}
}

.listWords {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 500px;
  flex-wrap: wrap;
}

.word {
  margin-right: 10px;
}

.wordContainer {
  padding: 11px;
  background-color: #F8FBFF;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-right: 10px;
  margin-bottom: 8px;
  border-radius: 6px;
  font-weight: 400;
}

.removeWordIcn {
  height: 16px;
  cursor: pointer;
}

.containerSelectTags {

}


