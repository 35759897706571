.container{
  position: relative;
  display: flex;
  width: 100%;
}

.inputField {
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 1px solid #0EBFA1;
  background: none;
  padding-bottom: 16px;
  padding-right: 24px;
  margin-bottom: 32px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 131.5%;
  color: #000000;
}

.inputField::placeholder {
  color: #0EBFA1;
  opacity: 1;
  z-index: 1
}
.inputField::-webkit-input-placeholder {
  color: #0EBFA1;
  opacity: 1;
  z-index: 1
}
.inputField::-ms-input-placeholder {
  color: #0EBFA1;
  opacity: 1;
  z-index: 1
}