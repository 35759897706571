.tonality {
  width: 1072px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: 20px;

  &_lineStart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    margin: auto;
    background: #F8FBFF;;
  }

  &_name {
    display: flex;
    align-items: center;
    width: 220px;
    height: 100%;
    font-weight: 600;
    font-size: 16px;
    background: #E8F1F9;;
    color: gray;
    border-radius: 6px;
    padding: 10px;
  }

  &_arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    width: 3%;
    background: #E8F1F9;;
    color: gray;
    border-radius: 2px;
    padding: 13px 11px;
    cursor: pointer;

    div {
      height: 12px;
      display: flex;
      align-items: center;
    }
  }

  &_list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 18px;
  }

  &_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    color: black;
    margin: 60px 0 0 34px;
    width: 256px;
  }

  &_block {
    display: flex;
    flex-direction: column;
    width: 210px;
  }

  &_nameTonality {
    border-radius: 6px;
    padding: 10px;
    text-align: center;
    width: 111px;
  }

  &_info {
    display: flex;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    margin-top: 18px;
    width: 200px;
  }

  &_quantity {
    font-style: normal;
    font-size: 16px;
  }

  &_percent {
    margin-left: 18px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  &_cell {
    display: flex;
    align-items: center;
    height: 38px;
  }

}

@media screen and (max-width: 1200px) {
  .tonality {
    width: 100%;
  }
}