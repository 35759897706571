.selectDemo_root {
  height: 100%;
  width: 100%;
  padding: 32px;
  overflow: hidden;

  background-color: #f3f3f3;
}

.selectDemo {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-right: 8px;
  span {
    margin-left: 8px;
  }
}