.headerContainer {
  &_root {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    height: 110px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 10px;
    margin-bottom: 16px;
    background: #FFFFFF;
  }
}

.leftContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  min-height: 40px;
  height: 40px;
  margin-top: 7px;
  overflow: hidden;

  &_text {
    height: 28px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    color: #262626;
    overflow: hidden;
  }

  &_linkArrow {
    display: flex;
    align-items: center;
  }
}

.blockInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-left: 24px;
  width: 350px;
  height: 40px;
  min-height: 40px;
  padding: 8px;
  background: #F0F5FF;
  border-radius: 8px;
  white-space: nowrap;

  &_text {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    line-height: 130%;
    color: black;
  }
}

