.wrapper {
    display: flex;
    flex-flow: column nowrap;
    background: #ffffff;
    border-radius: 6px;
    padding: 24px;
    font-family: "Montserrat", sans-serif;
}

.header {
    display: flex;
    flex-flow: row nowrap;
    gap: 24px;
	align-items: center;
	margin-bottom: 8px;

    &Calendar {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #787e93;
        font-weight: 500;
        line-height: 20px;
        gap: 8px;
    }

    &Link {
        display: flex;
        gap: 4px;
        align-items: center;

        span {
            color: #129fd4;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
        }
    }
}

.content {
	margin-bottom: 12px;

	&Text {
		font-weight: 500;
		font-size: 14px;
		color: #000000;
		line-height: 18.9px;
	}
}

.footer {
	display: flex;
	justify-content: space-between;

	&UpdatedDate {
		color: #787E93;
		font-size: 14px;
		font-weight: 400;
	}
}