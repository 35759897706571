.status {
    &_history {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    &_link {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 135%;
        color: #787e93;
    }

    &_btnArrow {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 19px;
        height: 15px;
        margin-left: 19px;
        transform: rotate(180deg);
        cursor: pointer;
    }
}

.sidebar {
    &Wrapper {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: rgba(29, 29, 27, 0.5);
        z-index: 999;
        display: flex;
        justify-content: flex-end;
    }

    &Container {
        width: 900px;
        background: #fff;
        height: 100%;
        padding: 24px;
        display: flex;
        flex-direction: column;
    }

    &Header {
        display: flex;
        justify-content: space-between;
        padding-left: 8px;
        padding-top: 24px;
        padding-bottom: 16px;
        padding-right: 16px;

        h2 {
            color: #262626;
            font-family: "Montserrat", sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 20px;
			margin-bottom: 0;
        }
    }
}

.link {
    display: flex;
    flex-flow: row nowrap;
    gap: 8px;
    align-items: center;

    p {
        font-weight: 500;
        font-size: 14px;
        line-height: 135%;
        color: #129fd4;
        margin-bottom: 0;
    }
}

.gridValues {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
}


.cellFields {
    display: flex;
    flex-flow: column nowrap;
    white-space: normal;
}