.modalCard {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 460px;
  max-height: 345px;
  padding: 16px;
  background: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  opacity: 1;
  border-radius: 12px;

  &_header {
    display: flex;
    justify-content: space-between;
    height: 20px;
  }

  &_title {
    display: flex;
    gap: 8px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #787E93;
  }

  &_btn {
    display: flex;
    align-items: center;
    padding: 0;
    background: none;
  }

  &_content {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-top: 8px;
    overflow-y: auto;
  }

  &_tagContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 12px 8px 0;
    padding: 10px;
    gap: 10px;
    height: 40px;
    background: #F8FBFF;
    border-radius: 6px;

    &_text {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: black;
    }
  }

  &_tagContainer:hover{
    cursor: pointer;
  }
}

.campaignWordsFavoriteList {
  &_root {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  &_skeleton {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-top: 8px;
    overflow-y: auto;
  }
}

.skeletonCell {
  margin: 0 12px 8px 0;
}