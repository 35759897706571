.blockLoadFile_root {
  position: relative;
  color: #F36869;
  border: 1px solid #F36869;
  padding: 10px 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  width: 178px;
  height: 79px;
  flex-shrink: 0;
  white-space: nowrap;
  border-radius: 6px;
  transition: transform 0.3s ease, box-shadow 0.2s ease, color 0.2s ease, border 0.3s ease;
  user-select: none;
}
.additionlInfo {
  span {
    margin-top: 8px;
  }
}
.btnWithLabel {
  display: flex;
  align-items: center;
  transition: all 0.1s ease;
  .label {
    margin-right: 35.5px;

  }
}

.blockLoadFileLoading_root {
  user-select: none;
  position: relative;
  color: #787E93;
  border: 1px dashed #0EBFA1;
  border-radius: 6px;
  background-color: #EDFFFC;
  width: 168px;
  height: 71px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  margin-left: 20px;
  margin-bottom: 20px;
}

// Misc
$curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);

.checkmark {
  width: 32px;
  height: 32px;
  border-radius: 80%;
  display: block;
  stroke-width: 4;
  stroke-linecap: round;
  stroke: #0EBFA1;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #EDFFFC;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 5;
  stroke-miterlimit: 10;
  stroke: #0EBFA1;
  fill: none;
  animation: stroke .6s $curve forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  stroke-linecap: round;
  stroke: #0EBFA1;
  animation: stroke .3s $curve .8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #EDFFFC;
  }
}