.categoryBlock {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;

  &_head {
    display: flex;
    justify-content: space-between;
    width: 1000px;
    margin: 12px 0 12px 24px;
  }

  &_title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #787E93;
  }

  &_block {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    gap: 20px;
    margin-left: 24px;
    margin-bottom: 20px;
    width: 1000px;
  }

  &_category {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
    padding: 8px;
    background: #F8FBFF;
    border-radius: 6px;
  }
}