.root {
    display: flex;
    align-items: center;
    height: 100%;
}

.label {
    display: flex;
    gap: 16px;
    white-space: nowrap;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #262626;
    cursor: pointer;
}

.loaderContainer {
    display: flex;
    align-items: center;
    height: 100%;
}