.wrapper {
	display: flex;
	gap: 12px;
	align-items: center;

	span {
		display: flex;
		align-items: center;
		gap: 8px;

		p {
			font-family: 'Montserrat', sans-serif;
			margin-bottom: 0;
			font-weight: 500;
			font-size: 14px;
			line-height: 18.9px;
		}
	}
}