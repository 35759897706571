.campaignCreateForm {
  &_root {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: stretch;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 999;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    background: rgba(29, 29, 27, 0.5);
    overflow-y: auto;
  }
}
.modalWindow {
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  width: 1062px;
  min-height: 782px;
  margin: 160px 0;
  padding: 26px;
  background: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  opacity: 1;
  border-radius: 6px;
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 32px;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  border: 1px solid red;
}

.subRoot {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  gap: 16px;
}

.scrollWrapper {
  overflow-y: auto;
}

.bottomContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
}

.pinnedInfo {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.label {
  display: flex;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #787E93;
}

.label.small {
  font-size: 12px;
  line-height: 20px;
}

.label.middle {
  font-size: 14px;
  line-height: 140%;
}

.buttonsContainer {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 16px;
}

.titleBlock {
  display: flex;
  align-items: center;
  width: 161px;
  min-height: 42px;
  padding: 0 10px;
  background: #DFFCF7;
  border-radius: 6px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 131.5%;
  color: #000000;
}

.flexRow {
  display: flex;
  gap: 16px;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #787E93;
}

.switchContainer {
  display: flex;
  gap: 16px;
  margin-top: -4px;
}

.wordsWrapper {
  display: flex;
  flex-direction: column;
  max-width: 1016px;
  gap: 18px;
  padding: 16px;
  background: white;
  border-radius: 6px;
}