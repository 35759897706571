.actionCell {
  display: flex;
  align-items: center;
}

.favoritBtn {
  height: 28px;
  width: 28px;
  cursor: pointer;
  fill: none;
  stroke: #787E93;
  stroke-width: 2.5px;
  &.active {
    stroke: none;
    fill: #0EBFA1;
  }
  transition: all 0.2s;
  &:active {
    transform: scale(0.9);
  }
}
.menuBtn {
  margin-left: 12px;
  cursor: pointer;
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: rgba(236, 242, 249, 0.50);
  transition: all 0.2s;
  &::after, &::before, span {
    content: ' ';
    left: calc(50% - 2px);
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: #787E93;
  }
  &::before {
    top: calc(50% - 10px);
  }
  span {
    top: calc(50% - 2px);
  }
  &::after {
    top: calc(50% + 6px);
  }
  &:hover {
    background-color: rgba(236, 242, 249, 0.80);
  }
  &:active {
    transform: scale(0.9);
  }
}

.tooltipOverlay {
  :global {
      .ant-popover-inner-content {
        padding: 12px 0px;
      }
    }
}