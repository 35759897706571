.modal {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(29, 29, 27, 0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;

    &Container {
        width: 324px;
        background: #fff;
        padding: 12px;
        display: flex;
        flex-direction: column;
        border-radius: 6px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
		align-items: center;
    }
}

.searchInput {
	max-width: calc(100% - 24px);
	width: 100%;
}

.list {
    display: flex;
    flex-flow: column nowrap;
	height: 230px;
	overflow-y: auto;
	width: 100%;
    margin: 12px 0;
    div {
        padding: 12px;
        display: flex;
        flex-flow: row nowrap;
        gap: 10px;
        align-items: center;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }

        span {
            color: #262626;	
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 131.5%;
        }
    }
}

.buttons {
	display: flex;
	justify-content: flex-end;
	gap: 16px;
	width: 100%;
    margin-bottom: 8px;
}

.successMessage {
    padding: 6.5px 12px;
    border-radius: 6px;
    background: #fff;
    display: flex;
    gap: 8px;
	align-items: center;

    p {
        color: #0ebfa1;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 135%;
		margin-bottom: 0;
    }
}