.wrapper {
    width: 20px;
    height: 19px;
    display: flex;
    cursor: pointer;

    svg {
        &:last-child {
            transform: rotate(180deg);
        }
    }

    &.desc {
        svg:first-child path {
            fill: #262626;
        }
    }

    &.asc {
        svg:last-child path {
            fill: #262626;
        }
    }
}