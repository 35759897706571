.savedFilters_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.platform {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: Montserrat, sans-serif;

  &_title {
    display: flex;
    width: 300px;
    height: 52px;
    align-items: center;
    gap: 8px;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 131.5%;
  }

  &_list {
    display: flex;
    gap: 8px;
  }

  &_item {
    display: flex;
    align-items: center;
    gap: 10px;
    background: #F8FBFF;
    border-radius: 6px;
    padding: 5px 10px;
  }

  &_name {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
}

.range {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: Montserrat, sans-serif;

  &_title {
    display: flex;
    width: 300px;
    height: 52px;
    align-items: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 131.5%;
  }

  &_row {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #787E93;
  }

  &_item {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    color: #262626;
    background: #F8FBFF;
    border-radius: 6px;
    padding: 8px;
  }
}

.categories {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: Montserrat, sans-serif;

  &_title {
    display: flex;
    width: 300px;
    height: 52px;
    align-items: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 131.5%;
  }

  &_list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &_item {
    display: flex;
    align-items: center;
    background: #F8FBFF;
    border-radius: 6px;
    padding: 5px 10px;
  }
}

.controls {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 16px 32px 24px 0;
}