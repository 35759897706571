.ant-slider:hover .ant-slider-track,
.ant-slider-track {
	background-color: #0EBFA1;
}

.ant-slider:hover .ant-slider-rail,
.ant-slider-rail {
	background: #EDFFFC;
}

.ant-slider-handle {
	background: #0EBFA1;
	border: 2px solid #0EBFA1;
}

.ant-slider-handle::after {
	display: none;
}

.ant-slider-handle:focus {
	box-shadow: none;
	border-color: #0EBFA1;
}