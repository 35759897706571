.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    background: rgba(29, 29, 27, 0.3);
    z-index: 999;
    align-items: center;
    justify-content: center;
}

.container {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-flow: column nowrap;
    border-radius: 16px;
    background: #fff;
    overflow: hidden;
}