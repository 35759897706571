$gridTemplateColumns: minmax(20px, 30px) minmax(200px, 320px) minmax(100px, 130px) minmax(50px, 70px) minmax(70px, 100px) minmax(70px, 100px) minmax(70px, 100px) minmax(70px, 100px) minmax(100px, 150px);
$gridTemplateColumnsMobile: minmax(40px, 50px) minmax(200px, 320px) minmax(100px, 130px) minmax(50px, 70px) minmax(70px, 100px) minmax(70px, 100px) minmax(70px, 100px) minmax(70px, 100px) minmax(100px, 150px);

.table {
    display: flex;
    flex-flow: column nowrap;
}

.header {
    display: grid;
    grid-template-columns: $gridTemplateColumns;
    column-gap: 10px;
    justify-content: space-between;
    background: #E8F1F9;
    padding: 20px 0;

    &Column {
        display: flex;
        align-items: center;
        gap: 8px;

        .headerColumnSortButton {
            display: none;

            &Visible {
                display: block;
            }
        }

        &:hover {
            .headerColumnSortButton {
                display: block;
            }
        }

        &:first-child {
            padding-left: 32px;
        }

        p {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #787E93;
            margin-bottom: 0;
            line-height: 135%;
        }
    }
}

.body {
    display: flex;
    flex-flow: column nowrap;
}

.row {
    display: grid;
    grid-template-columns: $gridTemplateColumns;
    column-gap: 10px;
    justify-content: space-between;
    border-bottom: 1px solid rgba(214, 221, 232, 0.5);
    padding: 10px 0;
    align-items: center;
    background: #fff;

    span {
        color: #787E93;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 14px;

        &:first-child {
            padding-left: 32px;
            font-size: 16px;
            font-weight: 500;
        }

        &:nth-child(2) {
            font-weight: 500;
            color: #262626;
        }

        &:nth-child(4) {
            display: flex;
            align-items: center;

            img {
                margin-left: 10px;
            }
        }
    }

    &Actions {
        display: flex;
        gap: 12px;
        align-items: center;
        position: relative;
    }

    &RemovalOptions {
        position: absolute;
        right: 0;
        top: 45px;
        background: #FFFFFF;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        display: flex;
        flex-flow: column nowrap;
        width: 200px;
        align-items: flex-start;
        z-index: 1;

        button {
            width: 100%;
            display: flex;
            text-align: left;
            padding: 10px;
            padding-bottom: 5px;

            &:hover {
                background: #F8FBFF;
            }
            
            &:focus {
                background: #E8F1F9;
            }
        }
    }

    &InformationPlate {
        display: flex;
        height: 30px;
        justify-content: flex-end;
        padding-right: 16px;
        background: #FFFFFF;
        border-top: 1px solid #DEE1E7;
        border-bottom: 1px solid #DEE1E7;
        align-items: center;
        gap: 10px;
        
        p {
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 400;
            color: #262626;
            margin-bottom: 0;
        }
    }
    
    &PostsDeleted {
        p {
            color: #E67E22;
        }
    }

    &Error {
        p {
            color: #F36869;
        }
    }
}

.noData,
.error {
    height: 44px;
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        color: #262626;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 0;
    }
}

.error {
    p {
        color: #F36869;
    }
}

@media screen and (max-width: 1200px) {
    .header {
        grid-template-columns: $gridTemplateColumnsMobile;
        &Column {
            p {
                font-size: 12px;
            }
        }
    }

    .row {
        grid-template-columns: $gridTemplateColumnsMobile;
        span {
            font-size: 12px;
        
            &:first-child {
                font-size: 14px;
            }
        }
    }
}