$gridTemplateColumns: minmax(200px, 320px) minmax(100px, 150px) minmax(50px, 70px) minmax(100px, 150px) minmax(100px, 150px) minmax(100px, 150px) minmax(100px, 150px);

.table {
    display: flex;
    flex-flow: column nowrap;
}

.header {
    display: grid;
    grid-template-columns: $gridTemplateColumns;
    column-gap: 10px;
    justify-content: space-between;
    background: #E8F1F9;
    padding: 20px 0;
    height: 65px;

    &Column {
        display: flex;
        align-items: center;
        gap: 8px;

        .headerColumnSortButton {
            display: none;

            &Visible {
                display: block;
            }
        }

        &:hover {
            .headerColumnSortButton {
                display: block;
            }
        }

        &:first-child {
            padding-left: 32px;
        }

        p {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #787E93;
            margin-bottom: 0;
            line-height: 135%;
        }

        &Search {
            display: flex;

            input {
                border: none;
                background: none;
                border-bottom: 1px solid #D6DDE8;
                width: 100%;

                &:focus-visible {
                    border: none;
                    border-bottom: 1px solid #D6DDE8;
                    outline: none
                }

                &::placeholder {
                    font-family: 'Montserrat', sans-serif;
                    color: #787E93
                }
            }
        }
    }
}

.body {
    display: flex;
    flex-flow: column nowrap;
}

.row {
    display: grid;
    grid-template-columns: $gridTemplateColumns;
    column-gap: 10px;
    justify-content: space-between;
    border-bottom: 1px solid rgba(214, 221, 232, 0.5);
    padding: 10px 0;
    align-items: center;
    background: #fff;

    span {
        color: #787E93;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 14px;

        &:first-child {
            padding-left: 32px;
            font-weight: 500;
            color: #262626;
        }

        &:nth-child(3) {
            display: flex;
            align-items: center;

            img {
                margin-left: 10px;
            }
        }
    }
}

.noData,
.error {
    height: 44px;
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        color: #262626;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 0;
    }
}

.error {
    p {
        color: #F36869;
    }
}