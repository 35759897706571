.wrapper {
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-flow: row nowrap;
  gap: 24px;
  padding: 24px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 16px;
}

.photo {
  min-width: 138px;
  max-width: 138px;
  height: 138px;
  border-radius: 6px;
  background: rgba(222, 225, 231, 0.5);
  overflow: hidden;

  // Temp no image
  img {
    width: 138px;
    height: 138px;
  }
}

.content {
  display: flex;
  flex-flow: column nowrap;
  gap: 3px;
  width: 100%;
}

.info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  &Side {
    display: flex;
    flex-flow: column nowrap;
  }
}

.name {
  display: flex;
  flex-flow: row nowrap;
  gap: 24px;
  margin-bottom: 4px;
  
  p {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    margin-bottom: 0;
  }

  svg {
    min-width: 24px;
    min-height: 24px;
  }
}

.type {
  font-weight: 500;
  font-size: 14px;
  color: #787e93;
  line-height: 135%;
  margin-bottom: 4px;
  display: flex;

  a {
    margin-left: 8px;
  }
}

.author {
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  display: flex;
  gap: 4px;
  align-items: center;

  p {
    color: #787e93;
    margin-bottom: 0;
  }

  a {
    color: #129fd4;
  }
}

.link {
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
  align-items: center;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    color: #129fd4;
    margin-bottom: 0;
  }
}

.date {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  min-width: 270px;

  p {
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #787e93;
  }
}

.categories {
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  row-gap: 12px;
  position: relative;
  margin-top: 7px;
  
  div {
    padding: 10.5px 10px;
    background: #f8fbff;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    color: #000000;
  }
}


.skeleton {
  margin-bottom: 16px;
}

.categoriesButton {
  display: flex;
  align-self: flex-start;
  align-items: center;
  gap: 6px;

  p {
    color: #787E93;
    font-family: "Montserrat", sans-serif;;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    margin-bottom: 0;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      transform: rotate(90deg);
      bottom: -5px;
      position: relative;
    }
  }

  &Active {
    div {
      svg {
        transform: rotate(-90deg);
        bottom: 5px;
      }
    }
  }
}