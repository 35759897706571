.test {
    color: red;
    font-size: 12px;
    border: 1px solid red;
}

.longList {
  &_root {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &_header {
    display: flex;
    gap: 24px;

    &_title {
      color: #262626;
      font-size: 20px;
      font-family: Montserrat, sans-serif;
      font-weight: 600;
      line-height: 20px;
    }

    &_saveSearch {
      height: 21px;
      color: #129FD4;
      font-size: 14px;
      font-family: Montserrat, sans-serif;
      font-weight: 600;
      line-height: 20px;
      border-bottom: 1px solid #129FD4;
      cursor: pointer;

    }

    &_actions {
      display: flex;
      flex-flow: row nowrap;
      gap: 12px;
      margin-left: auto;
    }
  }

  &_footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
    padding: 0 32px 0 0;
  }

  &_tableWrapper {
    width: 100%;
    overflow-x: auto ;
    display: flex;
    justify-content: flex-start;
  }

  &_table {
    width: 100%;
    & .rowCell {
      min-height: 84px;
    }
  }

  &_controller {
    border-radius: 100%;
    height: 31px;
    width: 31px;
    background-color: #F8FBFF;
    fill: #787E93;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.5s ease;
    transform: rotate(0deg);
    &.open {
      transition: all 0.5s ease;
      transform: rotate(180deg);
    }

    &:hover {
      background-color: #ecf1f9;
    }
  }

}

.subscriberGrowthCell {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.grouwthItem {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  & div {
    box-sizing: border-box;
    padding-left: 5px;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.grouwthTime {
  color: #3B3D47;
}

.grouwthValue {
  color: #0EBFA1;
}

.grouwthPercent {
  color: rgba(120, 126, 147, 0.7);
}

.planCell {
  display: flex;
  gap: 16px;
  min-width: 150px;
  box-sizing: border-box;
  height: 76px;
  background-color: #EDFFFC;
  align-items: center;
  margin-left: -12px;
  padding-left: 12px;
}

.headerPlanValues {
  display: flex;
  align-items: center;
  background-color: #EDFFFC;
  min-width: 150px;
  box-sizing: border-box;
  height: 44px;
  padding: 0 12px;
}

.headerAvgValues {
  display: flex;
  align-items: center;
  background-color: white;
  min-width: 150px;
  box-sizing: border-box;
  height: 44px;
  padding: 0 12px;
}

.colorWhite {
  background-color: white;
}

.headerCommon {
  display: flex;
  justify-content: center;
  width: 100%;
}


.icon_layout {
  margin-top: 2px;
  stroke: rgba(230, 126, 34, 1);
  fill: rgba(230, 126, 34, 1);
  &:hover {
    cursor: pointer;
  }
}

.btnRemove {
  cursor: pointer;
  fill: #787E93;
  transition: all 0.3s ease;

  &:hover {
    fill: tomato
  }
}

.longlistControlContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 1240px;
  padding-top: 12px;
  padding-right: 24px;
}

.message {
  &_succes {
    margin-top: calc(100vh - 80px);
    :global {
      .ant-message-notice-content {
        background: #0EBFA1;
        border-radius: 8px;
        box-shadow: 0px 32px 32px -16px rgba(0, 0, 0, 0.08), 0px 4px 24px -16px rgba(0, 0, 0, 0.12);
        color: #FFF;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; 
      }
    }
  }
  &_error {
    margin-top: calc(100vh - 80px);
    :global {
      .ant-message-notice-content {
        background: #F36869;
        border-radius: 8px;
        box-shadow: 0px 32px 32px -16px rgba(0, 0, 0, 0.08), 0px 4px 24px -16px rgba(0, 0, 0, 0.12);
        color: #FFF;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; 
      }
    }
  }
}