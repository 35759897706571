.container {

}

.overlay {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: stretch;

  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 999;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  background: rgba(29, 29, 27, 0.5);

  overflow-y: auto;
}

.modalWindow {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 165px;
  margin: 160px 0;
  background: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  opacity: 1;
  border-radius: 6px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 26px;
  gap: 20px;
}

.text {
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: black;
}

.controlBlock {
  display: flex;
  gap: 24px;
}