.layout {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.tabs {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tab {
    font-size: 18px;
    padding: 7px 14px;
    cursor: pointer;
    border-bottom: 2px solid rgba(0,0,0,0);
    &.select {
        border-bottom: 2px solid #0EBFA1;
    }
}