.monitoring {
  &_root {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &_controls {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 16px 24px ;
  }
}
.header {
    display: flex;
    gap: 24px;

    &_title {
      color: #262626;
      font-size: 20px;
      font-family: Montserrat, sans-serif;
      font-weight: 600;
      line-height: 20px;
    }

    &_actions {
      display: flex;
      flex-flow: row nowrap;
      gap: 12px;
      margin-left: auto;
    }

    &_controller {
      border-radius: 100%;
      height: 31px;
      width: 31px;
      background-color: #F8FBFF;
      fill: #787E93;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.5s ease;
      transform: rotate(0deg);
      &.open {
        transition: all 0.5s ease;
        transform: rotate(180deg);
      }
  
      &:hover {
        background-color: #ecf1f9;
      }
    }
}

.content {
  position: relative;
  &_title {
    color: #0EBFA1;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 16px;
  }
  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.statusChart {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #262626;
  &_message {
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin: 24px 0px;
  }
  &_title {
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    align-items: center;
    svg {
      margin-right: 16px;
    }
  }
}