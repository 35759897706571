.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border: 2px solid #D2D5DA;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    border-color: #0EBFA1;
  }
}

.container.checked {
  border: 2px solid #0EBFA1;
  background-color: #0EBFA1;
}

.container.error {
  border: 2px solid #F36869;
}

.container.disabled {
  background-color: #E1E4E7;
  cursor: no-drop;

  &:hover {
    border-color: #D2D5DA;
  }
}

.container.disabled.checked {
  background-color: #0EBFA1;
  cursor: no-drop;
}

.innerBox.checked {
  background-color: #0EBFA1;
}

.hidden {
  visibility: hidden;
}