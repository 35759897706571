$bigCircleDiameter: 96px;

.root_DocumentCard {
    position: relative;
    cursor: pointer;
}

.content_DocumentCard {
    position: relative;
    cursor: pointer;
}

.containerItemAnimation {
    position: absolute;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 82px;
    fill: #787E93;
    background-color: rgba(255,255,255,0.4);
    box-shadow: inset 0 0 41px rgba(120, 126, 147, 0.05);
    border-radius: 6px;
}

.btnClose {
    position: absolute;
    top: 0;
    right: 0;
    fill: #787E93;
    transition: all 0.3s ease;
    &:active {
        transform: scale(0.90);
        fill: #F36869 !important;
    }
    &:hover {
        fill: #F36869 !important;
    }
}

.svgIcn {
    fill: #787E93;
    cursor: pointer;
    transition: fill 0.3s ease;
    &:hover {
        fill: #0EBFA1;
    }
    &.select {
        fill: #0EBFA1;
    }
}

.label {
    margin-top: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    span {
        margin-bottom: 4px;
        margin-right: 2px;
        width: 54px;
        max-height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.date {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #787E93;
}

.removeItemAnimation {
    position: absolute;
    top: 24px
}

.removeItemAnimation::after {
    content: "";
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    margin: auto;
    width: 6px;
    height: 6px;
    background: rgba(120, 126, 147, 0.759);;
    border-radius: 50%;
    -webkit-animation: ball 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
            animation: ball 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@-webkit-keyframes ball {
    0%   { -webkit-transform: translate3d(0, -62px, 0) scale(0, 2); opacity: 0; }
    50%  { -webkit-transform: translate3d(0, 0, 0) scale(1.25, 1.25); opacity: 1; }
    100% { -webkit-transform: translate3d(0, 8px, 0) scale(0, 0); opacity: 0; }
}
@keyframes ball {
    0%   { transform: translate3d(0, -52px, 0) scale(0, 2); opacity: 0; }
    50%  { transform: translate3d(0, -22px, 0) scale(1.25, 1.25); opacity: 1; }
    100% { transform: translate3d(0, 8px, 0) scale(0, 0); opacity: 0; }
}

.labelErrorRemoving {
    color: #F36869;
    font-size: 11px;
    text-align: center;
    font-weight: bold;
    background-color: rgba(255,255,255,0.6);
}


.downloading {
    position: absolute;
    top: 12px;
    display: block;
    height: 34px;
    width: 34px;
}
.downloading span {
    display: block;
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    margin: auto;
    height: 34px;
    width: 34px;
}
.downloading span::before {
    content: "";
    display: block;
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    margin: auto;
    height: 34px;
    width: 34px;
    border: 3px solid #0ebfa1b7;
    border-bottom: 3px solid transparent;
    border-radius: 50%;
    -webkit-animation: circle 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
            animation: circle 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes circle {
    0% {
        -webkit-transform: rotate(0deg);
    }
    40% {
        -webkit-transform: rotate(180deg);
    }
    60% {
        -webkit-transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes circle {
    0%   {
        transform: rotate(0deg);
    }
    40%  {
        transform: rotate(180deg);
    }
    60%  {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.downloading span::after {
    content: "";
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    margin: auto;
    width: 6px;
    height: 6px;
    background: #0ebfa1b7;
    border-radius: 50%;
    -webkit-animation: ball2 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
            animation: ball2 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes ball2 {
    0%   { -webkit-transform: translate3d(0, -32px, 0) scale(0, 2); opacity: 0; }
    50%  { -webkit-transform: translate3d(0, 0, 0) scale(1.25, 1.25); opacity: 1; }
    100% { -webkit-transform: translate3d(0, 8px, 0) scale(0, 0); opacity: 0; }
}
@keyframes ball2 {
    0%   { transform: translate3d(0, -32px, 0) scale(0, 2); opacity: 0; }
    50%  { transform: translate3d(0, 0, 0) scale(1.25, 1.25); opacity: 1; }
    100% { transform: translate3d(0, 8px, 0) scale(0, 0); opacity: 0; }
}