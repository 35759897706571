.cursorPointer {
  cursor: pointer;
}

.dropdownName {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 10px;
  gap: 12px;
  border-radius: 6px;

  &:hover {
    background: #E8F1F9;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }
}

.dropdownName.open {
  background-color: white;
}

.cross {
  cursor: pointer;
}

.cross.invisible {
  visibility: hidden;
  width: 0;
}

.textContainer {
  display: flex;
  align-items: center;
  height: 20px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #787E93;
}

.arrowContainer {
  display: flex;
  align-items: center;
  height: 40px;
  width: 20px;
}

.arrow {
  cursor: pointer;
}

.arrow.open {
  transform: rotate(180deg);
}

.counterContainer {
  position: relative;
  height: 100%;
  width: 10px;

  &_counter {
    position: absolute;
    width: 3px;
    height: 7px;
    top: 0;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 20px;
    color: #787E93;
  }
}

.counterContainer.invisible {
  visibility: collapse;
}