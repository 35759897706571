.root_ModalChangeName{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.modalOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.2);
}

.modalContainer {
    z-index: 10;
    position: relative;
    border-radius: 6px;
    background-color: #fff;
    padding: 24px 32px;
}

.btnClose {
    position: absolute;
    top: 26px;
    right: 20px;
    svg {
        fill: #787E93;
        transition: transform 0.3s;
        &:active {
            transform: scale(0.94);
        }
    }
}

.modalTitle {
    color: #222;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 24px;
}

.label {
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    color: #787E93;
    margin-bottom: 10px;
}

.inputName {
    border: 1px solid #DEE1E780;
    border-radius: 6px;
    padding: 12px;
    outline: none !important;
    color: #000;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 32px;
    width: 536px;
}

.inputName::placeholder {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #787E93;
}

.footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.footerContent {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 16px;
}