.modalContainer {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  height: 100%;
  width: 100%;
  width: calc(100% + 64px);
  top: 0;
  left: -64px;
}
.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}
.headerSide {
  display: flex;
  align-items: center;
}
.modalOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0,0,0,0.15);
}
.cardHeader {
  display: flex;
  margin-bottom: 6px;
}
.iconsWithLabel {
  display: flex;
  align-items: center;
  margin-right: 21px;
}
.iconWithLabel {
  margin-right: 6px;
}
.platformLabel {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.cardDescripton {
  margin-bottom: 13px;
}
.cardDescriptonTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #1D202F;
  margin-bottom: 8px;
}
.cardDescriptonContent{
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #262626;
  
}
.cardReactions {
  display: flex;
  margin-bottom: 10px;
}
.cardTags {
  display: flex;
}
.cardTag {
  border-radius: 6px;
  background: #F8FBFF;
  padding: 10px;
  margin-right: 12px;
}
.tonalityDescription {
  display: flex;
  align-items: center;
  margin-left: 6px;
}
.tonalityLabel {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #787E93;
}
.dateValue {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #787E93;
}
.tonalityIndicator {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-right: 4px;
  margin-bottom: -2px;
}
.addTag {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #787E93;
  cursor: pointer;
}
.removeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  // fill="#F36869"
  fill: #dfdfdf;
}
.cardContnet {
  display: flex;
  align-items: center;
}
.cardPreview {
  width: 150px;
  height: 100px;
  flex-shrink: 0;
  object-fit: fill;
  background-size: cover;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 6px;
}

.authorName {
  position: relative;
  max-width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #3390EC;
  cursor: pointer;
}

.linkContainer {
  margin-top: 12px;
  display: flex;
  margin-bottom: 12px;
}

.linkOnPost {
  margin-left: 6px;
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #129FD4;
  cursor: pointer;
}

.toggleOpenModalMoreInformation {
  margin-top: 16px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;  
  // color: #0EBFA1;
  // fill: #0EBFA1;
  color: #dfdfdf;
  fill: #dfdfdf;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.labelToggleOpenModalMoreInformation {
  margin-right: 6px;
  margin-bottom: 8px;
}